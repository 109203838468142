<template>
<hr class="oido-divider" />
</template>


<script >



export default {

  

};
</script>


<style scoped>
.oido-divider{
  border: none;
  border-bottom: 1px solid #eee;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}




</style>


