import { toRefs, reactive, watch } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import axios from "axios";
import config from "../../config"

import authenticationService from "./authenticationService";
const auth = authenticationService();

import fileServiceModule from "./fileService";
const fileService = fileServiceModule();


const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.company = {};
    state.info = {};
    state.settings = {
        timezone: 'Europe/Madrid'
    };
    state.apiSettings = {};

}
const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    company: {},
    info: {},
    settings: {
        timezone: 'Europe/Madrid'
    },
    apiSettings: {},
    companyPictureUrl: null
});


async function loadCompany() {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    let companyUnsubscribeHandle = firebase
        .firestore()
        .collection("companies")
        .doc(companyId)
        .onSnapshot((snapshot) => {
            let data = snapshot.data()
            if (data) {
                data.id = snapshot.id
            }
            state.company = data ? data : null;
        })
    unsubscribeHandles.push(companyUnsubscribeHandle);

    let companyInfoUnsubscribeHandle = firebase
        .firestore()
        .collection("companyInfos")
        .doc(companyId)
        .onSnapshot((snapshot) => {
            let data = snapshot.data()
            state.info = data ? data : null;
        })
    unsubscribeHandles.push(companyInfoUnsubscribeHandle);

    let companySettingsUnsubscribeHandle = firebase
        .firestore()
        .collection("companySettings")
        .doc(companyId)
        .onSnapshot((snapshot) => {
            let data = snapshot.data()
            state.settings = data ? data : null;
        })
    unsubscribeHandles.push(companySettingsUnsubscribeHandle);

    let companyApiSettingsUnsubscribeHandle = firebase
        .firestore()
        .collection("companyApiSettings")
        .doc(companyId)
        .onSnapshot((snapshot) => {
            let data = snapshot.data()
            state.apiSettings = data ? data : null;
        })
    unsubscribeHandles.push(companyApiSettingsUnsubscribeHandle);
}

async function updateCompanyInfo(info) {

    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }


    function getBuffer(file) {
        const reader = new FileReader();
        return new Promise((resolve) => {
            reader.onload = (ev) => {
                resolve(ev.target.result);
            };
            reader.readAsArrayBuffer(file);
        });
    }

    console.log('info', info)

    let keys = [
        "name",
        "email",
        "companyname",
        "description",
        "country",
        "firstname",
        "lastname",
        "street",
        "zip",
        "city",
        "website",
        "phone",
        "mobile",
        "picture",
        "slug",
        "customPrivacyUrl",
        "customTermsUrl"
    ];

    let data = {}
    for (let key of keys) {
        data[key] = (info && info[key]) ? info[key] : null;
    }

    //only upload picuture if ist really a blob
    if (data.picture && typeof data.picture == 'object') {
        data.picture = await fileService.uploadFile(data.picture);
    }

    let token = await firebase.auth().currentUser.getIdToken();

    let response = await axios.post(config.apiUrl + "/api/companies/" + companyId + '/info', data, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    if (!response || !response.data) {
        throw new Error("incomplete return value");
    }

}

async function updateCompanySettings(settings) {

    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    let keys = [
        "currency",
        "defaultPointofsaleId",
        "defaultTipPercentage",
        "expectedDeliveryNotice",
        "isClosedMessage",
        "i18n",
        "isOpen",
        "sendCopyOfOrderReceipts",
        "sendCopyOfOrderReceiptsTo",
        "sendNewOrderNotifications",
        "sendNewOrderNotificationsTo",
        "stripePublishableKey",
        "stripeSecretKey",
        "tipIsEnabled",
        "tipIsTaxed",
        "vat",
        "vatId",
        "vatForTips",
        "vatRates",
        "sendSMSOnOrderReady",
        "timezone",
        "enableThrottleMessage",
        "throttleLimit",
        "throttleMessage",
        "enableOfflinePayments",
        "enableOfflinePaymentsConfirmation",
        "enableLegalAgePopup",
        "legalAgePopupContent",
        "legalAgePopupPositiveButtonLabel",
        "legalAgePopupNegativeButtonLabel",
        "legalAgePopupNegativeButtonLabel",
        "enableSlotLimits",
        "allowedNumberOfOrdersPerSlot",
    ];

    let data = {}
    for (let key of keys) {
        data[key] = settings && typeof settings[key] != 'undefined' ? settings[key] : null;
    }

    let token = await firebase.auth().currentUser.getIdToken();

    let response = await axios.post(config.apiUrl + "/api/companies/" + companyId + '/settings', data, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    if (!response || !response.data) {
        throw new Error("incomplete return value");
    }


}

async function updateCompanyApiSettings(settings) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }


    let token = await firebase.auth().currentUser.getIdToken();

    let response = await axios.post(config.apiUrl + "/api/api-settings/" + companyId, settings, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    if (!response || !response.data) {
        throw new Error("incomplete return value");
    }
}

async function refreshApiSecret() {

    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }


    let token = await firebase.auth().currentUser.getIdToken();

    let response = await axios.post(config.apiUrl + "/api/api-settings/" + companyId + '/regenerate', {}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    if (!response || !response.data) {
        throw new Error("incomplete return value");
    }
}


export default function () {


    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        watch(auth.companyId, async (companyId, prevUser) => {
            console.log('companyservice user change', companyId)
            if (companyId) {
                loadCompany();
            } else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadCompany();
        }

        watch(
            () => state.info,
            async () => {
                if (state.info && state.info.picture) {
                    state.companyPictureUrl = await firebase.storage().ref(state.info.picture).getDownloadURL();
                } else {
                    state.companyPictureUrl = null
                }
            })

        state.initialized = true
        state.initializing = false
    }

    return {
        ...toRefs(state),
        loadCompany,
        updateCompanyInfo,
        updateCompanySettings,
        updateCompanyApiSettings,
        refreshApiSecret
    }


}