<template>
  <div class="oido-date-picker"
    :class="{ 'has-errors': errors && errors.length > 0, 'readonly': readonly, 'disabled': disabled }">

    <slot name="before"></slot>
    <div class="validation-errors" v-if="errors">
      <span class="validation-error" v-for="error of errors" v-text="error" :key="error"></span>
    </div>
    <label :for="componentId">
      <span class="label-text" v-text="label"></span>
      <span class="required-marker" v-if="required"> *</span>
      <oido-help-icon class="help-text" v-if="helpText" :help-text="helpText"
        :position="helpTextPosition"></oido-help-icon>
      <span class="spacer"></span>
      <oido-button icon="delete" @click="clear" class="clear-button" v-if="nullable"
        :label="$t('oido-date-picker-clear-label')" :disabled="!hasValue"></oido-button>
    </label>



    <div class="input-content">
      <oido-help-icon class="help-text" v-if="helpText" :help-text="helpText"
        :position="helpTextPosition"></oido-help-icon>
      <div class="input-section" v-if="localValue">
        <ion-datetime-button ref="datetime" :presentation="presentationAttribute" :id="componentId"
          :datetime="componentId + '-datetime'"></ion-datetime-button>
        <ion-modal :keep-contents-mounted="true">
          <ion-datetime v-model="localValue" :id="componentId + '-datetime'"
            :presentation="presentationAttribute"></ion-datetime>
        </ion-modal>
      </div>      
      <div class="input-section" v-if="!localValue">
        <div class="date-placeholder placeholder-section" @click="placeholderClicked">Add Date</div>
        <div v-if="false" class="date-placeholder placeholder-section" @click="placeholderClicked">-- . -- . ----</div>
        <div v-if="false" class="time-placeholder placeholder-section" @click="placeholderClicked">-- : --</div>
      </div>
    </div> 
    <slot name="after"></slot>
  </div>
</template>

<script setup>
import { v4 as uuidv4 } from 'uuid';
import {
  IonModal,
  IonDatetime,
  IonDatetimeButton
} from "@ionic/vue";
import dayjs from "dayjs";
</script>
<script>
export default {

  data() {
    return {
      localValue: null
    };
  },
  mounted() {
    this.updateLocalValue()
  },
  props: {
    modelValue: String,
    errors: [Object],
    name: String,
    label: String,
    readonly: Boolean,
    required: Boolean,
    disabled: Boolean,
    nullable: Boolean,
    helpText: String,
    helpTextPosition: String,
    showDate: {
      type: Boolean,
      default: true,
    },
    showTime: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    modelValue() {
      this.updateLocalValue();
    },
    localValue() {
      if (this.localValue != this.modelValue) {
        this.valueChanged()
      }
    },
  },

  computed: {
    componentId() {
      return this.name + '-' + uuidv4()
    },
    hasValue() {
      return this.localValue !== null
    },
    presentationAttribute() {
      if (this.showDate && this.showTime) {
        return "date-time"
      }
      if (this.showDate) {
        return "date"
      }
      return "time"
    }

  },

  methods: {
    clear() {
      this.localValue = null
    },
    updateLocalValue() {
      let formattedModelValue = this.modelValue ? dayjs(this.modelValue).format("YYYY-MM-DDTHH:mm:ss") : null
      if (this.localValue !== formattedModelValue) {        
        this.localValue = formattedModelValue
      }
    },
    valueChanged() {            
      let isoLocalValue = this.localValue ?  dayjs(this.localValue).tz(this.companySettings.timezone).toISOString() : null
      this.$emit("update:modelValue", isoLocalValue);
    },
    placeholderClicked() {
      let now = dayjs().tz(this.companySettings.timezone);
      if (!this.showTime) {
        now = now.startOf('day')
      }
      this.localValue = now.toISOString()
    }
  },
};
</script>


<style scoped>
.oido-date-picker {
  width: 100%;
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  font-size: 0.9rem;
}

label {
  font-weight: 590;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #222;
  margin-bottom: 0.2rem;
}

.readonly label {
  font-weight: 400;
}

label .oido-help-icon {
  white-space: initial
}


.input-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.input-content .input-section {
  flex-basis: 50%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 0.3rem;
}

.validation-errors {
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.validation-errors {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
  text-align: right;
}

@media (max-width: 500px) {
  .validation-errors {
    text-align: left;
  }
}

ion-datetime-button {
  width: 100%;
  position: relative;
  left: -2px;
}

ion-datetime-button::part(native) {
  flex: 1
}

.placeholder-section {
  background-color: #EDEEF0;
  font-size: 0.9rem;
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
  margin-right: 0.5rem;
  white-space: nowrap;
  color: #444;
  flex: 1;
  text-align: center;
  cursor: pointer;
}

label .spacer {
  flex: 1;
  display: inline-block;
}
</style>
