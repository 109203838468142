<template>
  <div class="oido-select"
    :class="{ 'has-errors': errors && errors.length > 0, 'readonly': readonly, 'disabled': disabled, 'vertical': vertical }">

    <div class="input-header">

    </div>
    <div class="input-content">
      <label :for="componentId">
        <span class="label-text" v-text="label"></span>
        <span class="required-marker" v-if="required"> *</span>
        <oido-help-icon class="help-text" v-if="helpText" :help-text="helpText"
          :position="helpTextPosition"></oido-help-icon>
      </label>


      <div class="input-section">
        <multi-lingual-text class="value" v-if="readonly" v-text="readOnlyPreviewText"></multi-lingual-text>
        <v-select v-model="localValue" :multiple="multiple" :id="componentId" v-if="!readonly" label="label" key="id"
          :placeholder="placeholder" :options="preparedOptions" :clearable="false" :disabled="disabled"
          :selectable="optionIsSelectable" @close="selectCloses" @open="selectopens" ref="select" :searchable="false">
        </v-select>
      </div>
    </div>
    <div class="validation-errors" v-if="errors">
      <span class="validation-error" v-for="error of errors" v-text="error" :key="error"></span>
    </div>
  </div>
</template>

<script setup>
import { v4 as uuidv4 } from 'uuid';
import MultiLingualText from "../components/MultiLingualText.vue";

</script>
<script>
export default {
  data() {
    return {
      localValue: null
    };
  },
  mounted() {
    this.updateLocalValue()
  },
  props: {
    modelValue: [String, Object, Number, Boolean],
    errors: [Object],
    options: Array,
    optionKey: [String, Function],
    optionLabel: [String, Function],
    optionIsSelectable: Function,
    name: String,
    placeholder: String,
    label: String,
    helpText: String,
    helpTextPosition: String,
    readonly: Boolean,
    required: Boolean,
    disabled: Boolean,
    vertical: Boolean,
    multiple: Boolean,
  },
  watch: {
    modelValue() {
      this.updateLocalValue();
    },
    localValue() {
      if (this.localValue === this.modelValue) {
        return
      }

      this.valueChanged()


    },
    selectedLanguage() {
      this.focusOnInput;
    },
  },

  computed: {
    preparedOptions() {

      if (!this.options || !Array.isArray(this.options)) {
        return []
      }

      let keyIdentifier = this.optionKey || 'id'
      let labelIdentifier = this.optionLabel || 'label'

      let optionsAsArray = [];
      for (let item of this.options) {


        let label = null
        if (typeof labelIdentifier == 'function') {
          label = labelIdentifier(item)
        } else {
          label = item[labelIdentifier]
        }
        if (!label && typeof label != 'number' && typeof label != 'boolean') {
          label = this.$t("oido-select-no-label-found") //JSON.stringify(item)
        }

        let key = null
        if (typeof keyIdentifier == 'function') {
          key = keyIdentifier(item)
        } else {
          key = item[keyIdentifier];
          if(item[keyIdentifier] == undefined && typeof item == 'number' || typeof item == "string"){
            key = item
          }
        }
        if (!key && key != null && typeof key != 'number' && typeof key != 'boolean') {
          key = JSON.stringify(item)
        }
        optionsAsArray.push({
          id: key,
          label
        })

      }      
      return optionsAsArray
    },
    componentId() {
      return this.name + '-' + uuidv4()
    },
    extractedModelValue() {
      return this.modelValue;
    },
    readOnlyPreviewText() {
      let previewText = null;
      for (let option of this.preparedOptions) {
        if (option.id == this.modelValue || (option.id === false && this.modelValue === false)) {
          previewText = option.label;
          break;
        }
      }
      return previewText;
    },
  },

  methods: {
    selectopens() {
      // console.log("selectopens")
      let inputEl = this.$el.querySelector('.vs__search');
      if (inputEl) {
        this.$nextTick(() => { inputEl.focus() });
        // setTimeout(() => {inputEl.focus();}, 1000)

      }
    },
    selectCloses() {

    },
    updateLocalValue() {
      let selectedOtpion = null;
      for (let option of this.preparedOptions) {
        if (option.id == this.modelValue || (option.id === false && !this.modelValue)) {
          selectedOtpion = option;
          break
        }
      }
      this.localValue = selectedOtpion;
    },
    focusOnInput() {
      // this.$nextTick(() => {
      //   setTimeout(async () => {
      //     if (!this.$refs.textinput) {
      //       return;
      //     }
      //     (await this.$refs.textinput.$el.getInputElement()).focus();
      //   }, 200);
      // });
    },
    valueChanged() {

      this.$emit("update:modelValue", this.localValue && (this.localValue.id || typeof this.localValue.id == 'boolean' || typeof this.localValue.id == 'number') ? this.localValue.id : null);

    },
  },
};
</script>


<style scoped>
.oido-select {
  width: 100%;
  position: relative;
  margin-top: 0.5rem;
  padding-bottom: 0.7rem;
  font-size: 0.9rem;
}

.input-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.input-content .input-section {
  flex-basis: 50%;
  flex: 1;
  display: flex;
  /* padding-right: 0.2rem; */
}

.input-content .ml-text {
  text-align: right;
  font-weight: 450;
  flex: 1;
}

.input-content .v-select {
  font-weight: 450;
  width: auto;
  flex: 1;
}

label {
  
  font-weight: 590;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.readonly label {
  font-weight: 400;
}

.validation-errors {
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.validation-errors {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
  text-align: right;
}

.vertical .validation-errors {
  text-align: left;
}


.oido-select.disabled .input-content .v-select {
  background-color: rgba(0, 0, 0, 0.05) !important;
  ;
  color: #444;
}

.oido-select.disabled label,
.oido-select.disabled .input-content .ml-text {
  color: #888;
}


@media (max-width: 500px) {
  .validation-errors {
    text-align: left;

  }
}

@media (max-width: 500px) {
  .input-content .input-section {
    width: 100%;
    flex-basis: 100%;
  }

  .input-content .ml-text {
    text-align: left;
  }
}


.vertical .input-content {
  flex-direction: column;
  align-items: flex-start;
}

.vertical .input-content .input-section {
  flex-basis: 100%;
  width: 100%;
  text-align: left !important;
  margin-top: 0.2rem;
}

.vertical .v-select,
.vertical .input-content .ml-text {
  text-align: left;
}

.help-text {
  margin-left: 0.1rem;

}
</style>
<style>
.v-select {
  --vs-controls-color: #777;
  --vs-controls-size: 0.8;

}

.vs__open-indicator {
  margin-right: 0.2rem;

}

.vs__dropdown-toggle {
  background-color: #F6F7F9!important;
  border: none;
  
  border-radius: 0;
  min-height: 2.51rem;
  
}
/* 
.v-select:not(.vs--open) .vs__search {
  display: none;
} */

.vs__dropdown-option{
  overflow: hidden!important;
  text-overflow: ellipsis!important; 
}
</style>