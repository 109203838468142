import { registerPlugin } from '@capacitor/core';

import type { TestflightDetectionPlugin } from './definitions';

const TestflightDetection = registerPlugin<TestflightDetectionPlugin>(
  'TestflightDetection',
  {
    web: () => import('./web').then(m => new m.TestflightDetectionWeb()),
    android: () => import('./web').then(m => new m.TestflightDetectionWeb()),
  },
);

export * from './definitions';
export { TestflightDetection };
