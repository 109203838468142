import { toRefs, reactive, watch } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();
import axios from "axios";
import config from "../../config"


const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.discounts = [];

}
const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    discounts: [],
});


async function loadDiscounts() {

    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }
    

    let discountsUnsubscribeHandle = firebase
        .firestore()
        .collection("discounts")
        .where("companyId", "==", companyId)
        .orderBy("name", "asc")
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;
                if (type === "added") {
                    state.discounts.splice(newIndex, 0, data);
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.discounts.splice(oldIndex, 1);
                    state.discounts.splice(newIndex, 0, data);
                } else if (type === "removed") {
                    state.discounts.splice(oldIndex, 1);
                }
            });
        })
    unsubscribeHandles.push(discountsUnsubscribeHandle);

}

async function addVouchers(discountId, voucherData) {
    
    if (!auth.user) {
        teardown();
        return;
    }
    
    if(!discountId){
        console.error('addVouchers no discountId given');
        return 
    }

    try {

        let token = await firebase.auth().currentUser.getIdToken();
        let response = await axios.post(config.apiUrl + "/api/discounts/" + discountId + '/voucher', voucherData, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        if (!response || !response.data) {
            throw new Error("incomplete return value");
        }
    } catch (error) {
        throw error        
    }

}

async function saveDiscount(discount) {
    
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    
    discount.companyId = companyId
    discount.enabled = discount.enabled || false;
    
    try {

        let token = await firebase.auth().currentUser.getIdToken();
        let response = await axios.post(config.apiUrl + "/api/discounts/" + (discount.id ? discount.id : ''), discount, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        if (!response || !response.data) {
            throw new Error("incomplete return value");
        }
    } catch (error) {
        throw error        
    }

}

async function getVouchersCSV(discountId) {
    if(!discountId){
        return
    }

    let token = await firebase.auth().currentUser.getIdToken();

    let response = await axios.post(config.apiUrl + `/api/discounts/${discountId}/voucher/csv`, {}, {
        headers: {
            Authorization: "Bearer " + token,
        },
        responseType: "arraybuffer",
    });
    return response.data;
}


async function deleteDiscount(discountId) {
    if (!auth.user) {
        teardown();
        return;
    }
    if (!discountId) {
        console.log('Error@deleteDiscount: no discountId', discountId)
    }

    await firebase
        .firestore()
        .collection("discounts")
        .doc(discountId)
        .delete()
}

function getDiscountById(discountId) {
    if (!state.discounts) {
        return null;
    }
    for (let discount of state.discounts) {
        if (discount.id == discountId) {
            return discount;
        }
    }
    return null;
}


export default function () {


    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        watch(auth.companyId, async (companyId) => {
            if (companyId) {                
                loadDiscounts();
            }else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadDiscounts();
        }
        state.initialized = true
        state.initializing = false
    }

    return {
        ...toRefs(state),
        loadDiscounts,
        teardown,
        deleteDiscount,
        saveDiscount,
        getDiscountById,
        addVouchers,
        getVouchersCSV
    }
}