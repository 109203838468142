<template>
  <div class="navigation-item navigation-subaccount-item" :class="{
    'has-image': Boolean(companyPictureUrl)
  }">
    <a class="main-route">
      <img v-if="companyPictureUrl" src="/assets/images/logo-red.v2.svg" class="logo-image" />
      <div class="labels">
        <span class="label" v-text="label"></span>
        <ion-button @click="openOptionsPopover" size="small" color="medium" fill="clear">
          <ion-icon slot="icon-only" :icon="ellipsisVertical"></ion-icon>
        </ion-button>
      </div>

    </a>

  </div>
</template>

<script setup>

import {
  IonButton,
  IonIcon,  
  popoverController
} from "@ionic/vue";

import {
  ellipsisVertical

} from "ionicons/icons";
import SubAccountOptionsPopover from "./SubAccountOptionsPopover.vue";
</script>
<script>
export default {
  props: [],
  data() {
    return {

    };
  },
  mounted() {

  },
  computed: {
    label() {
      return this.loggedInUser ? this.loggedInUser.displayName : null
    },
    companyPictureUrl() {
      return this.services.company.companyPictureUrl ? this.services.company.companyPictureUrl.value : null;
    },

  },

  methods: {
    
    async openOptionsPopover(ev) {
      const popover = await popoverController.create({
        component: SubAccountOptionsPopover,
        event: ev,
        translucent: true,
      });
      await popover.present();

      const { data } = await popover.onDidDismiss();
      if (data && data.action == "logout") {
        this.logout();
      }      
    },
    async logout() {
      try {
        await this.services.auth.logout();
      } catch (error) {
        console.log("error@logout", error);
      }
      this.$router.push({ name: "startpage", replace: true });
    },
    

  }

};
</script>
<style scoped>
.navigation-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  height: 3rem;
  background-color: transparent;

}

.main-route {
  /* cursor: pointer; */
  display: flex;
  flex: 1;
  width: 100%;
  align-items: stretch;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  position: relative;
  object-fit: contain;

}

.logo-image {
  width: auto;
  height: 3rem;

}

.main-route .labels {
  display: flex;

  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding-left: calc(1.7rem + 16px + 0.7rem)
}

.has-image .main-route .labels {
  padding-left: calc(1.7rem + 16px + 0.7rem - 3rem)
}

.main-route .label {
  flex: 1 !important;
  text-decoration: none;
  color: var(--ion-color-medium);
  font-size: 0.8rem;
}
</style>
