<template>
  <span class="ml-text" :class="{ 'missing-translation': !hasTranslation }" v-text="translatedText"> </span>
</template>

<script >
import translationService from "../composables/translationService";
export default {
  props: {
    text: [String, Object],
  },
  computed: {
    enabledLanguages() {
      return this.translationService.enabledLanguages ? this.translationService.enabledLanguages.value : [];
    },
    defaultLanguage() {
      return this.translationService.defaultLanguage ? this.translationService.defaultLanguage.value : 'en';
    },
    hasTranslation() {
      if (!this.text) {
        return true;
      }

      if (typeof this.text == "string") {
        return true;
      }

      let selectedLanguage = this.translationService.selectedLanguage ? this.translationService.selectedLanguage.value : null;

      return selectedLanguage && this.text[selectedLanguage];
    },
    availableTranslation() {
      if (typeof this.text != "object") {
        return this.text;
      }
      // return JSON.stringify(this.text);

      let defaultLanguage = this.translationService.defaultLanguage;

      if (defaultLanguage && this.text[defaultLanguage]) {
        return this.text[defaultLanguage];
      }

      for (let language of this.enabledLanguages) {        
        if (this.text[language]) {
          return this.text[language];
        }
      }
      
      return null;
    },
    translatedText() {      
      return this.translationService.translation2Text(this.text)      
    },
  },
  setup() {
    return {
      translationService: translationService(),
    };
  },
  components: {},
};
</script>
<style scoped>
.ml-text {
  width: 100%;
  flex: 1;
  display: inline-block;  
}
.ml-text.missing-translation {
  color: #999;
  font-style: italic;
}
</style>