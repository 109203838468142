<template>
<div class="oido-card-section">
  <slot></slot>
</div>  
</template>


<script >



export default {

  

};
</script>


<style scoped>
.oido-card-section{
  border-top: 1px solid #eee;  
  padding: 0rem 0 1rem;
  margin: 1rem 0 1.5rem;
}
.oido-card-content > .oido-card-section:first-child{
  margin-top: 1rem;
}
.oido-card-section + .oido-card-section{  
  
}
.oido-card-section:last-child{
  padding-bottom: 0.5rem;
  border-bottom: none;
}
</style>
<style >
.oido-card-section:first-of-type{
  border-top: none;
}
</style>


