<template>
<div class="oido-card-subtitle">
  <slot></slot>
</div>  
</template>


<script >



export default {

  

};
</script>


<style scoped>

</style>


