<template>
  <div
    class="navigation-item navigation-account-item"
    :class="{
      'is-active': isActive || isActiveParent,
      'is-active-parent': isActiveParent,
      toplevel: isTopLevel,
      'has-image': Boolean(companyPictureUrl)
    }"
    :color="isActive || isActiveParent ? 'primary' : null"
  >
  <a class="main-route" :href="targetRoute" @click="navigateTo($event)">
      <img v-if="companyPictureUrl" src="/assets/images/logo-red.v2.svg" class="logo-image" />
      <div class="labels">

        <span class="label" v-if="label" v-text="label"></span>
        <div class="subscriptions-section" v-if="subscriptions && subscriptions.length > 0">
          {{$t('navigation-account-item-your-plan-label')}}:
          <span class="subscription" v-for="(subscription,index) in subscriptions" :key="'subscription' + index" :class="{[subscription.status]: true}">            
            {{subscription.name}}<span v-if="index < subscriptions.length -1" class="spacer">, </span>
          </span>
        </div>
      </div>
      <span class="badge" v-if="badge" v-text="badge"></span>
      
    </a>
    
  </div>
</template>

<script setup>


</script>
<script>
export default {
  props: ["label", "route", "params", "badge"],
  data() {
    return {
      isTopLevel: false,
      hasChildren: false,
      bagdeChanged: 0,
      badgeCountLoaded: false
    };
  },
  mounted() {
    if (this.$el) {
      let parentNavItem = this.$el.parentElement.closest(".navigation-item");
      if (!parentNavItem) {
        this.isTopLevel = true;
      }
    }
    if (this.$slots && this.$slots.default) {
      let numberOfChildren = this.$slots.default().length;
      if (numberOfChildren > 0) {
        this.hasChildren = true;
      }
    }
  },
  computed: {
    companyPictureUrl() {
      return this.services.company.companyPictureUrl ? this.services.company.companyPictureUrl.value : null;
    },
    targetRoute() {
      try {
        const route = this.$router.resolve({ name: this.route });
        return route ? route : null;
      } catch (error) {
        return null;
      }
    },
    targetHref() {
      return this.targetRoute ? this.targetRoute.href : null;
    },
    isActive() {
      return this.targetRoute && this.$route.name == this.targetRoute.name;
    },
    isActiveParent() {
      //ionic doesnt work with child nodes
      if (!this.targetRoute) {
        return false;
      }
      return this.$route.path.startsWith(this.targetRoute.path + "/");
    },
    subscriptions() {
      return this.services.subscriptions.subscriptions ? this.services.subscriptions.subscriptions.value : [];
    },
  },
  
  methods: {
    navigateTo(event) {      
      event.preventDefault();
      this.$router.push(this.targetRoute)
    },

  }  
  
};
</script>
<style scoped>
.navigation-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  height: 3rem;
  background-color: transparent;
  
}

.main-route {
  cursor: pointer;
  display: flex;
  flex: 1;
  width: 100%;
  align-items: stretch;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  position: relative;
  object-fit: contain;
 
}
.logo-image{
  width: auto;
  height: 3rem;
  
}
.main-route .labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding-left: calc( 1.7rem + 16px + 0.7rem)
}

.has-image .main-route .labels {
  padding-left: calc( 1.7rem + 16px + 0.7rem - 3rem)
}
.main-route .label {
  flex: 0!important;
  text-decoration: none;
  color: var(--ion-color-medium);
  font-size: 0.8rem;
}

.navigation-item.is-active > .main-route .label {  
  flex: 1;
  text-decoration: none;
  color: var(--ion-color-primary);
}

.navigation-item.is-active {
  padding-right: 0;
}
.navigation-item.is-active > .main-route {
  border-right: 4px solid var(--ion-color-primary);
  background-color: #f1f2f4 !important;
}

.navigation-item.is-active-parent > .main-route {
  border-right: none;
  background-color: #f1f2f4 !important;
  border-right: 4px solid var(--ion-color-primary);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03);
}

.badge {
  display: inline-block;
  position: relative;
  border: 1px solid var(--ion-color-primary);
  border-radius: 2rem;
  min-width: 1rem;
  height: 16px;
  font-size: 0.7rem;
  text-align: center;
  line-height: 0.9rem;
  font-weight: 590;
  color: var(--ion-color-primary-contrast);
  background-color: var(--ion-color-primary);
  padding: 0 0.2rem;
}


.navigation-item ion-list ion-item ion-label {
  margin: 0;
}
.subscriptions-section{
  font-size: 0.7rem;
  color: #222;
  margin-top: 0rem;
}

.subscriptions-section .trialing,
.subscriptions-section .active {
  color: var(--ion-color-success);
}

.subscriptions-section .inactive {
  color: var(--ion-color-warning);
}

.subscriptions-section .incomplete {
  color: var(--ion-color-warning);
}
</style>
