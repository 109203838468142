<template>
<h2 class="oido-section-heading"  :class="{ 'has-help-text': helpText }">
  
  <slot></slot>
  <oido-help-icon class="help-text" v-if="helpText" :help-text="helpText" :position="helpTextPosition"></oido-help-icon>
</h2>  
</template>


<script >



export default {
  props: {
    helpText: String,    
    helpTextPosition: String,    
  },
  

};
</script>


<style scoped>
.oido-section-heading{
  margin: 0;
  padding: 0;
  padding-bottom: 0.2rem;
  font-size: 1rem;
  position: relative;
  font-weight: 500;
}
.oido-section-heading .oido-help-icon{
  margin-left: 0.2rem;
}

</style>
<style >
.oido-section-heading .oido-help-icon .help-text{  
  
  
}
</style>



