import firebaseService from "./firebaseService";
const firebaseApp = firebaseService();



import { toRefs, reactive, watch } from "vue";


import authenticationService from "./authenticationService";
const auth = authenticationService();


const state = reactive({
    userDataCache: {},
    initialized: false,
    initializing: false
});

const unsubscribeHandles = [];


export default function () {

    async function teardown() {
        for (let unsubscribeHandle of unsubscribeHandles) {
            await unsubscribeHandle();
        }
        //empty it
        unsubscribeHandles.length = 0;
        
    }



    async function getUser(uid) {

        if(!uid){
            return new Promise((resolve) => {resolve(null)});
        }
        if (!state.userDataCache[uid]) {
            const resp = await firebaseApp
                .firestore()
                .collection("users")
                .doc(uid ? uid : null)
                .get();

            if (resp.exists) {
                state.userDataCache[uid] = {
                    ...resp.data(),
                    id: uid
                }
            }
        }
        return state.userDataCache[uid] ? state.userDataCache[uid] : new Promise((resolve) => {resolve(null)});
    }



    if (!state.initialized && !state.initializing) {
        state.initializing = true;
        // watch(auth.user, async (user, prevUser) => {            
        //     if (user) {
                
        //     }
        // })

        // if (auth.user) {
            
        // }
        state.initialized = true
        state.initializing = false
    }



    return {
        ...toRefs(state),
        getUser,       

    };

}
