import { toRefs, reactive, watch, computed } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();
import zonesService from "./zonesService";
const zonesServiceInstance = zonesService();




import translationService from "./../composables/translationService";
const translationServiceInstance = translationService();


const unsubscribeHandles = [];

async function teardown() {

    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.orders = [];

    state.searchText = null;
}

const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    orders: [],
    visibleStatusSettings: ["new", "wip", "ready"], //TODO
    visibleStationSettings: ["table", "takeaway", "delivery"],
    visibleCourseSettings: [],
    limitToLocationIds: [],
    searchText: null,
    possibleStatusValues: ["new", "wip", "ready"]
});


async function loadOrders() {

    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }
    // return

    let ordersUnsubscribeHandle = firebase
        .firestore()
        .collection("orders")
        .where("companyId", "==", companyId)
        .where("status", "in", ['new', 'wip', 'ready'])
        .orderBy("dateOrdered", "desc")
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;
                if (type === "added") {
                    // console.log('changes', data)
                    state.orders.splice(newIndex, 0, data);
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.orders.splice(oldIndex, 1);
                    state.orders.splice(newIndex, 0, data);

                } else if (type === "removed") {
                    state.orders.splice(oldIndex, 1);
                }

            });
        })
    unsubscribeHandles.push(ordersUnsubscribeHandle);
}

async function updateOrder(order) {

    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    if (!order.id) {
        console.log('Error@updateOrder: no id', order)
    }

    order.companyId = companyId;

    await firebase
        .firestore()
        .collection("orders")
        .doc(order.id)
        .set(order, { merge: true })
}

const filteredOrders = computed(() => {
    let results = [];
    if (state.orders) {

        for (let order of state.orders) {

            if (!state.visibleStatusSettings || !state.visibleStatusSettings.includes(order.status)) {
                continue;
            }


            if (state.limitToLocationIds && state.limitToLocationIds.length > 0) {
                let includesAtLeastOneOpenProductOfAVisibleLocation = false;
                for (let item of order.items) {
                    // console.log('item.locationId', item.locationId, item.name, item)
                    if (!item.done && state.limitToLocationIds.includes(item.locationId)) {
                        includesAtLeastOneOpenProductOfAVisibleLocation = true
                        break
                    }
                }
                if (!includesAtLeastOneOpenProductOfAVisibleLocation) {
                    continue;
                }
            }


            let zone = zonesServiceInstance.getZoneById(order.zoneId ? order.zoneId : order.zoneId);
            let pointOfSale = zonesServiceInstance.getPointOfSaleById(order.pointofsaleId, order.zoneId)


            // console.log('zone', pointOfSale)

            //also accommodate older orders
            let deliveryType = order.deliveryType ? order.deliveryType : 'delivery'


            if (!state.visibleStationSettings || !state.visibleStationSettings.includes(deliveryType)) {
                continue;
            }


            if (state.searchText && state.searchText.length > 0) {
                let infoToSearch = ''

                for (let item of order.receiptItems) {
                    infoToSearch += translationServiceInstance.translation2Text(item.name)
                }

                if (pointOfSale && pointOfSale.name) {
                    infoToSearch += translationServiceInstance.translation2Text(pointOfSale.name)
                }

                if (zone && zone.name) {
                    infoToSearch += translationServiceInstance.translation2Text(zone.name) + ' ';
                }

                infoToSearch += order.name + ' '
                infoToSearch += order.email + ' '
                infoToSearch += order.id + ' '
                infoToSearch += order.stripePaymentIntentId + ' '

                if (order.phone) {
                    infoToSearch += order.phone + ' '
                }

                infoToSearch = infoToSearch.toLowerCase();

                if (!infoToSearch.includes(state.searchText.toLowerCase())) {
                    continue
                }

            }

            results.push(order);
        }
    }

    // filteredProducts.sort((a, b) => a.sortOrder - b.sortOrder);
    results.sort((a, b) => {
        return b.dateOrdered.seconds - a.dateOrdered.seconds;
        // if (a.status == b.status) {
        // return b.dateOrdered.seconds - a.dateOrdered.seconds;
        // }

        // return state.possibleStatusValues.indexOf(a.status) - state.possibleStatusValues.indexOf(b.status);
    });

    // console.log('results.length',results.length)
    return results;
})

const ordersByStatus = computed(() => {
    // console.log('ordersByStatus', filteredOrders.value);
    let ordersByStatus = {};
    for (let status of state.possibleStatusValues) {
        ordersByStatus[status] = [];
    }
    for (let order of filteredOrders.value) {
        ordersByStatus[order.status].push(order);
    }
    return ordersByStatus;
});

const numberOfNewOrders = computed(() => {
    // console.log('numberOfNewOrders', ordersByStatus.value);
    let orders = ordersByStatus.value ? ordersByStatus.value['new'] : null;
    return orders && orders.length > 0 ? orders.length : 0;
});





export default function () {

    if (!state.initialized && !state.initializing) {
        state.initializing = true;


        watch(auth.companyId, async (companyId) => {
            if (companyId) {
                loadOrders();
            } else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadOrders();
        }
        state.initialized = true
        state.initializing = false
    }




    return {
        ...toRefs(state),
        loadOrders,
        teardown,
        updateOrder,
        filteredOrders,
        ordersByStatus,
        numberOfNewOrders
    }


}