<template>
<div class="oido-modal">
  <slot></slot>
</div>  
</template>


<script >



export default {

  

};
</script>


<style scoped>
.oido-modal{
  display: flex;
  flex-direction: column;
  height: 100%;
}

</style>


