<template>
  <div class="oido-card-section-header">
    <h4>
      <slot></slot>
    </h4>
    <oido-help-icon class="help-text" v-if="helpText" :help-text="helpText" :position="helpTextPosition"></oido-help-icon>
    <div class="spacer"></div>
    <div class="actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>



export default {
  props: {
    helpText: String,    
    helpTextPosition: String,    
  },
  



};
</script>


<style scoped>
.oido-card-section-header{
  display: flex;
  padding: 0 0rem 1rem;
  
  /* margin-bottom: 0.5rem; */
  position: relative;
}
.oido-card-section-header h4{
  font-size: 1rem;
  font-weight: 599;
  padding: 0rem 0;
  margin: 0;
  margin-top: 1rem;
}
.help-text{
  padding-left: 0.2rem;  
}
.spacer{
  flex:1;
}
.oido-card-section-header .actions{
  display: flex;
}
</style>
<style>
.oido-card-section-header .actions .oido-button{
  margin-left: 0.9rem;
  font-size: 0.8rem;
}
</style>
<style>
.oido-card-section-header .help-text .oido-icon{
  top:0.1rem
}
</style>