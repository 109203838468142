<template>
<div class="oido-grid-row">
  <slot></slot>
</div>  
</template>


<script >



export default {

  

};
</script>


<style scoped>


.oido-grid-row{
  display: flex;
  justify-content: space-evenly;
  margin: 0 1rem;
}

@media (max-width: 900px) {
  .oido-grid-row {
    display: block;
  }

}
</style>


