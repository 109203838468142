import { toRefs, reactive, watch } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();
import axios from "axios";
import config from "../../config"

import fileServiceModule from "./fileService";
const fileService = fileServiceModule();


const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.pages = [];
    state.error = null;

}

const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    pages: []
});


async function loadPages() {

    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }

    //only load if we have permissions (or are the owner)
    if(!auth.owner && !(auth.permissions && auth.permissions.value && auth.permissions.value.includes('content'))){
        return;
    }

    let pagesUnsubscribeHandle = firebase
        .firestore()
        .collection("pages")
        .where("companyId", "==", companyId) 
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;
                if (type === "added") {
                    state.pages.splice(newIndex, 0, data);
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.pages.splice(oldIndex, 1);
                    state.pages.splice(newIndex, 0, data);
                } else if (type === "removed") {
                    state.pages.splice(oldIndex, 1);
                }
            });
        })
    unsubscribeHandles.push(pagesUnsubscribeHandle);
}


async function savePage(page) {
    
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }
    
    page.companyId = companyId

    //only upload picuture if ist really a blob
    if (page.picture && typeof page.picture == 'object') {
        page.picture = await fileService.uploadFile(page.picture);
    }    

    try {
        let token = await firebase.auth().currentUser.getIdToken();
        let response = await axios.post(config.apiUrl + "/api/pages/" + (page.id ? page.id : ''), page, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        if (!response || !response.data) {
            throw new Error("incomplete return value");
        }
    } catch (error) {
        throw error
    }

}

async function reorderPage(from, to) {
    if (!auth.user) {
        teardown();
        return;
    }

    let move = function (target, from, to) {
        target.splice(to, 0, target.splice(from, 1)[0]);
    };

    let affectedPage = state.pages[from];

    let copiedCats = JSON.parse(JSON.stringify(state.pages))
    move(copiedCats, from, to);
    let sortOrder = 1000;

    let previousSortOrder = to > 0 ? copiedCats[to - 1].sortOrder : null;
    let nextSortOrder = to < copiedCats.length - 1 ? copiedCats[to + 1].sortOrder : null;

    // console.log('previousSortOrder',previousSortOrder);
    // console.log('nextSortOrder', nextSortOrder);

    if (!previousSortOrder && nextSortOrder) {
        sortOrder = nextSortOrder - 500;
    }
    if (previousSortOrder && !nextSortOrder) {
        sortOrder = previousSortOrder + 500;
    }
    if (previousSortOrder && nextSortOrder) {
        sortOrder = previousSortOrder + (nextSortOrder - previousSortOrder) / 2;
    }
    // console.log('sortOrder', sortOrder);

    await firebase
        .firestore()
        .collection("pages")
        .doc(affectedPage.id)
        .set({ sortOrder: sortOrder }, { merge: true })
}

async function updatePage(page) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    if (!page.id) {
        console.log('Error@updatePage: no cat id', page)
    }

    if (!page.name) {
        page.name = {}
    }

    // console.log('Info@updatePage: cat id', JSON.stringify(page))
    page.companyId = companyId;

    await firebase
        .firestore()
        .collection("pages")
        .doc(page.id)
        .set(page, { merge: true })
}
async function deletePage(pageId) {
    if (!auth.user) {
        teardown();
        return;
    }
    if (!pageId) {
        console.log('Error@deletePage: no page id', pageId)
    }
    await firebase
        .firestore()
        .collection("pages")
        .doc(pageId)
        .delete()

}

export default function () {

    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        watch(auth.companyId, async (companyId) => {
            if (companyId) {
                loadPages();
            }else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadPages();
        }

        state.initialized = true
        state.initializing = false
    }





    return {
        ...toRefs(state),
        loadPages,
        reorderPage,
        teardown,
        updatePage,
        deletePage,        
        savePage

    }


}