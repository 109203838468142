<template>  
    <h3 class="oido-value-list-item" :class="{[typeClassName]: true}"><span class="label" v-text="label"></span>: <span class="value" v-text="preparedValue"></span></h3>  
</template>


<script>



export default {
  props: ["value", "label"],
  computed:{
    typeClassName() {
      return "type-" + (typeof this.value)
    },
    preparedValue(){
      if(typeof this.value == 'boolean'){
        return this.value ? 'yes' : 'no'
      }
      return this.value
    }

  }


};
</script>


<style scoped>
.oido-value-list-item {
  margin: 0 0 0.5rem 0;
}
 h3{
  font-size: 0.8rem;
  color: var(--ion-color-dark);
  margin: 0.5rem 0;

}
h3 .label{
  font-weight: 599;
  display: inline-block;
}
h3 .label:first-letter {
  display: none;
  display: none;
    text-transform: uppercase;
}

</style>
