import { toRefs, reactive, watch } from "vue";
import { Device } from '@capacitor/device';

import firebaseService from "./firebaseService";
const firebase = firebaseService();



import notificationService from "./notificationService";
const notifications = notificationService();

const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
});


export default function () {

    async function getId() {
        return await Device.getId();
    }
    async function getInfo() {
        return await Device.getInfo();
    }
    async function getBatteryInfo() {
        return await Device.getBatteryInfo();
    }
    async function getLanguageCode() {
        return await Device.getLanguageCode();

    }
    async function getDeviceIdForUid(uid) {
        let physicalID = await getId();
        // console.log('physicalID', physicalID)
        return physicalID.identifier + '' + uid;
    }

    async function updateDeviceForUser() {

        try {
            if (!firebase.auth().currentUser) {
                console.error('updateDeviceForUser without user')
                return;
            }
            let deviceId = await getDeviceIdForUid(firebase.auth().currentUser.uid);
            // let deviceInfo = await getInfo()
            let language = await getLanguageCode();


            let data = {
                deviceId: deviceId,
                language: language ? language.value : null,
                uid: firebase.auth().currentUser.uid,
                dateUpdated: new Date(),
                info: {
                    //     isVirtual: deviceInfo.isVirtual ? true : false,
                    //     manufacturer: deviceInfo.manufacturer ? deviceInfo.manufacturer : null,
                    //     model: deviceInfo.model ? deviceInfo.model : null,
                    //     operatingSystem: deviceInfo.operatingSystem ? deviceInfo.operatingSystem : null,
                    //     osVersion: deviceInfo.osVersion ? deviceInfo.osVersion : null,
                    //     platform: deviceInfo.platform ? deviceInfo.platform : null,
                    //     webViewVersion: deviceInfo.webViewVersion ? deviceInfo.webViewVersion : null,
                },
                token: notifications.token ? notifications.token.value : null
            }


            await firebase
                .firestore()
                .collection("devices")
                .doc(data.deviceId)
                .set(data, { merge: true });

        } catch (error) {
            console.log('error @ updateDeviceForUser', error);
        }
    }


    async function removeDeviceOfUser(user) {
        if (!user || !user.uid) {
            console.error('removeDeviceOfUser called without user', user);
            return;
        }
        let deviceId = await getDeviceIdForUid(user.uid);

        try {
            const resp = await firebase
                .firestore()
                .collection("devices")
                .doc(deviceId)
                .delete();
        } catch (error) {
            console.error('error@removeDeviceOfUser', error, deviceId, user.uid);
        }
    }


    if (!state.initialized && !state.initializing) {
        state.initializing = true;
        // console.log('initializing deviceService');


        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                updateDeviceForUser();
            }
        });


        watch(notifications.token, (token, prevToken) => {
            console.log('deviceService push notifiction token changed', token);
            updateDeviceForUser();
        })

        if (firebase.auth().currentUser) {
            updateDeviceForUser();
        }
        state.initialized = true
        state.initializing = false
    }





    return {
        ...toRefs(state),
        getId,
        getInfo,
        getBatteryInfo,
        getLanguageCode,
        updateDeviceForUser,
        removeDeviceOfUser
    }


}