<template>
  <div class="ml-input" :class="{'has-errors': errors && errors.length > 0, 'readonly': readonly, 'disabled': disabled, [type]: true}">
    <ion-label position="stacked">
      <span class="label" v-text="label"></span>
      <span class="required-marker" v-if="required"> *</span>
      <span class="validation-errors" v-if="errors">
        <span class="validation-error" v-for="error of errors" v-text="error" :key="error"></span>
      </span>
    </ion-label>
    <div class="input-header">
      <translation-selector v-if="translatable" v-show="!readonly" v-model="selectedLanguage"></translation-selector>
    </div>
    <div class="input-section">
      <span class="prefix" v-if="prefix" v-text="prefix" />
      <multi-lingual-text v-if="readonly && extractedModelValue" v-text="readOnlyPreviewText"></multi-lingual-text>
      <multi-lingual-text v-if="readonly && !extractedModelValue" v-html="'&nbsp;'"></multi-lingual-text>
      <ion-input
        v-if="!readonly && type != 'textarea'"
        ref="textinput"
        :legacy="true"
        class=""
        :modelValue="extractedModelValue"
        @update:modelValue="valueChanged"
        :type="type"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :name="name"
        :autocapitalize="autocapitalize"
      ></ion-input>

      <ion-textarea

        v-if="!readonly && type == 'textarea'"
        ref="textinput"
        :legacy="true"
        class=""
        :auto-grow="autogrow"
        :modelValue="extractedModelValue"
        @update:modelValue="valueChanged"
        :type="type"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :name="name"
        :autocapitalize="autocapitalize"
      ></ion-textarea>
      <span class="postfix" v-if="postfix" v-text="postfix" />
    </div>
  </div>
</template>

<script >
import { IonInput, IonLabel, IonTextarea } from "@ionic/vue";
import TranslationSelector from "./TranslationSelector.vue";
import MultiLingualText from "../components/MultiLingualText.vue";
export default {
  components: { TranslationSelector, IonInput, IonLabel, MultiLingualText, IonTextarea },
  data() {
    return {
      selectedLanguage: null,
    };
  },
  mounted() {
    if(this.focus){
      this.focusOnInput();

    }
  },
  props: {
    modelValue: [String, Object, Number],
    errors: [Object],
    translatable: { type: Boolean, default: true },
    name: String,
    type: String,
    placeholder: String,
    label: String,
    readonly: Boolean,
    required: Boolean,
    disabled: Boolean,
    autogrow: Boolean,
    autocomplete: String,
    autocapitalize: String,
    postfix: String,
    prefix: String,
    focus: Boolean
  },
  watch: {
    selectedLanguage() {
      this.focusOnInput;
    },
  },

  computed: {
    extractedModelValue() {
      if (!this.translatable || typeof this.modelValue != "object") {
        return this.modelValue;
      }

      if (this.modelValue && this.modelValue[this.selectedLanguage]) {
        return this.modelValue[this.selectedLanguage];
      }
      return null;
    },
    readOnlyPreviewText() {      
      if(this.extractedModelValue && this.type == 'password'){
        return '*'.repeat(this.extractedModelValue.length)
      }
      return this.extractedModelValue;

      
    },
  },

  methods: {
    focusOnInput() {
      this.$nextTick(() => {
        setTimeout(async () => {
          if (!this.$refs.textinput) {
            return;
          }
          (await this.$refs.textinput.$el.getInputElement()).focus();
        }, 200);
      });
    },
    valueChanged(newValue) {
      let value = newValue;
      if (this.translatable) {
        value = this.modelValue && typeof this.modelValue == "object" ? JSON.parse(JSON.stringify(this.modelValue)) : {};
        value[this.selectedLanguage] = newValue;
      }
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
<style scoped>
.ml-input {
  width: 100%;
  position: relative;
}
.ml-input ion-label {
  margin-left: 0.3rem;
  padding-bottom: 0.2rem;
  overflow: visible !important;
}
.ml-input .ml-text {
  padding-left: 0.3rem;
  font-weight: bolder;
}
.ml-input .translation-selector {
  position: absolute;
  right: 0.5rem;
  top: 0.1rem;
}

.input-section{
  position: relative;
  display: flex;
  align-items: baseline;
}
.readonly .input-section{
  display: inline-block;
word-break: break-all;
padding-bottom: 0.2rem;
width: 100%;
}
.ml-input:not(.readonly):not(.textarea) .input-section{
  border: 1px solid rgba(0,0,0,0.13);
  border-bottom:none;
  background-color: #f7f7f7;

}
.postfix{ 
  position: absolute;
  top: 50%;
  margin-top: -0.6rem;
  right: 0.5rem;
  z-index: 100;
}

.prefix{  
  font-size: 0.9rem;
  font-weight: 400;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
}
.readonly .prefix{  
  padding-right: 0rem;  
  
  display: inline;
}
.readonly .prefix + .ml-text{
  padding-left: 0.1rem;
  display: inline;
}

</style>
<style>
.ml-input .translation-selector ion-segment-button {
  margin-left: 0.1rem;
}
.ml-input .translation-selector ion-segment-button::part(native) {
  font-size: 0.6rem;
  padding: 0;
  height: 1.6rem;
}
.ml-input .ml-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.ml-input .validation-errors {
  display: inline;
  margin-left: 0.5rem;
}

.ml-input .validation-error {
  color: var(--ion-color-danger);
  font-size: 1rem;
  margin-bottom: 0;
}


form ion-item {
  --inner-padding-end: 0;
}

form ion-item::part(native) {
  padding-left: 0 !important;
}

form ion-item .ml-input ion-input .native-input {
  border: 1px solid #ddd;
  --padding-start: 0.5rem;
  background-color: rgba(255, 255, 0, 0.1);
  padding-top: 8px;
  padding-bottom: 8px;
}
form ion-item:not(.item-lines-none) .ml-input ion-input .native-input {
  border-bottom: none;
}
form ion-item.item-lines-none .ml-input {
  border-bottom: 1px solid #ddd !important;
}

form ion-item .ml-input ion-textarea {
  border: 1px solid #ddd;
  border-bottom: none;
}
form ion-item .ml-input ion-textarea .native-textarea {
 --padding-start: 1rem;
  background-color: rgba(255, 255, 0, 0.1);
}
form ion-item .ml-input.has-errors ion-input .native-input {
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.1);
}
.ml-input .native-input{
  border: none!important;
  background-color: #FFFFEC!important;
  opacity: 1;
}
.ml-input .prefix + ion-input > .native-input{
  border-left: 1px solid rgba(0,0,0,0.13)!important;
}
</style>