<template>
<div class="oido-modal-footer">
  <slot></slot>
</div>  
</template>


<script >



export default {

  

};
</script>


<style scoped>
.oido-modal-footer{
  padding: 1.5rem 2rem 1.5rem;
  display: flex;
  justify-content: end;
}
</style>
<style>
.oido-modal-footer .oido-button{
  margin-left: 2rem;
  font-size: 0.9rem
}
</style>


