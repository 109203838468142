import { createApp } from 'vue'
import {  watch } from "vue";
import { App as CapacitorApp } from '@capacitor/app';

import App from './App.vue'
import router from './router.js';
import { IonicVue } from '@ionic/vue';
import { createI18n } from 'vue-i18n'
import config from "../config"
import packageInfo from "../package.json"
import VueSelect from "vue-select";
import { Capacitor } from '@capacitor/core';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import deviceService from "./composables/deviceService";

import firebaseService from "./composables/firebaseService";

import notificationService from "./composables/notificationService";
import translationService from "./composables/translationService";
import companyService from "./composables/companyService";
import categoriesService from "./composables/categoriesService";
import coursesService from "./composables/coursesService";
import locationsService from "./composables/locationsService";
import tagsService from "./composables/tagsService";
import productsService from "./composables/productsService";
import printersService from "./composables/printersService";
import discountsService from "./composables/discountsService";
import accountsService from "./composables/accountsService";
import localeService from "./composables/localeService";
import subscriptionsService from "./composables/subscriptionsService";
import authenticationService from "./composables/authenticationService";
import zonesService from "./composables/zonesService";
import dashboardService from "./composables/dashboardService";
import ordersService from "./composables/ordersService";
import userService from "./composables/userService";
import mediaService from "./composables/mediaService";
import storiesService from "./composables/storiesService";
import pagesService from "./composables/pagesService";
import notificationsService from "./composables/notificationsService";

let translationServiceInstance = translationService();
let companyServiceInstance = companyService();

let userLanguage = navigator.language ? navigator.language.split('-')[0] : null;
if (!config.translations.languages.available.includes(userLanguage)) {
  userLanguage = 'en';
}

if (userLanguage) {
  translationServiceInstance.selectLanguage(userLanguage);
}


import combinedTranslations from "./translations/translations-backend";
let foundLanguages = Object.keys(Object.values(combinedTranslations)[0]);
let translations = {}
for (let language of foundLanguages) {
  translations[language] = {};
}
for (let key in combinedTranslations) {
  for (let language of foundLanguages) {
    if (
      typeof combinedTranslations[key][language] !== 'undefined'
      && (language == translationServiceInstance.defaultLanguage || combinedTranslations[key][language].length > 0)
      // skip empty translations except for the default language (which might be empty to hide the element)
    ) {
      translations[language][key] = combinedTranslations[key][language];
    }
  }
}



import Validator from "validatorjs";
import en from 'validatorjs/src/lang/en';
import de from 'validatorjs/src/lang/de';
import es from 'validatorjs/src/lang/es';
import fr from 'validatorjs/src/lang/fr';
import nl from 'validatorjs/src/lang/nl';
import ca from 'validatorjs/src/lang/ca';


es.required_with = translations['es']["validator-required-with-label"]
de.required_with = translations['de']["validator-required-with-label"]
fr.required_with = translations['fr']["validator-required-with-label"]
nl.required_with = translations['nl']["validator-required-with-label"]
ca.required_with = translations['ca']["validator-required-with-label"]


Validator.setMessages('en', en);
Validator.setMessages('de', de);
Validator.setMessages('nl', nl);
Validator.setMessages('es', es);
Validator.setMessages('ca', ca);
Validator.setMessages('fr', fr);
Validator.useLang(userLanguage);


let i18n = createI18n({
  // locale: translationServiceInstance.selectedLanguage.value,
  locale: userLanguage,
  fallbackLocale: translationServiceInstance.defaultLanguage,
  messages: translations,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  warnHtmlInMessage: 'off'
})

import { useTimestamp } from '@vueuse/core'
const now = useTimestamp({ interval: 60000 })

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import duration from "dayjs/plugin/duration";
dayjs.extend(duration)
import weekday from "dayjs/plugin/weekday";
dayjs.extend(weekday)

import updateLocale from 'dayjs/plugin/updateLocale'
dayjs.extend(updateLocale)
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)
watch(companyServiceInstance.settings, (settingsRef,oldSettings) => {
  if (settingsRef && settingsRef.timezone) {
    console.log('setting Timezone', settingsRef.timezone)
    dayjs.tz.setDefault('settingsRef.timezone')
  }
})

console.log('dayjs.locale()',dayjs.locale())
dayjs.updateLocale(dayjs.locale(), {
    weekStart: 1,
})

dayjs.updateLocale('en', {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: 'a moment',
    m: "a minute",
    mm: "%d mins",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years"
  }
})


import MultiLingualInput from "./components/MultiLingualInput.vue";
import MultiLingualText from "./components/MultiLingualText.vue";
import OidoTextInput from "./components/OidoTextInput.vue";
import OidoQuantityInput from "./components/OidoQuantityInput.vue";
import OidoSelect from "./components/OidoSelect.vue";
import OidoCheckbox from "./components/OidoCheckbox.vue";

import OidoButton from './components/OidoButton.vue';
import OidoCard from './components/OidoCard.vue';
import OidoCardSection from './components/OidoCardSection.vue';
import OidoCardSectionHeader from './components/OidoCardSectionHeader.vue';
import OidoValueList from './components/OidoValueList.vue';
import OidoValueListItem from './components/OidoValueListItem.vue';
import OidoDatePicker from './components/OidoDatePicker.vue';
import OidoCardTitle from './components/OidoCardTitle.vue';
import OidoCardSubtitle from './components/OidoCardSubtitle.vue';
import OidoCardHeader from './components/OidoCardHeader.vue';
import OidoCardFooter from './components/OidoCardFooter.vue';
import OidoCardContent from './components/OidoCardContent.vue';
import OidoModal from './components/OidoModal.vue';
import OidoModalTitle from './components/OidoModalTitle.vue';
import OidoModalSubtitle from './components/OidoModalSubtitle.vue';
import OidoModalHeader from './components/OidoModalHeader.vue';
import OidoModalContent from './components/OidoModalContent.vue';
import OidoModalFooter from './components/OidoModalFooter.vue';
import OidoDivider from './components/OidoDivider.vue';
import OidoIcon from './components/OidoIcon.vue';
import OidoGrid from './components/OidoGrid.vue';
import OidoGridRow from './components/OidoGridRow.vue';
import OidoGridColumn from './components/OidoGridColumn.vue';
import OidoSectionHeading from './components/OidoSectionHeading.vue';
import OidoHelpIcon from './components/OidoHelpIcon.vue';
import OidoPage from './components/OidoPage.vue';
import OidoToolbar from './components/OidoToolbar.vue';
import OidoBubble from './components/OidoBubble.vue';


import { createVfm } from 'vue-final-modal'




//check if there is a firebase user before continuing
const auth = authenticationService();

import mitt from 'mitt'

import { CapacitorUpdater } from '@capgo/capacitor-updater';
CapacitorUpdater.notifyAppReady();
CapacitorUpdater.addListener('appReady', () => {
  // console.log("CapacitorUpdater appReady")
  // Hide splash
  //SplashScreen.hide()
})

const emitter = mitt()


window.addEventListener("beforeprint", (event) => {  
  document.querySelectorAll('body > .printnode').forEach(node => node.remove())
  let printview = document.querySelector('.show-modal .shouldbeprinted')
  if (!printview) {
    printview = document.querySelector('.ion-page .ion-page:not(.ion-page-hidden) .shouldbeprinted')
  }
  if (!printview) {
    return;
  }  
  let clonedContent = printview.cloneNode(true)
  clonedContent.classList.add('printnode')
  document.body.appendChild(clonedContent);
});

window.addEventListener("afterprint", (event) => {
  document.querySelectorAll('body > .printnode').forEach(node => node.remove())
});




auth.authCheck().then(() => {

  // console.log('setting up push notifications')
  const device = deviceService();
  const firebase = firebaseService();
  const notifications = notificationService();

  notifications.setup(
    (notification) => {
      setTimeout(() => { notifications.clearAllNotifications(); }, 2000)
      const data = notification.notification.data;
      if (data.path) {
        router.push({ path: data.path });
      }
    }
  );
  setTimeout(() => { notifications.clearAllNotifications(); }, 2000)

  const app = createApp(App)
    .component("v-select", VueSelect)
    .use(IonicVue)
  app.use(i18n)
    .use(router);

    //init modal plugin
const vfm = createVfm()
app.use(vfm)

  CapacitorApp.addListener('appUrlOpen', function (event) {
    // console.log('appUrlOpen', event)
    const slug = event.url.split('.app').pop();

    // We only push to the route if there is a slug present
    if (slug) {
      router.push({
        path: slug,
      });
    }
  });




  app.config.globalProperties.emitter = emitter


  

  let toolbarShadowOpacity = 0;

  function isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  function adjustToolBarToScrollOffset(scrolledInPx) {
    let opacity = 1;
    if (isIOS()) {
      if (toolbarShadowOpacity == 1) { return }
      toolbarShadowOpacity = 1;

    } else {

      let offsetForOpaque = 100;
      opacity = scrolledInPx / offsetForOpaque < 1 ? scrolledInPx / offsetForOpaque : 1;
      //its already bigger than 1
      if (opacity > 1 && toolbarShadowOpacity == 1) {
        return;
      }
      //probably 0
      if (opacity == toolbarShadowOpacity) {
        return;
      }
      toolbarShadowOpacity = opacity;

    }


    document.getElementById('toolbar-shadow-opacity').sheet.cssRules[0].style.opacity = opacity
    document.getElementById('toolbar-shadow-opacity').sheet.cssRules[1].style.fontSize = ((1 - opacity) * 0.5 + 1.1) + 'rem'
    document.getElementById('toolbar-shadow-opacity').sheet.cssRules[2].style.paddingTop = ((1 - opacity) * 1) + 'rem'
    document.getElementById('toolbar-shadow-opacity').sheet.cssRules[2].style.paddingBottom = document.getElementById('toolbar-shadow-opacity').sheet.cssRules[2].style.paddingTop;
  }
  adjustToolBarToScrollOffset(0)

  router.afterEach((to, from) => {
    setTimeout(() => {
      let el = document.querySelector(':not(ion-app).ion-page:not(.ion-page-hidden) ion-content');
      if (el && el.scrollEl) {
        adjustToolBarToScrollOffset(el.scrollEl.scrollTop);
      }
    }, 100)
  })


  let services = {
    translations: translationServiceInstance,
    company: companyServiceInstance,
    courses: coursesService(),
    locations: locationsService(),
    categories: categoriesService(),
    products: productsService(),
    printers: printersService(),
    discountsService: discountsService(),
    accountsService: accountsService(),
    zones: zonesService(),
    dashboard: dashboardService(),
    orders: ordersService(),
    tags: tagsService(),
    locale: localeService(),
    subscriptions: subscriptionsService(),
    auth: auth,
    media: mediaService(),
    storiesService: storiesService(),
    pagesService: pagesService(),
    user: userService(),
    notifications: notificationsService(),
    pushNotifications: notifications,
    firebase
  }

  app.mixin(
    {
      components: {
        MultiLingualInput,
        MultiLingualText,
        OidoTextInput,
        OidoQuantityInput,
        OidoSelect,
        OidoCheckbox,
        OidoCard,
        OidoCardTitle,
        OidoCardSubtitle,
        OidoCardHeader,
        OidoCardFooter,
        OidoCardContent,
        OidoValueList,
        OidoValueListItem,
        OidoCardSection,
        OidoButton,
        OidoCardSectionHeader,
        OidoDatePicker,
        OidoModalFooter,
        OidoModal,
        OidoModalTitle,
        OidoModalSubtitle,
        OidoModalHeader,
        OidoModalContent,
        OidoDivider,
        OidoIcon,
        OidoGrid,
        OidoGridRow,
        OidoGridColumn,
        OidoSectionHeading,
        OidoHelpIcon,
        OidoPage,
        OidoToolbar,
        OidoBubble
      },
      computed: {
        config() {
          return config;
        },
        services() {
          return services;
        },
        version() {
          return packageInfo.version;
        },
        now() {
          return now.value;
        },
        loggedInUser() {
          return auth.user ? auth.user.value : null;
        },
        companyId() {
          return auth.companyId ? auth.companyId.value : null;
        },
        isOwner() {
          let companyId = auth.companyId ? auth.companyId.value : null
          let user = auth.user ? auth.user.value : null          
          return user && user.uid == companyId
        },
        permissions(){
          return auth.permissions ? auth.permissions.value : []
        },
        firebase() {
          return firebase
        },
        isApp() {
          return Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android'
        },
        platform() {
          return Capacitor.getPlatform()
        },
        device() {
          return device
        },
        categories() {
          return services.categories.categories ? services.categories.categories.value : null;
        },
        companySettings() {
          return services.company.settings ? services.company.settings.value : null;
        },
        company() {
          return services.company.company ? services.company.company.value : null;
        },
        companyInfo() {
          return services.company.info ? services.company.info.value : null;
        },
        courses() {
          return services.courses.courses ? services.courses.courses.value : null;
        },
        locations() {
          return services.locations.locations ? services.locations.locations.value : null;
        },
        tags() {
          return services.tags.tags ? services.tags.tags.value : null;
        },
        products() {
          return services.products.products ? services.products.products.value : null;
        },
        companiesCurrencySymbol() {
          return this.companySettings ? this.services.locale.getCurrencyInfo(this.companySettings.currency).symbol : ''
        },
        subscribedPlan() {
          if(!this.hasAnActiveSubscription){
            return null
          }
          return services.company.company.value.subscribedPlan;
        },
        hasAnActiveSubscription() {
          // if (Capacitor.getPlatform() === 'web' && (location.hostname === 'localhost' || location.hostname === '127.0.0.1')) {
          //   return true;
          // }
          // if (config.environment == "dev") {
          //   return true;
          // }
          if (config.environment == "testumgebung") {
            return true;
          }
          return services.company.company && services.company.company.value && services.company.company.value.hasAnActiveSubscription;
        },
        hasAValidStripeConnection() {
          if (config.environment == "testumgebung") {
            return true;
          }
          return services.company.company && services.company.company.value && services.company.company.value.hasAValidStripeConnection;
        },
        frameWidth(){
          return window.innerWidth
        }        
      },
      methods: {
        scrolled(event) {
          adjustToolBarToScrollOffset(event.detail.scrollTop);
        },
        adjustToolBarToScrollOffset(offset) {
          adjustToolBarToScrollOffset(offset);
        }
      }
    }
  )

  router.isReady().then(() => {
    app.mount('#app');
    // console.log("addPlausibleScript: ", location.hostname, location.port, location.protocol)
    if(location.hostname == 'www.oido.me'){      
      addPlausibleScript()
    }
  });
}, 1000);



function addPlausibleScript() {
  var s = document.createElement( "script" );
  s.setAttribute( "src", "https://plausible.io/js/script.js" );
  s.setAttribute( "data-domain", "oido.me/admin" );
  document.body.appendChild( s );
}
