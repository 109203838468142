import { Capacitor } from '@capacitor/core';

import {TestflightDetection} from "testflight-detection"
let isTestFlightVersion = (await TestflightDetection.isTestVersion()).value;

let options = {
  environment: 'production',
  url: "https://www.oido.me",
  apiUrl: "https://www.oido.me",
  cdnUrl: "https://www.oido.me",
  region: "europe-west1",
  translations: {
    languages: {
      available: ['de', 'en', 'es', 'ca', 'fr', 'nl'],//,'zh'],
      default: 'en',
      excludedFromAutomaticTranslation: ["ca"]
    }
  },
  permissions:[
    "orders",
    "products",
    "content",
    "statistics",
    // "discounts",
    "accounts",
    "zones",
    "settings",
  ],
  stripe: {
    pricingTables: {      
      existingUsers: {
        de: "prctbl_1MBGmsKqWyyaaeNx8E4WOsze",
        en: "prctbl_1MBGmsKqWyyaaeNx8E4WOsze",
        es: "prctbl_1MBGmsKqWyyaaeNx8E4WOsze",
        ca: "prctbl_1MBGmsKqWyyaaeNx8E4WOsze",
      }
    },
    publishableKey: "pk_live_51M6YauKqWyyaaeNxpNcOYV6s6bzI7dDTOevLw7Ip9BCrKsNiYoNKcss7BtTbN7dxFGuNxaWawD1JIOZiq8q9niol00G78ei9MK"
  },
  takenSlugs: ["admin", "assets", "favico", "js", "css", "site.webmanifest", 'en','es','de', 'ca', 'nl','fr', 'zh']
};

if (Capacitor.getPlatform() === 'web' && location.hostname === 'oidowithbugs.web.app') {
  options.environment = 'dev'
  options.url = "https://oidowithbugs.web.app";
  options.apiUrl = "https://oidowithbugs.web.app";
  options.stripe = {
    pricingTables: {
      existingUsers: {
        de: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
        en: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
        es: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
        ca: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
      }
    },
    publishableKey: "pk_test_51M5VUnKSRAYGSASFhqLc78ZynYh29q53iCSmg71BQCbX9dn12KuopmZG3Dfvoy3lUh7DIBSnqwg4hHueDLuRkX8k00X92bLseu"
  }
}
if (Capacitor.getPlatform() === 'ios') {
  // // options.environment = 'dev'
  // // options.url = "https://oidowithbugs.web.app";
  // // options.apiUrl = "https://oidowithbugs.web.app";
  // // options.stripe = {
  // //   pricingTables: {
  // //     newUsers: {
  // //       de: "prctbl_1M5VZ5KSRAYGSASF6tIY506C",
  // //       en: "prctbl_1M5VZ5KSRAYGSASF6tIY506C",
  // //       es: "prctbl_1M5VZ5KSRAYGSASF6tIY506C",
  // //       ca: "prctbl_1M5VZ5KSRAYGSASF6tIY506C",
  // //     },
  // //     existingUsers: {
  // //       de: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
  // //       en: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
  // //       es: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
  // //       ca: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
  // //     }
  // //   },
  // //   publishableKey: "pk_test_51M5VUnKSRAYGSASFhqLc78ZynYh29q53iCSmg71BQCbX9dn12KuopmZG3Dfvoy3lUh7DIBSnqwg4hHueDLuRkX8k00X92bLseu"
  // }
}


if (
  (Capacitor.getPlatform() === 'web' && (location.hostname === 'oido-testumgebung.web.app' || location.hostname === 'testumgebung.oido.me' || location.hostname === 'demo.oido.me')) ||
  (Capacitor.getPlatform() === 'ios' && isTestFlightVersion) 
) {
  console.log('Detected Testflight: using demo.oido system')
  options.environment = 'testumgebung'
  options.url = "https://demo.oido.me";
  options.apiUrl = "https://demo.oido.me";
  options.stripe = {
    pricingTables: {
      existingUsers: {
        de: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
        en: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
        es: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
        ca: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
      }
    },
    publishableKey: "pk_test_51NssUyIWSqxYaigh1QQpyC0BeBEtXcZ22DleLm0cRW6R21G5i5Im8pJgRkIvHUYWe7QYgL7RaZm9AIjMkcJHv8Gn00FB4tnxJ8"
  }
}

// if (Capacitor.getPlatform() === 'web' && location.hostname === 'oidowithbugs.web.app') {
//   options.environment = 'dev'
//   options.url = "https://oidowithbugs.web.app";
//   options.apiUrl = "https://oidowithbugs.web.app";  
//   options.stripe = {
//     pricingTables:{
//       newUsers:{
//         de: "prctbl_1MAzsIKqWyyaaeNxphumrFia",
//         en: "prctbl_1MAzsIKqWyyaaeNxphumrFia",
//         es: "prctbl_1MAzsIKqWyyaaeNxphumrFia",
//         ca: "prctbl_1MAzsIKqWyyaaeNxphumrFia",
//       },
//       existingUsers: {
//         de: "prctbl_1MBGmsKqWyyaaeNx8E4WOsze",
//         en: "prctbl_1MBGmsKqWyyaaeNx8E4WOsze",
//         es: "prctbl_1MBGmsKqWyyaaeNx8E4WOsze",
//         ca: "prctbl_1MBGmsKqWyyaaeNx8E4WOsze",
//       }
//     },
//     publishableKey: "pk_live_51M6YauKqWyyaaeNxpNcOYV6s6bzI7dDTOevLw7Ip9BCrKsNiYoNKcss7BtTbN7dxFGuNxaWawD1JIOZiq8q9niol00G78ei9MK"
//   }
// }

if (Capacitor.getPlatform() === 'web' && (location.hostname === 'localhost' || location.hostname === '127.0.0.1')) {
  options.environment = 'dev'
  options.url = "https://oidowithbugs.web.app";
  options.apiUrl = "https://oidowithbugs.web.app";  

  // options.environment = 'production'
  // options.url = "https://www.oido.me";
  // options.apiUrl = "https://www.oido.me";


  // options.environment = 'local'
  // options.url = "http://localhost:5002";
  // options.apiUrl = "http://localhost:5002";

  console.log('using localhost as apiUrl and url', options.apiUrl, options.url)
  options.stripe = {
    pricingTables: {
      existingUsers: {
        de: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
        en: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
        es: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
        ca: "prctbl_1M7l99KSRAYGSASF6ty0CAns",
      }
    },
    publishableKey: "pk_test_51M5VUnKSRAYGSASFhqLc78ZynYh29q53iCSmg71BQCbX9dn12KuopmZG3Dfvoy3lUh7DIBSnqwg4hHueDLuRkX8k00X92bLseu"
  }
}

export default options;