import { toRefs, reactive, watch, ref } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();
import axios from "axios";
import config from "../../config"
import { v4 as uuidv4 } from 'uuid';

const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.zones = [];

}
const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    zones: []
});

let zonesById = ref({});
let posByIdCache = ref({});

async function loadZones() {
    
    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }

    let zonesUnsubscribeHandle = firebase
        .firestore()
        .collection("zones")
        .where("companyId", "==", companyId)
        .orderBy("sortOrder", "asc")
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();                
                data.id = doc.id;
                if (type === "added") {
                    state.zones.splice(newIndex, 0, data);
                    zonesById.value[data.id] = state.zones[newIndex];
                    if (data.pointsOfSale) {
                        for (let pos of data.pointsOfSale) {
                            pos.zoneId = data.id
                            posByIdCache.value[pos.id] = pos
                        }
                    }
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.zones.splice(oldIndex, 1);
                    state.zones.splice(newIndex, 0, data);
                    zonesById.value[data.id] = state.zones[newIndex];
                    if (data.pointsOfSale) {
                        for (let pos of data.pointsOfSale) {
                            posByIdCache.value[pos.id] = pos
                        }
                    }

                } else if (type === "removed") {
                    console.log('removed pos doc', doc)
                    state.zones.splice(oldIndex, 1);
                    delete zonesById.value[data.id]

                }

            });
        })
    unsubscribeHandles.push(zonesUnsubscribeHandle);
}


async function addZone(zone) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }



    let sortOrder = 1000;
    if (state.zones && state.zones.length > 0) {
        for (let zone of state.zones) {
            if (zone.sortOrder >= sortOrder) {
                sortOrder = zone.sortOrder + 500;
            }
        }
    }

    zone.companyId = companyId;
    zone.sortOrder = sortOrder;
    zone.hidden = Boolean(zone.hidden);
    zone.closed = Boolean(zone.closed);
    zone.description = zone.description || null;
    zone.checkoutNotice = zone.checkoutNotice || null;
    zone.frontpageNotice = zone.frontpageNotice || null;
    zone.orderOptionsNotice = zone.orderOptionsNotice || null;
    zone.collectPhoneNumber = zone.collectPhoneNumber || false;
    zone.enableGeneralNote = zone.enableGeneralNote || false;
    zone.enableVouchers = zone.enableVouchers || false;
    zone.enableOnlinePayment = typeof zone.enableOnlinePayment == 'boolean' ? zone.enableOnlinePayment : true;
    zone.enableOfflinePayment = zone.enableOfflinePayment || false;
    zone.enableDelivery = zone.enableDelivery || false;
    zone.enablePickup = typeof zone.enablePickup == 'boolean' ? zone.enablePickup : true;    
    zone.enableTableService = zone.enableTableService || false;
    zone.pointsOfSale = zone.pointsOfSale || null;
    zone.categoryVisibilityRule = zone.categoryVisibilityRule || 'all';
    zone.categoryVisibilityConfig = zone.categoryVisibilityConfig || [];
    zone.pagesVisibilityRule = zone.pagesVisibilityRule || 'all';
    zone.pagesVisibilityConfig = zone.pagesVisibilityConfig || [];
    zone.storiesVisibilityRule = zone.storiesVisibilityRule || 'all';
    zone.storiesVisibilityConfig = zone.storiesVisibilityConfig || [];    
    zone.displayDeliveryTimeRule = zone.displayDeliveryTimeRule || "always";
    zone.displayDeliveryTimeTreshold = zone.displayDeliveryTimeTreshold || null;
    zone.displayDeliveryTimeTresholdUnit = zone.displayDeliveryTimeTresholdUnit || "days";
    zone.allowCustomPickupTime = Boolean(zone.allowCustomPickupTime)    
    zone.allowDeliveryOnInvoice = Boolean(zone.allowDeliveryOnInvoice)    


    let token = await firebase.auth().currentUser.getIdToken();

    let response = await axios.post(config.apiUrl + "/api/zones/", zone, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });
    if (!response || !response.data) {
        throw new Error("incomplete return value");
    }

}


async function getQRCodesPDF(options) {

    let token = await firebase.auth().currentUser.getIdToken();

    let response = await axios.post(config.apiUrl + "/api/pointsofsales.pdf", {}, {
        headers: {
            Authorization: "Bearer " + token,
        },
        responseType: "arraybuffer",
    });
    return response.data;
}
async function getQRCodesCSV(options) {

    let token = await firebase.auth().currentUser.getIdToken();

    let response = await axios.post(config.apiUrl + "/api/pointsofsales.csv", {}, {
        headers: {
            Authorization: "Bearer " + token,
        },
        responseType: "arraybuffer",
    });
    return response.data;
}

async function reorderZone(from, to) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    let move = function (target, from, to) {
        target.splice(to, 0, target.splice(from, 1)[0]);
    };

    let affectedZone = state.zones[from];

    let copiedItems = JSON.parse(JSON.stringify(state.zones))
    move(copiedItems, from, to);
    let sortOrder = 1000;

    let previousSortOrder = to > 0 ? copiedItems[to - 1].sortOrder : null;
    let nextSortOrder = to < copiedItems.length - 1 ? copiedItems[to + 1].sortOrder : null;

    // console.log('previousSortOrder', previousSortOrder);
    // console.log('nextSortOrder', nextSortOrder);

    if (!previousSortOrder && nextSortOrder) {
        sortOrder = nextSortOrder - 500;
    }
    if (previousSortOrder && !nextSortOrder) {
        sortOrder = previousSortOrder + 500;
    }
    if (previousSortOrder && nextSortOrder) {
        sortOrder = previousSortOrder + (nextSortOrder - previousSortOrder) / 2;
    }


    await firebase
        .firestore()
        .collection("zones")
        .doc(affectedZone.id)
        .set({ sortOrder: sortOrder }, { merge: true })


}


async function updateZone(zone) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    if (!zone.id) {
        console.error('Error@updateZone: no id', zone)
        return
    }

    zone.companyId = companyId;
    zone.description = zone.description || null;
    zone.hidden = Boolean(zone.hidden);
    zone.closed = Boolean(zone.closed);
    zone.checkoutNotice = zone.checkoutNotice || null;
    zone.frontpageNotice = zone.frontpageNotice || null;
    zone.orderOptionsNotice = zone.orderOptionsNotice || null;
    zone.collectPhoneNumber = zone.collectPhoneNumber || false;
    zone.enableGeneralNote = zone.enableGeneralNote || false;
    zone.enableVouchers = zone.enableVouchers || false;
    zone.enableOnlinePayment = typeof zone.enableOnlinePayment == 'boolean' ? zone.enableOnlinePayment : true;
    zone.enableOfflinePayment = zone.enableOfflinePayment || false;
    zone.enableDelivery = zone.enableDelivery || false;
    zone.enablePickup = typeof zone.enablePickup == 'boolean' ? zone.enablePickup : true;
    zone.enableTableService = zone.enableTableService || false;
    zone.pointsOfSale = zone.pointsOfSale || [];
    zone.categoryVisibilityRule = zone.categoryVisibilityRule || 'all';
    zone.categoryVisibilityConfig = zone.categoryVisibilityConfig || [];
    zone.pagesVisibilityRule = zone.pagesVisibilityRule || 'all';
    zone.pagesVisibilityConfig = zone.pagesVisibilityConfig || [];
    zone.storiesVisibilityRule = zone.storiesVisibilityRule || 'all';
    zone.storiesVisibilityConfig = zone.storiesVisibilityConfig || [];
    zone.displayDeliveryTimeRule = zone.displayDeliveryTimeRule || "always";
    zone.displayDeliveryTimeTreshold = zone.displayDeliveryTimeTreshold || null;
    zone.displayDeliveryTimeTresholdUnit = zone.displayDeliveryTimeTresholdUnit || "days";
    zone.allowCustomPickupTime = Boolean(zone.allowCustomPickupTime)    
    zone.allowDeliveryOnInvoice = Boolean(zone.allowDeliveryOnInvoice)    
    
    let token = await firebase.auth().currentUser.getIdToken();
    let response = await axios.post(config.apiUrl + "/api/zones/" + zone.id, zone, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });
    if (!response || !response.data) {
        throw new Error("incomplete return value");
    }

}

async function deleteZone(zoneId) {
    if (!auth.user) {
        teardown();
        return;
    }
    if (!zoneId) {
        console.log('Error@deleteZone: no zoneId', zoneId)
    }

    await firebase
        .firestore()
        .collection("zones")
        .doc(zoneId)
        .set({ archived: true }, { merge: true })
}



function getZoneById(zoneId) {
    if (!zoneId) {
        return null
    }
    return zonesById && zonesById.value && zonesById.value[zoneId] ? zonesById.value[zoneId] : null;
}

function getPointOfSaleById(posId, zoneId) {
    if (posByIdCache.value && posByIdCache.value[posId]) {
        return posByIdCache.value[posId]
    }
    let zone = getZoneById(zoneId);
    if (!zone) {
        console.log('getPointOfSaleById: Zone not found', zoneId)
        return null
    }
    for (let pos of zone.pointsOfSale) {
        if (pos.id == posId) {
            pos.zoneId = zone.id
            posByIdCache.value[posId] = pos;
            // console.log('getPointOfSaleById: searche', posByIdCache.value[posId])
            return posByIdCache.value[posId];
        }
    }
    // console.log('getPointOfSaleById: nothing hit',posId, zoneID)
    return null;
}

async function addPointOfSale(zoneId, name, description, quantity) {
    let zone = getZoneById(zoneId);
    if (!zone) {
        console.error('addPointOfSale zone not found for id:', zoneId)
    }

    if (!zone.pointsOfSale) {
        zone.pointsOfSale = [];
    }

    for (let i = 0; i < quantity; i++) {
        let posName = JSON.parse(JSON.stringify(name))
        if (quantity > 1) {
            if (typeof posName == 'string') {
                posName = posName + ' ' + (i + 1)
            } else {
                for (let language in posName) {
                    posName[language] = posName[language] + ' ' + (i + 1)
                }
            }
        }
        let pos = {
            name: posName,
            description: description,
            id: uuidv4()
        }
        zone.pointsOfSale.push(pos)
    }
    await updateZone(zone);
}

async function updatePointOfSale(pointofsaleId, zoneId, name, description) {
    let zone = getZoneById(zoneId);
    if (!zone) {
        console.error('updatePointOfSale pos not found for id:', zoneId)
    }

    if (!zone.pointsOfSale) {
        zone.pointsOfSale = [];
    }

    for (let pos of zone.pointsOfSale) {
        if (pos.id == pointofsaleId) {
            pos.name = name;
            pos.description = description;
            break
        }
    }

    await updateZone(zone);

}

async function toggleVisibility(zoneId) {
    let zone = getZoneById(zoneId);
    if (!zone) {
        console.error('toggleVisibility zone not found for id:', zoneId)
    }
    zone.hidden = !Boolean(zone.hidden);

    await updateZone(zone);
}

async function toggleClosed(zoneId) {
    let zone = getZoneById(zoneId);
    if (!zone) {
        console.error('toggleClosed zone not found for id:', zoneId)
    }
    zone.closed = !Boolean(zone.closed);

    await updateZone(zone);
}


async function deletePointofcomsumption(pointofsaleId, zoneId) {
    if (!auth.user) {
        teardown();
        return;
    }
    let zone = getZoneById(zoneId);
    if (!zone) {
        console.error('deletePointofcomsumption pos not found for id:', zoneId)
    }

    if (!zone.pointsOfSale) {
        zone.pointsOfSale = [];
    }

    let indexOfPos = undefined;
    for (let pos of zone.pointsOfSale) {
        if (pos.id == pointofsaleId) {
            indexOfPos = zone.pointsOfSale.indexOf(pos)
            break
        }
    }

    if (indexOfPos !== undefined && indexOfPos != -1) {
        zone.pointsOfSale.splice(indexOfPos, 1);
    }

    await updateZone(zone);

}


export default function () {


    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        watch(auth.companyId, async (companyId) => {
            if (companyId) {
                loadZones();
            }else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadZones();
        }
        state.initialized = true
        state.initializing = false
    }

    return {
        ...toRefs(state),
        loadZones,
        reorderZone,
        teardown,
        updateZone,
        deleteZone,
        addZone,
        getZoneById,
        getQRCodesPDF,
        getQRCodesCSV,
        addPointOfSale,
        deletePointofcomsumption,
        updatePointOfSale,
        toggleVisibility,
        toggleClosed,
        getPointOfSaleById
    }


}