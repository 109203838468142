<template>
  <div class="oido-button"
    :class="{ [buttonStyle]: Boolean(buttonStyle), 'has-label': Boolean(label), 'has-icon': Boolean(icon),'icon-only': Boolean(icon) && !Boolean(label) && !Boolean(badge), 'primary': Boolean(primary), [icon]: Boolean(icon) && !iconIsSVGCode, 'disabled': disabled }">
    <span class="loader" v-if="showSpinner"></span>
    
    <span class="icon" v-if="icon" v-html="svg"></span>
    <div class="label" v-if="label" v-text="label"></div>
    <span class="badge" v-if="badge" v-text="badge"></span>
  </div>
</template>


<script>

import icons from './icons';

export default {
  props: ['label', 'buttonStyle', "icon", "showSpinner", "primary", "disabled", "badge"],
  computed: {
    iconIsSVGCode(){
      return  this.icon && (''+this.icon).startsWith('<svg')
    },
    svg() {
      let svg = null
      if (icons[this.icon]) {
        svg = icons[this.icon]
      }
      if(this.iconIsSVGCode){
        svg = ''+this.icon
      }
      return svg
    }
  }
};
</script>


<style scoped>
.oido-button {
  font-size: 1rem;
  
  padding: 0rem 0;

  -webkit-user-select: none; /* Safari */
  user-select: none;

  font-weight: 400;
  display: inline-block;
  color: var(--ion-color-medium);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.oido-button.primary {
  color: var(--ion-color-primary);
}

.oido-button.bordered {
  border:1px solid var(--ion-color-primary);  
  padding: 0.7rem 1rem;
  border-radius: 3rem;
}
.oido-button.solid {
  background-color: var(--ion-color-medium);
  color: white;
  padding: 0.7rem 1rem;
}

.oido-button.solid.primary {
  background-color: var(--ion-color-primary);
}
.oido-button.disabled{
  pointer-events: none;
  color: #bbb;
}
.oido-button.solid.disabled {
  background-color: #ddd;
  color: white;
}

.icon {
  width: 1.3em;
  height: 1.3em;
  margin-right: .3em;
  position:relative;
  
}

.icon-only .icon{
  margin-right: 0;
}
.oido-button.qrcode .icon {
  width: 1.3em;
  height: 1.3em;
  padding: 0.1em;
}

.loader {
  width: 17px;
  height: 17px;
  margin: 0 0.5rem;
  border: 2px solid var(--ion-color-primary);
  opacity: 0.8;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1.3s linear infinite;
}
.oido-button.solid .loader{
  border: 2px solid rgba(255, 255, 255, 0.9);
  border-bottom-color: transparent;
  opacity: 1;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
<style>
.oido-button:not(.qrcode-doc) svg {
  stroke: var(--ion-color-medium);
  fill: transparent;
  stroke-width: 25px;
}
.oido-button.scan svg {
  stroke: transparent;
  fill: var(--ion-color-medium);
  stroke-width: 1px;
}

.oido-button.add-note{
  font-size: 1.35rem;

}
.oido-button.numbered-list svg,
.oido-button.bullet-list svg,
.oido-button.add-note svg,
.oido-button.note svg {
  stroke: transparent;
  stroke-width: 0px;
}
.oido-button.numbered-list svg *,
.oido-button.bullet-list svg *,
.oido-button.add-note svg *,
.oido-button.note svg *{  
  stroke-width: 0px!important;  
  fill: var(--ion-color-medium)!important;  
}
.oido-button svg {
  overflow: visible;
  width: 100%;
}
.oido-button.solid svg {
  stroke: white;
}

.oido-button.primary svg {
  stroke: var(--ion-color-primary);
}
.oido-button.disabled svg {
  stroke: #bbb;
}
.oido-button.disabled.solid svg {
  stroke: white;
}

.oido-button.hidden svg{
  stroke: transparent;
  fill: var(--ion-color-medium);
  stroke-width: 0px;
}

.oido-button.solid,
.oido-button.bordered{
  .icon {
    margin-right: 0.5rem;
  }
}

.badge{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.3rem;
  border-radius: 0.9rem;
  min-width: 0.9rem;
  line-height: 0.9rem;
  font-size: 0.6rem;  
  background-color: var(--ion-color-primary);
  color: white;
  font-weight:400;
  
}
</style>
