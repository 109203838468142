<template>
<div class="oido-card-content">
  <slot></slot>
</div>  
</template>


<script >



export default {

  

};
</script>


<style scoped>
.oido-card-content{
  padding: 1.6rem;
  height: 100%;
}
</style>


