<template>
  <div class="oido-toolbar">
    <slot></slot>
    <div class="actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>


<script>



export default {
  props: {
    secondary: Boolean,    
  },
  data() {
    return {
      localValue: null
    };
  },
  mounted() {
    
  },
  



};
</script>


<style scoped>
.oido-toolbar {
  padding: 0.3rem 1rem 0.35rem;  
  display: flex;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  align-items: center
}

.oido-toolbar .actions {
  display: flex;
  justify-content: flex-end;  
}
</style>

<style>
  ion-toolbar:has( + .oido-toolbar){    
    border-bottom: 1px solid #ddd;
    box-shadow: 0 0px 15px  rgba(0, 0, 0, 0.05);
  }
  .oido-toolbar .options .option{
    display: flex;
  }
  .oido-toolbar ion-button{
    min-height:0 !important;
  }
  .oido-toolbar ion-button::part(native) { 
    white-space:nowrap;
    padding: 0.15rem 0.6rem;
    min-height:0;
  }

</style>
