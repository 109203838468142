import { toRefs, reactive } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import deviceServiceModule from "./deviceService";
const deviceService = deviceServiceModule()

import axios from "axios";
import config from "../../config"
import Validator from "validatorjs";

const userDataRules = {
    name: "required|min:3",
    bio: "min:1"
};

const state = reactive({
    user: null,
    companyId: null,
    loading: true,
    error: null,
    userData: null,
    initialized: false,
    claims: [],
    permissions: [],
    owner: false
});

function getBuffer(file) {
    const reader = new FileReader();
    return new Promise((resolve) => {
        reader.onload = (ev) => {
            resolve(ev.target.result);
        };
        reader.readAsArrayBuffer(file);
    });
}



export default function () {


    const sendResetPasswordLink = async () => {
        if (!state.user && !state.user.email) {
            return;
        }
        await firebase.auth().sendPasswordResetEmail(state.user.email)
    };

    const deleteAccount = async (data) => {
        let token = await firebase.auth().currentUser.getIdToken();

        let response = await axios.post(config.apiUrl + "/api/deleteAccount", data, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        if (!response || !response.data) {
            throw new Error("incomplete return value");
        }
        await logout();
    };

    const updateUserData = async (data) => {
        let token = await firebase.auth().currentUser.getIdToken();

        let validation = new Validator(data, userDataRules);
        if (validation.fails()) {
            throw new Error({
                type: 'validation',
                errors: validation.errors.errors
            })
        }


        if (data.picture) {

            let fileData = await getBuffer(data.picture);

            try {
                let uploadLinkResponse = await axios.post(
                    config.apiUrl + "/api/create-profile-picture-uploadlink/",
                    { type: data.picture.type },
                    {
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    }
                )

                let uploadLink = uploadLinkResponse.data.url;
                let fileName = uploadLinkResponse.data.fileName;

                let uploadResponse = await axios.put(uploadLink, fileData, { headers: { "Content-Type": data.picture.type } });


                data.picture = { type: fileData.type, name: fileName };

            } catch (error) {
                console.log("create-profile-picture-uploadlink error", error);
                throw new Error("Error creating upload link");
            }
        }

        let response = await axios.post(config.apiUrl + "/api/me", data, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        if (!response || !response.data) {
            throw new Error("incomplete return value");
        }

    };

    const getUserData = async () => {

        let idToken = await firebase.auth().currentUser.getIdTokenResult()
        console.log('idToken', idToken)

        if (idToken.claims && idToken.claims.companyId) {
            state.companyId = idToken.claims.companyId
        } else {
            state.companyId = firebase.auth().currentUser.uid
        }
        state.claims = idToken.claims ? idToken.claims : []
        state.permissions = idToken.claims.permissions ? idToken.claims.permissions : []
        state.owner = idToken.claims.owner ? idToken.claims.owner : false

        console.log('state.permissions', state.permissions)
        console.log('state.companyId', state.companyId)


        const resp = await firebase
            .firestore()
            .collection("users")
            .doc(firebase.auth().currentUser ? firebase.auth().currentUser.uid : null)
            .get();

        if (resp.exists) {
            return {
                ...resp.data(),
                id: firebase.auth().currentUser ? firebase.auth().currentUser.uid : null
            }
        }



    };


    const updateOnlineState = async (online) => {
        if (!firebase.auth().currentUser) {
            return;
        }
        const resp = await firebase
            .firestore()
            .collection("activity")
            .doc(firebase.auth().currentUser.uid)
            .set({ 'lastTimeOnline': new Date() }, { merge: true });
    };


    /**
     *
     * @param email
     * @param password
     */
    const login = (email, password) => {
        state.loading = true;
        return firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(
                async (loginResult) => {
                    console.log('loginResult', loginResult)

                    if (!loginResult.user.emailVerified) {
                        await logout();
                        throw new Error('not-verified');
                    }
                    state.loading = false;
                    state.error = null;
                    state.userData = await getUserData();
                    state.user = loginResult.user;
                    return loginResult;
                },
                (error) => {
                    state.error = error;
                    state.loading = false;
                    throw error;
                }
            )
            .catch((error) => {
                // Handle Errors here.
                state.error = error;
                state.loading = false;
                throw error;
            });
    };
    const loginWithToken = (token) => {
        state.loading = true;

        return firebase
            .auth()
            .signInWithCustomToken(token)
            .then(
                async (loginResult) => {
                    console.log('loginResult', loginResult)

                    //verification doesnt apply to subaccounts
                    if (loginResult.user.email && !loginResult.user.emailVerified) {
                        await logout();
                        throw new Error('not-verified');
                    }

                    state.loading = false;
                    state.error = null;
                    state.userData = await getUserData();
                    console.log('state.userData', state.userData)
                    state.user = loginResult.user;
                    console.log('state.user', state.user)
                    return loginResult;
                },
                (error) => {
                    state.error = error;
                    state.loading = false;
                    throw error;
                }
            )
            .catch((error) => {
                // Handle Errors here.
                state.error = error;
                state.loading = false;
                throw error;
            });
    };

    /**
     *
     * @param email
     * @param password
     */
    const register = async (data) => {
        let response = await axios.post(config.apiUrl + "/api/registration", data);
        if (!response || !response.data) {
            throw new Error("incomplete return value");
        }
    }

    /**
     *
     * @param email
     */
    const sendForgotPasswordMail = async (email) => {
        if (email) {
            await firebase.auth().sendPasswordResetEmail(email)
        }
    }

    /**
     *
     * @param email
     */
    const requestVerificationMail = async (email, redirect) => {
        try {
            if (email) {
                let response = await axios.post(config.apiUrl + "/api/sendVerificationLink", { email, redirect });
            }
        } catch (error) {
            console.log("Error requesting verification Link", error)
            throw new Error("Error requesting verification Link");
        }
    }

    /**
     *
     * https://stackoverflow.com/questions/37932983/firebase-customize-reset-password-landing-page
     *      
     * @param code
     */
    const checkResetPasswordCode = async (code) => {
        if (code) {
            return await firebase.auth().verifyPasswordResetCode(code);
        }
    }


    /**
     *
     * https://stackoverflow.com/questions/37932983/firebase-customize-reset-password-landing-page
     * 
     * @param password
     * @param code
     */
    const resetPassword = async (password, code) => {
        if (password && code) {
            await firebase.auth().confirmPasswordReset(code, password)
        }
    }



    /**
     *
     */
    const logout = async () => {
        console.log('deviceService',deviceService, deviceService.removeDeviceOfUser)
        await deviceService.removeDeviceOfUser(firebase.auth().currentUser)

        return await firebase
            .auth()
            .signOut()
            .then(
                () => {
                    state.error = null;
                    state.loading = false;
                    state.user = null;
                    state.userData = null;
                    state.claims = [];
                    state.permissions = [];
                    state.owner = false;
                    state.companyId = null;
                },
                (error) => {
                    state.error = error;
                    state.loading = false;
                }
            )
            .catch((error) => {
                // Handle Errors here.
                state.error = error;
                state.loading = false;
            });
    };

    // RUN AT STARTUP
    const authCheck = () => {
        return new Promise((resolve) => {
            state.loading = true;
            if (!state.initialized) {
                firebase.auth().onAuthStateChanged(async (_user) => {
                    // console.log('onAuthStateChanged', _user)
                    if (_user) {
                        state.user = _user;
                        state.userData = await getUserData();
                        // deviceService.updateDeviceForUser()
                    } else {
                        state.user = null;
                        state.userData = null;
                        state.claims = [];
                        state.companyId = null;
                    }
                    state.loading = false;
                    state.initialized = true;
                    resolve(true);
                });
            }
        });
    };

    return {
        ...toRefs(state),
        // FUNCTIONS
        login,
        logout,
        authCheck,
        register,
        updateUserData,
        updateOnlineState,
        sendForgotPasswordMail,
        resetPassword,
        sendResetPasswordLink,
        checkResetPasswordCode,
        deleteAccount,
        requestVerificationMail,
        loginWithToken
    };
}
