<template>
    <div class="tip-tap-editor">
        <div class="menu-bar" v-if="editor">

            <div class="button-group">
                <oido-button @click="editor.chain().focus().toggleBold().run()"
                    :disabled="!editor.can().chain().focus().toggleBold().run()" :primary="editor.isActive('bold')"
                    label="b" class="bold">

                </oido-button>
                <oido-button @click="editor.chain().focus().toggleItalic().run()"
                    :disabled="!editor.can().chain().focus().toggleItalic().run()" :primary="editor.isActive('italic')"
                    label="i" class="italic">
                </oido-button>

                <oido-button @click="editor.chain().focus().toggleStrike().run()"
                    :disabled="!editor.can().chain().focus().toggleStrike().run()" :primary="editor.isActive('strike')"
                    label=" s " class="strikethrough">
                </oido-button>

            </div>
            <div class="button-group">


                <oido-button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                    :primary="editor.isActive('heading', { level: 1 })" label="h1" class="h1">
                </oido-button>

                <oido-button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                    :primary="editor.isActive('heading', { level: 2 })" label="h2" class="h2">
                </oido-button>

                <oido-button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                    :primary="editor.isActive('heading', { level: 3 })" label="h3" class="h3">
                </oido-button>

            </div>
            <div class="button-group">

                <oido-button @click="editor.chain().focus().toggleBulletList().run()"
                    :primary="editor.isActive('bulletList')" label="ul" class="ul">
                </oido-button>

                <oido-button @click="editor.chain().focus().toggleOrderedList().run()"
                    :primary="editor.isActive('orderedList')" label="ol" class="ol">
                </oido-button>
            </div>

            <div class="button-group">

                <oido-button @click="setLink" :primary="editor.isActive('link')" icon="link" class="link">
                </oido-button>

                <oido-button @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')"
                    icon="unlink" class="unlink">
                </oido-button>
            </div>


        </div>
        <editor-content :editor="editor" />
    </div>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import Link from '@tiptap/extension-link'

export default {
    components: {
        EditorContent,
    },

    props: {
        modelValue: {
            type: String,
            default: '',
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            editor: null,
        }
    },
    methods: {
        setLink() {
            const previousUrl = this.editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                this.editor.chain().focus().extendMarkRange('link').unsetLink()
                    .run()

                return
            }

            // update link
            try {
                this.editor.chain().focus().extendMarkRange('link').setLink({ href: url })
                    .run()
            } catch (e) {
                alert(e.message)
            }

        }

    },

    watch: {
        modelValue(value) {
            // HTML
            const isSame = this.editor.getHTML() === value

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
    },

    mounted() {
        this.editor = new Editor({
            extensions: [
                StarterKit,
                Link.configure({
                    openOnClick: false,
                    autolink: true,
                    defaultProtocol: 'https',
                    protocols: ['http', 'https'],
                    isAllowedUri: (url, ctx) => {
                        try {
                            
                            // construct URL
                            const parsedUrl = url.includes(':') ? new URL(url) : new URL(`${ctx.defaultProtocol}://${url}`)

                            // use default validation
                            if (!ctx.defaultValidate(parsedUrl.href)) {
                                return false
                            }

                            // disallowed protocols
                            const disallowedProtocols = ['ftp', 'file', 'mailto']
                            const protocol = parsedUrl.protocol.replace(':', '')

                            if (disallowedProtocols.includes(protocol)) {
                                return false
                            }

                            // only allow protocols specified in ctx.protocols
                            const allowedProtocols = ctx.protocols.map(p => (typeof p === 'string' ? p : p.scheme))

                            if (!allowedProtocols.includes(protocol)) {
                                return false
                            }

                            // disallowed domains
                            const disallowedDomains = ['example-phishing.com', 'malicious-site.net']
                            const domain = parsedUrl.hostname

                            if (disallowedDomains.includes(domain)) {
                                return false
                            }

                            // all checks have passed
                            return true
                        } catch {
                            return false
                        }
                    },
                    shouldAutoLink: url => {
                        try {
                            // construct URL
                            const parsedUrl = url.includes(':') ? new URL(url) : new URL(`https://${url}`)

                            // only auto-link if the domain is not in the disallowed list
                            const disallowedDomains = ['example-no-autolink.com', 'another-no-autolink.com']
                            const domain = parsedUrl.hostname

                            return !disallowedDomains.includes(domain)
                        } catch {
                            return false
                        }
                    }
                })
            ],
            content: this.modelValue,
            onUpdate: () => {
                // HTML
                this.$emit('update:modelValue', this.editor.getHTML())

                // JSON
                // this.$emit('update:modelValue', this.editor.getJSON())
            },
        })
    },

    beforeUnmount() {
        this.editor.destroy()
    },
}
</script>
<style scoped>
.menu-bar {
    display: flex;
    justify-content: center;
    padding: 0.3rem;
    border-bottom: 1px solid #ddd;
    flex-wrap: wrap;
}

.menu-bar .button-group {
    display: flex;
    margin: 0 0.7rem;
}

.menu-bar .oido-button {
    font-weight: 500;
    margin: 0 0.1rem;
    padding: 0.3rem .2rem;
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1rem;
}

.menu-bar .oido-button.bold {
    font-weight: 700;
}

.menu-bar .oido-button.italic {
    font-style: italic;
}

.menu-bar .oido-button.strikethrough {
    text-decoration: line-through;
}




</style>