<template>
  <div class="translation-selector">
    <header v-if="enabledLanguages && enabledLanguages.length > 1 && (!automaticTranslationsAreEnabled || !hideTranslationTabs)">
      <ion-segment @ionChange="languageChanged($event.detail.value)" mode="md">
        <ion-segment-button v-for="language of enabledLanguages" :key="language" :value="language"
          :checked="selectedLanguage == language" :class="{active: selectedLanguage == language}">
          <ion-label v-text="language"></ion-label>
        </ion-segment-button>
      </ion-segment>
    </header>
  </div>
</template>

<script >
import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/vue";

import translationService from "./../composables/translationService";

export default {
  props: ["modelValue"],
  components: { IonSegment, IonSegmentButton, IonLabel },
  data() {
    return {
      selectedLanguage: null,
    };
  },
  watch: {
    modelValue() {
      if (this.selectedLanguage != this.modelValue) {
        this.selectedLanguage = this.modelValue;
      }
    },
  },
  async unmounted() { },
  async mounted() {
    if (!this.modelValue) {
      this.languageChanged(this.defaultLanguage);
    }
  },
  computed: {
    enabledLanguages() {
      return this.translationService.enabledLanguages ? this.translationService.enabledLanguages.value : [];
    },
    defaultLanguage() {
      return this.translationService.defaultLanguage ? this.translationService.defaultLanguage.value : 'en';
    },
    hideTranslationTabs() {
      return this.companySettings && this.companySettings.i18n && this.companySettings.i18n.hideTranslationTabs
    },
    automaticTranslationsAreEnabled() {
      return this.companySettings && this.companySettings.i18n && this.companySettings.i18n.enableAutomaticTranslation
    },
  },
  methods: {
    languageChanged(language) {
      this.selectedLanguage = language;
      this.$emit("update:modelValue", this.selectedLanguage);
    },
  },
  setup() {
    let translationServiceInstance = translationService();
    return {
      translationService: translationServiceInstance,
    };
  },
};
</script>
<style scoped>
header {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

header ion-segment {
  display: flex;
  width: auto;
  font-size: 0.7rem;
}

ion-segment-button {
  font-size: 0.6rem;
  min-width: auto;
  min-height: auto;
  padding: 0;
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
  line-height: 1;
}

ion-segment-button ion-label {
  margin: 0 5px;
}
ion-segment-button.active {
  color: var(--ion-color-primary)
}
ion-segment-button.active::part(indicator) {
  opacity: 1;

}

</style>>
