<template>
<div class="oido-modal-content">
  <slot></slot>
</div>  
</template>


<script >



export default {

  

};
</script>


<style scoped>
.oido-modal-content{
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  padding: 0.2rem;
}
.oido-modal-content.has-padding{
  padding: 2rem 3rem;
}
@media (max-width: 600px) {
  .oido-modal-content.has-padding{
    padding: 1rem 2rem;
  }
  
}
</style>


