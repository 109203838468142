

import { toRefs, reactive, watch } from "vue";

import companyServiceModule from "./companyService";
const companyService = companyServiceModule();
import config from "../../config"

const state = reactive({
    selectedLanguage: null,
    defaultLanguage: null,
    availableLanguages: [],
    enabledLanguages: [],
    initialized: false,
    initializing: false,
});




function translation2Text(translationMap) {

    if (!translationMap) {
        return null;
    }

    if (typeof translationMap == "string") {
        return translationMap;
    }

    let selectedLanguage = state.selectedLanguage ? state.selectedLanguage : null;

    if (selectedLanguage && translationMap[selectedLanguage]) {
        return translationMap[selectedLanguage];
    }

    let defaultLanguage = state.defaultLanguage ? state.defaultLanguage : null;
    if (defaultLanguage && translationMap[defaultLanguage]) {
        return translationMap[defaultLanguage];
    }

    for (let language of state.enabledLanguages) {
        if (translationMap[language]) {
            return translationMap[language];
        }
    }
    for (let language of state.availableLanguages) {
        if (translationMap[language]) {
            return translationMap[language];
        }
    }
    return null
}

function selectLanguage(language) {
    if (state.enabledLanguages.includes(language)) {
        state.selectedLanguage = language;
    } else {
        // console.log('language not available', language, state.enabledLanguages);
    }
}


function setAvailableLanguages(languages) {
    if (!languages || languages.length <= 0) {
        languages = []
    }
    state.availableLanguages = languages;
    // console.log('state.availableLanguages', state.availableLanguages)
}

function setEnabledLanguages(languages) {
    if (!languages || languages.length <= 0) {
        languages = []
    }
    state.enabledLanguages = languages;
    // console.log('state.enabledLanguages', state.enabledLanguages)
}
function setDefaultLanguage(language) {
    if (!language) {
        language = null;
    }
    state.defaultLanguage = language
    // console.log('state.defaultLanguage', state.defaultLanguage)
}

function settingsChanged() {

    let settings = companyService.settings ? companyService.settings.value : null;

    let enabledLanguages = []
    let defaultLanguage = null
    if (settings && settings.i18n) {
        if (settings.i18n.defaultLanguage) {
            defaultLanguage = settings.i18n.defaultLanguage;
        }
        if (settings.i18n.languages && settings.i18n.languages.length > 0) {
            for (let languageConf of settings.i18n.languages) {
                if (languageConf.enabled && languageConf.language) {
                    enabledLanguages.push(languageConf.language);
                }
            }
            if (!defaultLanguage || !enabledLanguages.includes(defaultLanguage)) {
                defaultLanguage = enabledLanguages[0];
            }

            if (!state.selectedLanguage || !enabledLanguages.includes(state.selectedLanguage)) {
                state.selectedLanguage = defaultLanguage;
            }
        }

    }
    setEnabledLanguages(enabledLanguages)
    setDefaultLanguage(defaultLanguage)
}



export default function () {


    state.availableLanguages = config.translations.languages.available;

    if (!state.selectedLanguage) {
        state.selectedLanguage = state.availableLanguages[0];
    }
    if (!state.defaultLanguage) {
        state.defaultLanguage = state.availableLanguages[0];
    }


    if (!state.initialized && !state.initializing) {

        state.initializing = true;


        //company is already loaded
        if (companyService.settings && companyService.settings.value && companyService.settings.value.i18n) {
            // console.log('settings are ready', companyService.settings.value, companyService.settings)
            settingsChanged(companyService.settings.value);
        }
        watch(companyService.settings, (settingsRef) => {
            if (settingsRef) {
                settingsChanged(settingsRef.value);
            }
        })





        state.initialized = true
        state.initializing = false
    }


    return {
        ...toRefs(state),
        translation2Text,
        selectLanguage,
    }


}