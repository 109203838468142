<template>
  <div class="oido-text-input"
    :class="{ 'has-errors': errors && errors.length > 0, 'readonly': readonly, 'disabled': disabled, translatable, [type]: true, 'vertical': vertical, 'has-postfix': postfix, 'has-prefix': prefix, 'has-help-text': helpText, 'has-hint': hint, [name]: true }">

    <div class="input-header" v-show="translatable && !readonly">

      <label :for="componentId">
        <span class="label-text" v-text="label"></span>
        <span class="required-marker" v-if="required"> *</span>
        <oido-help-icon class="help-text" v-if="helpText" :help-text="helpText"
          :position="helpTextPosition"></oido-help-icon>
      </label>


      <translation-selector v-if="translatable" v-show="!readonly && !disabled"
        v-model="selectedLanguage"></translation-selector>

    </div>
    <div class="input-content">

      <label :for="componentId" v-if="!translatable || readonly">
        <span class="label-text" v-text="label"></span>
        <span class="required-marker" v-if="required"> *</span>
        <oido-help-icon class="help-text" v-if="helpText" :help-text="helpText"
          :position="helpTextPosition"></oido-help-icon>
        <span class="hint" v-if="hint && !readonly" v-text="hint"></span>
      </label>

      <div class="input-section">
        <slot name="before"></slot>
        <span class="prefix" v-if="prefix" v-text="prefix" />
        <multi-lingual-text class="value" v-if="readonly && extractedModelValue"
          v-text="readOnlyPreviewText"></multi-lingual-text>
        <multi-lingual-text class="value" v-if="readonly && !extractedModelValue"
          v-html="'&nbsp;'"></multi-lingual-text>
        <input ref="textinput" v-if="!readonly && type != 'textarea' && type != 'rtf'" :value="extractedModelValue"
          @input="valueChanged" :type="htmlTypeOfInput" :placeholder="placeholder" :disabled="disabled" :name="name"
          :readonly="readonly" :id="componentId" size="1" />
        <textarea v-if="!readonly && type == 'textarea'" ref="textinput" :value="extractedModelValue"
          @input="valueChanged" :placeholder="placeholder" :readonly="readonly" :disabled="disabled" :name="name"
          :id="componentId"></textarea>

        <div class="rtf-editor" v-if="!readonly && type == 'rtf'">
          <Tiptap :modelValue="extractedModelValue" @update:modelValue="valueChanged"></Tiptap>
        </div>

        <span class="postfix" v-if="postfix" v-text="postfix" />
        <div class="after">
          <slot name="after"></slot>
        </div>
      </div>
    </div>
    <div class="validation-errors" v-if="errors">
      <span class="validation-error" v-for="error of errors" v-text="error" :key="error"></span>
    </div>

  </div>
</template>

<script setup>
import { v4 as uuidv4 } from 'uuid';
import TranslationSelector from "./TranslationSelector.vue";
import MultiLingualText from "./MultiLingualText.vue";
import Tiptap from './Tiptap.vue';
  
</script>
<script>


export default {
  data() {
    return {
      selectedLanguage: null,
      tiptap: null
    };
  },
  mounted() {
    
  },
  props: {
    modelValue: [String, Object, Number],
    errors: [Object],
    translatable: { type: Boolean, default: true },
    name: String,
    type: String,
    placeholder: String,
    helpText: String,
    helpTextPosition: String,
    label: String,
    readonly: Boolean,
    required: Boolean,
    disabled: Boolean,
    postfix: String,
    prefix: String,
    vertical: { type: Boolean, default: true },
    hint: String
  },  
  computed: {
    htmlTypeOfInput() {
      if (this.type == 'number') {
        return 'text'
      }
      return this.type ? this.type : 'text';
    },
    componentId() {
      return this.name + '-' + uuidv4()
    },
    extractedModelValue() {
      if (!this.translatable || typeof this.modelValue != "object") {
        return this.modelValue;
      }

      if (this.modelValue && this.modelValue[this.selectedLanguage]) {
        return this.modelValue[this.selectedLanguage];
      }
      return null;
    },
    readOnlyPreviewText() {
      if (this.extractedModelValue && this.type == 'password') {
        return '*'.repeat(this.extractedModelValue.length)
      }
      return this.extractedModelValue;
    },
  },

  methods: {
    valueChanged(event) {
      let newValue = event.target && typeof event.target.value != 'undefined' ? event.target.value : event;
      let value = newValue;
      if (this.translatable) {
        value = this.modelValue && typeof this.modelValue == "object" ? JSON.parse(JSON.stringify(this.modelValue)) : {};
        value[this.selectedLanguage] = newValue;
      }
      if (this.type == 'number') {
        console.log('cleaning number',value)
        value = ('' + value).replaceAll(',', '.');
        let parsed = Number.parseFloat(value)
        value =  !isNaN(parsed) ? parsed : null
      }
      this.$emit("update:modelValue", value);
    }    
  },
};
</script>
<style scoped>
.oido-text-input {
  width: 100%;
  position: relative;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.9rem;
}

.input-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.input-content .input-section {
  flex: 1;
  flex-basis: 50%;
  display: flex;

}

.oido-text-input.textarea .input-content .input-section {
  flex-basis: 100%;
  white-space: pre-wrap;

}

.input-content .ml-text {
  text-align: left;
  font-weight: 590;
  width: auto;
  flex: 0;
}

label {
  font-weight: 590;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #222;
}

.readonly label {
  font-weight: 400;
}

label .oido-help-icon {
  white-space: initial
}

.has-help-text .oido-help-icon {
  margin-right: 1rem;
}

.oido-text-input:not(.readonly) .prefix {
  background-color: #E6E7E9 !important;
  flex: 1;
  padding: 0.3rem 0.45rem;
  display: flex;
  align-items: center;
}

.oido-text-input input,
.oido-text-input textarea {
  background-color: #F9FAFB !important;
  border: none !important;
  resize: vertical;
  border-bottom: 1px solid var(--light-color) !important;
}

.input-content .input-section input {
  border: 1px solid #e6e6e6;
  padding: 0.3rem 0.45rem;
  text-align: left;
  flex: 1;
  min-height: 2.51rem;
  position: relative;
  border: none;
  color: #343;
}

.has-postfix .input-content .input-section input {
  padding-right: 0.2rem;
}

.input-content .input-section textarea {
  width: 100%;
  border: 1px solid #e6e6e6;
  padding: 0.6rem 0.5rem;
  line-height: 1.4;
  text-align: left;
  min-height: 5rem;
}

.oido-text-input.has-postfix:not(.textarea) .input-content .input-section input {
  border-right: none;
}

.oido-text-input:not(.readonly) .input-content .input-section .postfix {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-right: 0.7rem;
  padding-left: 0.3rem;
  background-color: #F6F7F9 !important;
  -webkit-user-select: none;
  /* Safari */
  user-select: none;
}

.oido-text-input.disabled .input-content .input-section input,
.oido-text-input.disabled .input-content .input-section textarea,
.oido-text-input.disabled:not(.readonly) .input-content .input-section .postfix {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: #444;
}

.oido-text-input.disabled .input-content .input-section .postfix,
.oido-text-input.disabled label,
.oido-text-input.disabled .input-content .ml-text {
  color: #888;
}


.oido-text-input.readonly .input-content .input-section {
  display: block;
  text-align: left;
}

.oido-text-input.readonly .input-content .input-section * {
  display: inline-block;
  vertical-align: middle;
}


.oido-text-input.readonly .prefix {
  color: #666;
  font-weight: 400;

}

.vertical .input-content {
  flex-direction: column;
  align-items: flex-start;
}

.vertical .input-content .input-section {
  flex-basis: 100%;
  width: 100%;
  text-align: left !important;
  margin-top: 0.2rem;
}

.vertical:not(.textarea) input,
.vertical .input-content .ml-text {
  text-align: left;
}


@media (max-width: 500px) {

  .input-content .input-section {
    width: 100%;
    flex-basis: 100%;
    text-align: left !important;
  }

  .input-content .input-section input {
    text-align: left !important;
  }
}

.translation-selector {
  flex: 1
}

.input-header {
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.help-text {
  margin-left: 0.1rem;
}

.has-hint label {
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;
}

.has-hint .hint {
  margin-right: 0.6rem;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.7rem;
}
</style>
<style>
.oido-text-input .after .oido-icon {
  margin-left: 0.4rem;
  position: relative;
  top: 0.13rem;
}
</style>


<style>

.oido-text-input .rtf-editor {
  background-color: var(--sidebar-background-color);
  border-radius: 0.3rem;
  border: 1px solid #f0f0f0;
  width: 100%;
}

.oido-text-input .tiptap{
  display: block;
  height: auto;
  min-height: 8rem;
  
  width: 100%;
  padding: 0.5rem 0.5rem;
}

</style>

<style>
.tiptap h1 {
  font-family: var(--heading-font-family);
  font-size: 1.9rem;
  font-weight: 700;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 500px) {
  .tiptap h1 {
    font-size: 1.6rem;
  }
}

.tiptap h2 {
  font-family: var(--heading-font-family);
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 0.3rem;
}

@media (max-width: 500px) {
  .tiptap h2 {
    font-size: 1.3rem;
  }
}


.tiptap h3 {
  font-family: var(--heading-font-family);
  font-size: 1.1rem;
  padding-bottom: 0.3rem;
  font-weight: 600;
}

@media (max-width: 500px) {
  .tiptap h3 {
    font-size: 1rem;
  }
}




.tiptap p,
.tiptap ul,
.tiptap ol,
.tiptap dl,
.tiptap blockquote {
  margin: 0 0 1.5em 0;
}

.tiptap ol,
.tiptap ul {
  padding-left: 1.3em;
  padding-right: 1.5em;
}

.tiptap ol ol,
.tiptap ul ul,
.tiptap ul ol,
.tiptap ol ul {
  margin: 0.5em 0 1em;
}

.tiptap ul {
  list-style: disc;
}

.tiptap ol {
  list-style: decimal;
}

.tiptap ul,
.tiptap ol {
  max-width: 100%;
}

.tiptap li {
  margin: 0.5em 0;
  padding-left: 0.3em;
  line-height: 1.6em;
}

.tiptap dt {
  float: left;
  margin: 0 20px 0 0;
  width: 120px;
  color: #666;
  font-weight: 500;
  text-align: right;
}

.tiptap dd {
  margin: 0 0 5px 0;
  text-align: left;
}

.tiptap blockquote {
  margin: 1.5em 0;
  padding: 0 1em;
  border-left: var(--ion-color-medium) 0.15em solid;
  border-left: 0.15em solid var(--ion-color-primary);
}

.tiptap blockquote p {
  margin: 0.8em 0;
  font-size: 1.2em;
  font-weight: 300;
}

.tiptap blockquote small {
  display: inline-block;
  margin: 0.8em 0 0.8em 1.5em;
  font-size: 0.9em;
  opacity: 0.8;
}

/* Quotation marks */
.tiptap blockquote small:before {
  content: "\2014 \00A0";
}

.tiptap blockquote cite {
  font-weight: bold;
}

.tiptap blockquote cite a {
  font-weight: normal;
}

.tiptap a {
  color: var(--ion-color-primary);
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.tiptap a:hover {
  text-decoration: underline;
}

.tiptap b,
.tiptap strong {
  font-weight: 600;
}

.tiptap em {
  font-style: italic;
}

.tiptap pre {
  background-color: #444;
  padding: 1rem;
  border-radius: 0.4rem;
  color: #fff;
  font-family: monospace;
  font-weight: bold;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  white-space: pre-wrap;
}
</style>