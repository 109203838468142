<template>
<h1 class="oido-modal-title" :class="{ 'has-help-text': helpText }">
  <slot></slot>
  <oido-help-icon class="help-text" v-if="helpText" :help-text="helpText" :position="helpTextPosition"></oido-help-icon>
</h1>  
</template>


<script >



export default {
  props: {
    helpText: String,    
    helpTextPosition: String,    
  },
};
</script>


<style scoped>
.oido-modal-title{
  text-align: center;
  font-weight: 600;
  font-size: 1.3rem;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0rem 1rem 0.3rem;
  width: 100%;
  margin-top: -1rem;
}
.oido-modal-title .oido-help-icon{
  margin-left: 0.2rem;
  
}

</style>
<style >
.oido-modal-title .oido-help-icon .help-text{  
  bottom: initial !important;
  left: initial !important;
  top: 4rem;
  margin-left: -12rem;
  
  
}
</style>


