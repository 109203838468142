<template>
  <ion-app>
    <ion-split-pane content-id="main" mode="md" when="(min-width: 1200px)">
      <ion-menu side="start" ref="menu" content-id="main" v-show="loggedInUser">
        <ion-content>
          <navigation-view></navigation-view>
        </ion-content>
      </ion-menu>
      <ion-content id="main">
        <ion-router-outlet :animated="false"></ion-router-outlet>
      </ion-content>
    </ion-split-pane>
  </ion-app>
  <ModalsContainer />
</template>

<script setup>
import {
  IonApp,
  IonRouterOutlet,
  IonMenu,
  IonSplitPane,
  IonContent

} from "@ionic/vue";
import { defineComponent } from "vue";
import NavigationView from "./views/NavigationView.vue"
import { App as CapacitorApp } from '@capacitor/app';
import { AppLauncher } from '@capacitor/app-launcher';
import { KeepAwake } from '@capacitor-community/keep-awake';
import {version} from '../package.json';

import axios from "axios";
import { toastController } from "@ionic/vue";

import 'vue-final-modal/style.css'
import { ModalsContainer } from 'vue-final-modal'

</script>
<script>


function isNewerVersion(oldVer, newVer) {
  if (!oldVer || !newVer) {
    return false
  }
  const oldParts = oldVer.split('.')
  const newParts = newVer.split('.')
  for (var i = 0; i < newParts.length; i++) {
    const a = ~~newParts[i] // parse int
    const b = ~~oldParts[i] // parse int
    if (a > b) return true
    if (a < b) return false
  }
  return false
}

var versionCheckInterval = null;
export default defineComponent({
  data() {
    return {
      appInfo: null
    }
  },
  mounted() {
    this.checkAppVersion()
    const versionChekIntervalInHours = 1
    if (!versionCheckInterval) {
      versionCheckInterval = setInterval(this.checkWebsiteVersion, versionChekIntervalInHours * 60*60*1000);
    }
    this.checkWebsiteVersion()
    this.keepAwake()
  },
  unmounted() {
    if (versionCheckInterval) {
      clearInterval(versionCheckInterval);
    }
  },
  computed: {
    selectedLanguage() {
      return this.services.translations.selectedLanguage ? this.services.translations.selectedLanguage.value : null;
    },
    enabledLanguages() {
      return this.services.translations.enabledLanguages ? this.services.translations.enabledLanguages.value : [];
    },
    hideTranslationTabs() {
      return this.companySettings && this.companySettings.i18n && this.companySettings.i18n.hideTranslationTabs
    },
    automaticTranslationsAreEnabled() {
      return this.companySettings && this.companySettings.i18n && this.companySettings.i18n.enableAutomaticTranslation
    },
  },
  methods: {
    hideMenu() {
      this.$refs.menu.$el.close()
    },
    async keepAwake() {
      if (!this.isApp) {
        return;
      }
      await KeepAwake.keepAwake();
    },
    async checkWebsiteVersion() {
      if (this.isApp) {
        return;
      }
      const getWebsiteVersion = async ()  => {
        let info = null
        try {
          let response = await axios.get(this.config.apiUrl + "/version.json");                    
          info = response.data
        } catch (error) {
          console.log('checkAppVersion: problems getting system status!', error);
          // info = JSON.parse('{"version":"1.4.30"}')
          return;
        }
        let version = info.version ? info.version : null;
        return version
      }

      let availableVersion = await getWebsiteVersion()
      let aNewerVersionIsAvailable = isNewerVersion(version,availableVersion);


      if (aNewerVersionIsAvailable) {
        const toast = await toastController.create({
          message: this.$t('new-website-version-available-toast-label'),
          position: 'top',
          color: 'primary',
          cssClass: "new-app-version-toast",
          buttons: [{
            text: this.$t('new-app-version-available-toast-update-button-label'),
            role: 'info',
            handler: async () => {              
              toast.dismiss()
              window.location.reload()
            },
          },]
        });

        await toast.present();
      }
      

    },
    async checkAppVersion() {
      if (!this.isApp) {
        return;
      }
      this.appInfo = await CapacitorApp.getInfo();

      let systemAppInfo = null
      try {
        let response = await axios.get(this.config.apiUrl + "/api/system/apps/");
        systemAppInfo = response.data.ios ? response.data.ios : null;
      } catch (error) {
        console.log('checkAppVersion: problems getting system status!', error);
        return;
      }

      let version = systemAppInfo.version ? systemAppInfo.version : null;

      if (!systemAppInfo || !systemAppInfo.version || !systemAppInfo.appstoreLink) {
        console.log('no system status available!', systemAppInfo);
      }
      let aNewerVersionIsAvailable = isNewerVersion(this.appInfo.version, version);
      if (aNewerVersionIsAvailable) {
        const toast = await toastController.create({
          message: this.$t('new-app-version-available-toast-label'),
          position: 'top',
          color: 'primary',
          cssClass: "new-app-version-toast",
          buttons: [{
            text: this.$t('new-app-version-available-toast-update-button-label'),
            role: 'info',
            handler: async () => {
              await AppLauncher.openUrl({ url: systemAppInfo.appstoreLink });
              toast.dismiss()
            },
          },]
        });
        await toast.present();
      }


    }
  }
});
</script>
<style>
@import "vue-select/dist/vue-select.css";

body {
  background-color: white !important;
  color: var(--ion-color-dark);
}


html:not(.plt-desktop) ion-split-pane {
  margin-top: env(safe-area-inset-top);
}

html:not(.plt-desktop) ion-modal {
  margin-top: env(safe-area-inset-top);
}


.validation-errors.global {
  padding-left: 16px;
}

.validation-error {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
  margin-bottom: -0.7rem;
}

ion-button ion-spinner {
  height: 1.2em;
}

.no-data-notice {
  text-align: center;
  padding: 1rem;
  color: var(--ion-color-medium);
  font-style: italic;
}

ion-toolbar ion-buttons {
  min-height: 56px;
}

ion-toolbar ion-buttons .toolbar-side,
ion-toolbar ion-buttons ion-button {
  margin-left: .5rem !important;
}

@media (max-width: 600px) {
  ion-toolbar ion-title {
    font-size: 1.1rem !important;
  }

  ion-toolbar ion-buttons .toolbar-side,
  ion-toolbar ion-buttons ion-button {
    font-size: 0.8rem !important;
  }
}

/*
add some margin to the bottom to avoid an overlap with focused yellow mac elements
*/
ion-label.label-stacked {
  margin-bottom: 0.3rem !important;
}

ion-menu:not(.show-menu) {
  max-width: 270px;
}

ion-item::part(native) {
  background: transparent;
  ;
}

ion-header ion-toolbar ion-title {
  padding-left: 0.5rem;
  font-weight: 590;
  color: var(--ion-color-dark)
}


ion-menu.menu-type-overlay.menu-pane-visible {
  border-right: none;
}

ion-split-pane>ion-content::after {
  content: "";
  position: absolute;
  width: 10px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.03) 20%, rgba(0, 0, 0, 0.01) 50%, rgba(0, 0, 0, 0) 90%);
  height: 100%;
  left: 0px;
  z-index: 2000000;
}

.ion-page>ion-header ion-toolbar {
  padding: 1rem 1rem 1rem 0.2rem;
}

.ion-page>ion-header ion-toolbar ion-button ion-label {
  font-size: 0.8rem !important;
  text-transform: none;

}

.ion-page>ion-header ion-toolbar ion-button::part(native) {
  border-width: 1px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

}

.ion-page>ion-header ion-toolbar ion-icon[slot="start"] {
  margin-left: -0.4rem;

}

.ion-page>ion-header:first-child::after {
  background-image: none;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.05) 20%, rgba(0, 0, 0, 0.01) 50%, rgba(0, 0, 0, 0) 90%);
}

ion-card {
  padding: 1.3rem 2.5rem;
  border-radius: 8px !important;
  box-shadow: 0 0px 25px 5px rgba(0, 0, 0, 0.05);
}

ion-content>ion-card {
  margin: 1rem 2.5rem !important;
}

@media (max-width: 500px) {
  ion-card {
    padding: 1rem 1rem;
  }

  ion-content>ion-card {
    margin: 0.5rem 1.4rem !important;
  }

}

ion-card .card-header::part(native) {
  margin-bottom: 1rem;
}

ion-card .card-header ion-label {
  font-weight: 500 !important;
  font-size: 0.9rem !important;

}

ion-card .card-header ion-button {
  margin-right: -1rem;

}

.list-item::part(native) {
  border-color: var(--ion-color-light);
}

ion-reorder-group .list-item:last-child::part(native) {
  border: none !important;
}

.sortable-view .list-item .label {
  color: var(--ion-color-medium) !important;
  font-size: 0.8rem !important;
  font-weight: 400;
}

.sortable-view .list-item ion-icon {
  color: var(--ion-color-medilight) !important;
}

.sortable-view .list-item .drag-trigger {
  cursor: move;
  cursor: grab;
  font-size: 1.5rem !important;
  color: var(--ion-color-medilight) !important;
  opacity: 1 !important;
  margin-right: 0.5rem;
}

.card-footer ion-button {
  color: var(--ion-color-primary) !important;
  font-size: 0.7rem;
}

.currency-symbol {
  padding-left: .1rem;
}

.warning-sign {
  padding: 2rem;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: 590;
  background: #db22418a;
  background: var(--ion-color-primary);
  position: relative;
  text-align: center;
  z-index: 200;
}

.warning-sign a {
  z-index: 200;
  position: relative;
  color: white
}

ion-toast {
  margin-top: env(safe-area-inset-top);
}

ion-modal .ion-page>ion-header ion-title {
  font-size: 1.1rem;
}


ion-modal .ion-page>ion-header ion-toolbar {
  --min-height: 56px;
  min-height: 1rem !important;
  height: auto;
  padding: 0 1rem;

}
</style>


<style>
.authsystem.oido-page {
  padding: 1rem 10vw 1rem;
}

.authsystem .page-header {
  text-align: center;
  margin-top: 3vw;
  margin-bottom: 1.5rem;
}

.authsystem .page-header h1 {
  font-size: 2rem;
  font-weight: 580;
  color: #000;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.authsystem .page-header h2 {
  font-size: 1.1rem;
  font-weight: 580;
  margin-top: 0rem;
  margin-bottom: 2.5rem;
  color: var(--ion-color-primary);
}

.authsystem .page-header .logo-image {
  width: 7vw;
  height: 7vw;
  min-width: 70px;
  min-height: 70px;
}


@media (max-width: 700px) {

  .authsystem .page-header h1 {
    font-size: 1.4rem;
    margin-top: 1.5rem;
    margin-bottom: 0.7rem;
  }

  .authsystem .page-header h2 {
    font-size: 0.8rem;

  }
}

.authsystem .oido-card-content .oido-grid {
  margin: 0;
  padding: 1rem 1.5rem 1rem;
}

.authsystem .oido-card-content .oido-grid .oido-grid-column {
  margin: 0;
  padding: 0;
  padding-right: 7.5vw;

}

.authsystem .oido-card-content .oido-grid .oido-grid-column:last-of-type {
  padding-right: 0;
}


.authsystem .oido-section-heading {
  font-size: 1.5rem !important;
  font-weight: 590 !important;
  margin-top: 0rem !important;
  margin-bottom: 1.5rem !important;
  letter-spacing: -0.03rem !important;
  color: var(--ion-color-primary) !important;
  padding-top: 1rem;
}

@media (max-width: 900px) {
  .authsystem.oido-page {
    padding: 1rem 2rem 1rem;
  }

  .authsystem .page-header {
    margin-bottom: 0rem;
  }

  .authsystem .oido-card-content .oido-grid .oido-grid-column {
    padding-right: 0rem;
    /* padding-bottom: 6rem; */
  }

  .authsystem .oido-card-content .oido-grid .oido-grid-column:last-child {
    padding-bottom: 0
  }


  .authsystem .oido-card-content .oido-grid {

    padding: 1rem;

  }

  .authsystem .oido-card-content .oido-grid-row {
    margin: 0;
  }

  .authsystem .progress-column {
    display: none !important;
  }

  .authsystem .oido-section-heading {
    font-size: 1.3rem !important;
    font-weight: 590 !important;
    margin-top: 0rem !important;
    margin-bottom: 1rem !important;
    letter-spacing: -0.03rem !important;
    color: var(--ion-color-primary) !important;
    padding-top: 1rem;
  }

}

@media (max-width: 500px) {
  .authsystem.oido-page {
    padding: 1rem 1rem 1rem;
  }

  .authsystem.oido-page .oido-card {
    margin: 0;
  }

  .authsystem.oido-page .oido-card-content {
    padding: 1rem 0.5rem;
  }
}



.authsystem .to-login-link {
  font-weight: 590;
  text-decoration: none;
  display: block;
  text-align: center;
  padding-top: 0.6rem;
}


.authsystem .request-verification-mail-button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  white-space: break-spaces;
  height: auto;
  line-height: 2rem;
}


.authsystem .further-actions {
  text-align: center;
}

.authsystem .loading-spinner-section {
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.authsystem .loading-spinner-section ion-spinner {
  color: #444;
}

.authsystem .loading-spinner-section ion-text {
  line-height: 1.4;
  max-width: 400px;
  margin: 0 auto;
  display: block;
  color: #444;
  font-size: 1.15rem;
}

.authsystem .login-button {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 590;
  letter-spacing: -0.01rem;
}

.authsystem .oido-text-input label {
  font-weight: 590;
  color: #222
}

.authsystem .oido-text-input .hint {
  font-weight: 300;

}


.authsystem .validation-errors {
  margin-bottom: 1rem;
}

.authsystem .further-actions {
  margin-top: 1rem;
}

.authsystem .further-actions span.link,
.authsystem .further-actions a {
  font-weight: 500;
  display: inline-block;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  text-decoration: none;
  /* color: #222;
  text-decoration: underline; */
  margin-top: 0.9rem;
  margin-bottom: 0.1rem;
}

.authsystem .further-actions a em {
  font-style: normal;
  color: var(--ion-color-primary);

}

.authsystem .oido-checkbox label {
  font-weight: 500;
  color: #777;
}

.authsystem .oido-checkbox label a {
  text-decoration: none;
}



.printnode {
  display: none;
}


@media print {
  @page {
    size: A4;
    margin: 0;
  }

  html,
  body {
    width: 210mm !important;
  }

  /* ... the rest of the rules ... */
}


@media print {


  .printnode {
    display: block;
    position: relative;

  }

  #app {
    display: none;
  }

  .oido-card,
  ion-card {
    box-shadow: none !important;
  }

}
</style>

<style>
ion-header {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2) !important;
}


.popover {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.popover .popover-content {
  padding: 0.6rem 1rem;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
}

.popover .popover-footer {
  padding: 0.6rem 1rem;
  text-align: center;
}

ion-toolbar ion-button.button-has-icon-only::part(native) {
  padding: 0.5rem !important;

}

ion-toolbar ion-button.button-has-icon-only ion-icon:not(.filter-engaged-alert-icon) {

  width: 2rem;
  height: 2rem;

}


.teleport {}

.teleport .settings-group-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
}

.teleport .oido-card-content{
  overflow: hidden;
  overflow-y: auto;
  height: auto;
}
.teleport .settings-group-modal .backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 510;
  background-color: rgba(0, 0, 0, 0.6);
}

.teleport .settings-group-modal .oido-card {
  position: absolute;
  width: 80%;
  left: 10%;
  top: 8%;
  bottom: 10%;
  z-index: 520;
  margin: 0;
  background-color: #fff;
  overflow: hidden;
display: flex;
flex-direction: column;
}
</style>