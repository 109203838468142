import { toRefs, reactive, watch } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();
import axios from "axios";
import config from "../../config"

import fileServiceModule from "./fileService";
const fileService = fileServiceModule();

const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.products = [];
  
}
const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    products: []
});


async function loadProducts() {

    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }


    
    let productsUnsubscribeHandle = firebase
        .firestore()
        .collection("products")
        .where("companyId", "==", companyId)
        .orderBy("sortOrder", "asc")
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;
                if (type === "added") {
                    state.products.splice(newIndex, 0, data);
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.products.splice(oldIndex, 1);
                    state.products.splice(newIndex, 0, data);

                } else if (type === "removed") {
                    state.products.splice(oldIndex, 1);
                }

            });
        })
    unsubscribeHandles.push(productsUnsubscribeHandle);
}


async function saveProduct(product) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    if(!product.sortOrder){
        let sortOrder = 1000;
        if (state.products && state.products.length > 0) {
            for (let product of state.products) {
                if (product.sortOrder >= sortOrder) {
                    sortOrder = product.sortOrder + 500;
                }
            }
        }
    
        product.sortOrder = sortOrder;
    
    }
    
    product.companyId = companyId
    product.tags = product.tags || null
    product.slots = product.slots || null
    product.description = product.description || null


    //only upload picuture if ist really a blob
    if (product.picture && typeof product.picture == 'object') {
        product.picture = await fileService.uploadFile(product.picture);
    }
    
    let token = await firebase.auth().currentUser.getIdToken();    
    let response = await axios.post(config.apiUrl + "/api/products/" + (product.id ? product.id : ''), product, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });
    if (!response || !response.data) {
        throw new Error("incomplete return value");
    }

}


async function updateProductSortOrder(productId,categoryId, sortOrder) {
    
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    if (!productId) {
        console.log('Error@updateProductSortOrder: no id')
    }

    let data = {
        sortOrder: Number.parseFloat(sortOrder),
        categoryId: categoryId,
        companyId: companyId
    }

    await firebase
        .firestore()
        .collection("products")
        .doc(productId)
        .set(data, { merge: true })
}

async function updateProductVisibility(productId, hidden) {
    
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    if (!productId) {
        console.log('Error@updateProductVisibility: no id')
    }

    let data = {
        hidden: Boolean(hidden),
        companyId: companyId
    }

    await firebase
        .firestore()
        .collection("products")
        .doc(productId)
        .set(data, { merge: true })
}


async function deleteProduct(productId) {
    if (!auth.user) {
        teardown();
        return;
    }
    if (!productId) {
        console.log('Error@deleteProduct: no productId', productId)
    }

    await firebase
        .firestore()
        .collection("products")
        .doc(productId)
        .delete()
}

export default function () {

    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        watch(auth.companyId, async (companyId) => {
            if (companyId) {
                loadProducts();
            }else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadProducts();
        }
        state.initialized = true
        state.initializing = false
    }

    function getProductById(productId){    
        if(!state.products){    
            return null;
        }
        for(let product of state.products){
            if(product.id == productId){
                return product;
            }
        }
        return null;
    }



    return {
        ...toRefs(state),
        loadProducts,
        teardown,
        deleteProduct,        
        getProductById,
        saveProduct,
        updateProductVisibility,
        updateProductSortOrder
    }
}