<template>  
    <div class="abo-feature-badge" @click="redirectTo" v-if="shouldBeShown">{{ labelToDisplay }}</div>

</template>

<script setup>

</script>
<script>
export default {
  data() {
    return {
    };
  },
  props: {
    label: String,
    allowedSubscriptionPlans: Array
  },
  computed:{
    labelToDisplay(){
      if(this.label){
        return this.label
      }
      function capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
      }
      let label = this.$t("abo-feature-badge-label")
      if(this.allowedSubscriptionPlans && this.allowedSubscriptionPlans.length > 0){
        label = label + ': ' + this.allowedSubscriptionPlans.map((a) => capitalizeFirstLetter(a) ).join(', ')
      }
      return label
    },
    shouldBeShown(){
      return !this.hasAnActiveSubscription || (this.allowedSubscriptionPlans && this.subscribedPlan && !this.allowedSubscriptionPlans.includes(this.subscribedPlan))
    }
  },
  methods:{
    redirectTo(){
      this.$router.push({ name: "profile" });
    }
  }
};
</script>


<style scoped>
.abo-feature-badge {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  color: white;
  background-color: #db2240;
  font-weight: 500;
  border-radius: 2rem;
  z-index: 200;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}


</style>