<template>
<div class="oido-card">
  <slot></slot>
</div>  
</template>


<script >



export default {

  

};
</script>


<style scoped>
.oido-card{
  margin: 1rem 1rem;
  border-radius: 8px !important;
  box-shadow: 0 0px 15px 3px rgba(0, 0, 0, 0.1);
  flex:1;
  position: relative;

}

.oido-grid-column > .oido-card:not(:last-child) {
  flex: 0;
}


</style>
