import { toRefs, reactive, watch, ref } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();
import axios from "axios";
import config from "../../config"
import dayjs from "dayjs";
import companyServiceModule from "./companyService";
const companyService = companyServiceModule();


const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.orders = [];

}
const state = reactive({
    initialized: false,
    initializing: false,
    sortOrder: 'desc',
    sortBy: 'status',
    startDate: null,
    endDate: null,
    error: null,
    orders: []
});


async function loadOrders() {

    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }


    let query = firebase
        .firestore()
        .collection("orders")
        .where("companyId", "==", companyId)
    if (state.startDate) {
        query = query.where("dateOrdered", ">=", dayjs(state.startDate).toDate())
    }
    if (state.endDate) {
        query = query.where("dateOrdered", "<=", dayjs(state.endDate).toDate())
    }
    query = query.orderBy(state.sortBy, state.sortOrder)


    if (state.sortBy != "dateOrdered") {
        query = query.orderBy("dateOrdered", "desc")
    }


    // .limit(100)
    // .startAfter(state.lastVisibleDoc)
    let ordersUnsubscribeHandle = query.onSnapshot((docRef) => {
        docRef.docChanges().forEach((change) => {
            const { newIndex, oldIndex, doc, type } = change;
            let data = doc.data();
            data.id = doc.id;
            // console.log('change', data, type)
            if (type === "added") {
                //  console.log('added', data, type)
                state.orders.splice(newIndex, 0, data);
                // if we want to handle references we would do it here
            } else if (type === "modified") {
                // remove the old one first
                state.orders.splice(oldIndex, 1);
                state.orders.splice(newIndex, 0, data);

            } else if (type === "removed") {
                state.orders.splice(oldIndex, 1);
            }

        });
    })
    unsubscribeHandles.push(ordersUnsubscribeHandle);
}


async function updateOrder(order) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    if (!order.id) {
        console.error('Error@updateOrder: no id', order)
    }

    order.companyId = companyId;

    await firebase
        .firestore()
        .collection("orders")
        .doc(order.id)
        .set(order, { merge: true })

}



async function export2CSV(options) {



    let token = await firebase.auth().currentUser.getIdToken();
    let data = {
        from: options.from ? options.from : null,
        to: options.to ? options.to : null,
    }


    let response = await axios.post(config.apiUrl + "/api/orders/export", data, {
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    return response.data;
}

function clearFilters() {
    state.startDate = state.endDate = null;
}

export default function () {


    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        
        watch(companyService.settings,(settings) => {
        
            if(settings && settings && settings.timezone){
                let timezone = settings.timezone
                state.startDate = dayjs().tz(timezone).startOf('week').toISOString()
                state.endDate = dayjs().tz(timezone).endOf('week').toISOString()
            }

        })
        
        
        
        watch(auth.companyId, async (companyId) => {
            if (companyId) {
                loadOrders();
            }else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadOrders();
        }

        //if a date changes reload orders
        watch(
            () => state.endDate + state.startDate,
            () => {
                loadOrders();
            })
        state.initialized = true
        state.initializing = false
    }


    return {
        ...toRefs(state),
        loadOrders,
        teardown,
        updateOrder,
        export2CSV,
        clearFilters
    }


}