import { toRefs, reactive, watch } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();


const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.locations = [];
    state.error= null;
   
}

const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    locations: []
});


async function loadLocations() {    
    
    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }    

    let locationsUnsubscribeHandle = firebase
        .firestore()
        .collection("locations")
        .where("companyId", "==", companyId)
        .orderBy("sortOrder", "asc")
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;                
                if (type === "added") {
                    state.locations.splice(newIndex, 0, data);
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.locations.splice(oldIndex, 1);
                    state.locations.splice(newIndex, 0, data);

                } else if (type === "removed") {
                    state.locations.splice(oldIndex, 1);
                }

            });
        })
    unsubscribeHandles.push(locationsUnsubscribeHandle);
}


async function addLocation() {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    let sortOrder = 1000;
    if (state.locations && state.locations.length > 0) {
        for (let location of state.locations) {
            if (location.sortOrder >= sortOrder) {
                sortOrder = location.sortOrder + 500;
            }
        }
    }

    let location = {
        name: {},
        companyId: companyId,
        sortOrder: sortOrder,
    }

    await firebase
        .firestore()
        .collection("locations")
        .add(location)
}

async function reorderLocation(from, to) {
    if (!auth.user) {
        teardown();
        return;
    }
    
    let move = function (target, from, to) {
        target.splice(to, 0, target.splice(from, 1)[0]);
    };

    let affectedLocation = state.locations[from];

    let copiedCats = JSON.parse(JSON.stringify(state.locations))
    move(copiedCats, from, to);
    let sortOrder = 1000;

    let previousSortOrder = to > 0 ?  copiedCats[to-1].sortOrder : null;
    let nextSortOrder = to < copiedCats.length - 1 ?  copiedCats[to + 1].sortOrder : null;

    // console.log('previousSortOrder',previousSortOrder);
    // console.log('nextSortOrder', nextSortOrder);
    
    if(!previousSortOrder && nextSortOrder){
        sortOrder = nextSortOrder - 500;
    }
    if(previousSortOrder && !nextSortOrder){
        sortOrder = previousSortOrder + 500;
    }
    if(previousSortOrder && nextSortOrder){
        sortOrder = previousSortOrder + (nextSortOrder - previousSortOrder) / 2;
    }
    // console.log('sortOrder', sortOrder);

    await firebase
        .firestore()
        .collection("locations")
        .doc(affectedLocation.id)
        .set({sortOrder: sortOrder},{merge:true})


}
async function updateLocation(location) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }
    if (!location.id) {
        console.log('Error@updateLocation: no cat id', location)
    }

    // console.log('Info@updateLocation: cat id', JSON.stringify(location))
    location.companyId = companyId;

    await firebase
        .firestore()
        .collection("locations")
        .doc(location.id)
        .set(location, { merge: true })
}
async function deleteLocation(locationId) {
    if (!auth.user) {
        teardown();
        return;
    }
    if (!locationId) {
        console.log('Error@deleteLocation: no location id', locationId)
    }
    await firebase
    .firestore()
    .collection("locations")
    .doc(locationId)
    .delete()

}

function getLocationById(locationId) {
    if (!state.locations) {
        return null;
    }
    for (let location of state.locations) {
        if (location.id == locationId) {
            return location;
        }
    }
    return null;
}


export default function () {

    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        watch(auth.companyId, async (companyId) => {
            if (companyId) {
                loadLocations()
            }else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadLocations()
        }
        state.initialized = true
        state.initializing = false
    }





    return {
        ...toRefs(state),
        loadLocations,
        reorderLocation,
        teardown,
        updateLocation,
        deleteLocation,
        addLocation,
        getLocationById

    }


}