<template>
<div class="oido-page">
  <div class="oido-page-scroll-content">
  <slot></slot>
  </div>  
</div>  
</template>


<script >



export default {

  

};
</script>


<style scoped>
.oido-page{
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}
.oido-page .oido-page-scroll-content{
  position: relative;
}
</style>


