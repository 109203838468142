import { toRefs, reactive, watch } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();


const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.courses = [];
    state.error = null;

}

const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    courses: []
});


async function loadCourses() {

    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }
    

    let coursesUnsubscribeHandle = firebase
        .firestore()
        .collection("courses")
        .where("companyId", "==", companyId)
        .orderBy("sortOrder", "asc")
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;
                if (type === "added") {
                    state.courses.splice(newIndex, 0, data);
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.courses.splice(oldIndex, 1);
                    state.courses.splice(newIndex, 0, data);

                } else if (type === "removed") {
                    state.courses.splice(oldIndex, 1);
                }

            });
        })
    unsubscribeHandles.push(coursesUnsubscribeHandle);
}


async function addCourse() {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }


    let sortOrder = 1000;
    if (state.courses && state.courses.length > 0) {
        for (let course of state.courses) {
            if (course.sortOrder >= sortOrder) {
                sortOrder = course.sortOrder + 500;
            }
        }
    }

    let course = {
        name: {},
        companyId: companyId,
        sortOrder: sortOrder,
    }

    await firebase
        .firestore()
        .collection("courses")
        .add(course)
}

async function reorderCourse(from, to) {
    if (!auth.user) {
        teardown();
        return;
    }

    let move = function (target, from, to) {
        target.splice(to, 0, target.splice(from, 1)[0]);
    };

    let affectedCourse = state.courses[from];

    let copiedCats = JSON.parse(JSON.stringify(state.courses))
    move(copiedCats, from, to);
    let sortOrder = 1000;

    let previousSortOrder = to > 0 ? copiedCats[to - 1].sortOrder : null;
    let nextSortOrder = to < copiedCats.length - 1 ? copiedCats[to + 1].sortOrder : null;

    // console.log('previousSortOrder',previousSortOrder);
    // console.log('nextSortOrder', nextSortOrder);

    if (!previousSortOrder && nextSortOrder) {
        sortOrder = nextSortOrder - 500;
    }
    if (previousSortOrder && !nextSortOrder) {
        sortOrder = previousSortOrder + 500;
    }
    if (previousSortOrder && nextSortOrder) {
        sortOrder = previousSortOrder + (nextSortOrder - previousSortOrder) / 2;
    }
    // console.log('sortOrder', sortOrder);

    await firebase
        .firestore()
        .collection("courses")
        .doc(affectedCourse.id)
        .set({ sortOrder: sortOrder }, { merge: true })
}

async function updateCourse(course) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }


    if (!course.id) {
        console.log('Error@updateCourse: no cat id', course)
    }

    if (!course.name) {
        course.name = {}
    }

    // console.log('Info@updateCourse: cat id', JSON.stringify(course))
    course.companyId = companyId;

    await firebase
        .firestore()
        .collection("courses")
        .doc(course.id)
        .set(course, { merge: true })
}
async function deleteCourse(courseId) {
    if (!auth.user) {
        teardown();
        return;
    }
    if (!courseId) {
        console.log('Error@deleteCourse: no course id', courseId)
    }
    await firebase
        .firestore()
        .collection("courses")
        .doc(courseId)
        .delete()

}


function getCourseById(courseId) {
    if (!state.courses) {
        return null;
    }
    for (let course of state.courses) {
        if (course.id == courseId) {
            return course;
        }
    }
    return null;
}


export default function () {

    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        watch(auth.companyId, async (companyId) => {
            if (companyId) {
                loadCourses();
            } else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadCourses();
        }

        state.initialized = true
        state.initializing = false
    }





    return {
        ...toRefs(state),
        loadCourses,
        reorderCourse,
        teardown,
        updateCourse,
        deleteCourse,
        addCourse,
        getCourseById
        

    }


}