import { toRefs, reactive, watch } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();
import axios from "axios";
import config from "../../config"


const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.printers = [];

}
const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    printers: [],
    printJobs: [],
    stati: {}
});


async function loadPrinters() {

    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }



    let printersUnsubscribeHandle = firebase
        .firestore()
        .collection("printers")
        .where("companyId", "==", companyId)
        .orderBy("name", "asc")
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;
                if (type === "added") {
                    state.printers.splice(newIndex, 0, data);
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.printers.splice(oldIndex, 1);
                    state.printers.splice(newIndex, 0, data);

                } else if (type === "removed") {
                    state.printers.splice(oldIndex, 1);
                }

            });
        })
    unsubscribeHandles.push(printersUnsubscribeHandle);


    let statusUnsubscribeHandle = firebase
        .firestore()
        .collection("printer-status")
        .where("companyId", "==", companyId)
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;
                if (type === "added") {

                    state.stati[doc.id] = data
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    //console.log("modified printer",data)
                    // remove the old one first
                    state.stati[doc.id] = data
                } else if (type === "removed") {
                    delete state.stati[doc.id]
                }
            });
        })
    unsubscribeHandles.push(statusUnsubscribeHandle);

    let logsUnsubscribeHandle = firebase
        .firestore()
        .collection("printJobs")
        .where("companyId", "==", companyId)
        .orderBy("date", "desc")
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;
                if (type === "added") {
                    state.printJobs.splice(newIndex, 0, data);
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.printJobs.splice(oldIndex, 1);
                    state.printJobs.splice(newIndex, 0, data);

                } else if (type === "removed") {
                    state.printJobs.splice(oldIndex, 1);
                }


            });
        })
    unsubscribeHandles.push(logsUnsubscribeHandle);
}

async function savePrinter(printer) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    let data = {}
    data.companyId = companyId;
    data.name = printer.name
    data.paperWidth = printer.paperWidth || null
    data.locationsFilter = printer.locationsFilter || null
    data.enabled = printer.enabled || false;
    data.mac = printer.mac || null;

    let response = null
    try {

        let token = await firebase.auth().currentUser.getIdToken();
        let response = await axios.post(config.apiUrl + "/api/printers/" + (printer.id ? printer.id : ''), data, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        if (!response || !response.data) {
            throw new Error("incomplete return value");
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error == "printer-mac-already-added") {
            throw new Error("printer-mac-already-added");
        }else{
            throw error
        }
    }

}

async function deletePrinter(printerId) {
    if (!auth.user) {
        teardown();
        return;
    }
    if (!printerId) {
        console.log('Error@deletePrinter: no printerId', printerId)
    }

    await firebase
        .firestore()
        .collection("printers")
        .doc(printerId)
        .delete()
}


async function cancelPrintJob(printJob) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }
    if (!printJob.id) {
        console.log('Error@printJob: no id', printJob)
        return
    }
    printJob.companyId = companyId;
    await firebase
        .firestore()
        .collection("printJobs")
        .doc(printJob.id)
        .set({status: "canceled"}, { merge: true })
}

async function restartPrintJob(printJob) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }
    if (!printJob.id) {
        console.log('Error@printJob: no id', printJob)
        return
    }
    printJob.companyId = companyId;
    await firebase
        .firestore()
        .collection("printJobs")
        .doc(printJob.id)
        .set({status: "new"}, { merge: true })
}

export default function () {


    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        watch(auth.companyId, async (companyId) => {
            if (companyId) {
                loadPrinters();
            }else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {            
            loadPrinters();
        }
        state.initialized = true
        state.initializing = false
    }

    function getPrinterById(printerId) {
        if (!state.printers) {
            return null;
        }
        for (let printer of state.printers) {
            if (printer.id == printerId) {
                return printer;
            }
        }
        return null;
    }



    return {
        ...toRefs(state),
        loadPrinters,
        teardown,
        deletePrinter,
        savePrinter,
        getPrinterById,
        cancelPrintJob,
        restartPrintJob
    }


}