import { toRefs, reactive, watch } from "vue";
import axios from "axios";
import config from "../../config"

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();


const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.subscriptions = [];
    state.error = null;
}

const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    subscriptions: []    
});


async function loadSubscriptions() {

    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }



    let subscriptionUnsubscribeHandle = firebase
        .firestore()
        .collection("subscriptions")        
        .where("companyId", "==", companyId)
        .orderBy("created", "asc")
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;
                if (type === "added") {
                    state.subscriptions.splice(newIndex, 0, data);
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.subscriptions.splice(oldIndex, 1);
                    state.subscriptions.splice(newIndex, 0, data);

                } else if (type === "removed") {
                    state.subscriptions.splice(oldIndex, 1);
                }

                
                // for(let subscription of state.subscriptions){
                //     if(subscription.status == "trialing" || subscription.status == "complete" || subscription.status == "active" )
                //     state.hasAnActiveSubscription = true;
                // }
            });
        })        
    unsubscribeHandles.push(subscriptionUnsubscribeHandle);
}

async function getCustomerPortalSessionUrl(subscription){

    let token = await firebase.auth().currentUser.getIdToken();
    
    let response = await axios.post(config.apiUrl + "/api/subscriptions/create-customer-portal-session", {subscriptionId: subscription.id}, {
        headers: {
            Authorization: "Bearer " + token,
        },
    });
    
        // console.log('response',response)

    return response.data.url;
      

}

async function getSessionData(sessionId){
    
    let response = await axios.post(config.apiUrl + "/api/subscriptions/session", {sessionId: sessionId}, {
        headers: {
    
        },
    });    
    // console.log('getSessionData response',response)

    return response.data;
      

}


export default function () {
    
    if (!state.initialized && !state.initializing) {
        state.initializing = true;
        
        watch(auth.companyId, async (companyId) => {
            if (companyId) {
                loadSubscriptions();
            }else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadSubscriptions();

        }
        state.initialized = true
        state.initializing = false
    }


    return {
        ...toRefs(state),
        loadSubscriptions,
        getCustomerPortalSessionUrl,
        teardown,
        getSessionData
        
    }


}