import { toRefs, reactive, watch } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();
import axios from "axios";
import config from "../../config"


const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.accounts = [];

}
const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    accounts: [],
});


async function loadAccounts() {

    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }    

    //only load if we have permissions (or are the owner)
    if(!auth.owner && !(auth.permissions && auth.permissions.value && auth.permissions.value.includes('accounts'))){
        return;
    }



    let accountsUnsubscribeHandle = firebase
        .firestore()
        .collection("accounts")
        .where("companyId", "==", companyId)
        .orderBy("name", "asc")
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;
                if (type === "added") {
                    state.accounts.splice(newIndex, 0, data);
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.accounts.splice(oldIndex, 1);
                    state.accounts.splice(newIndex, 0, data);
                } else if (type === "removed") {
                    state.accounts.splice(oldIndex, 1);
                }
            });
        })
    unsubscribeHandles.push(accountsUnsubscribeHandle);

}

async function saveAccount(account) {
    let companyId = auth.companyId && auth.companyId.value
    if ( !companyId ) {
        teardown();
        return;
    }



    account.companyId = companyId
    
    
    try {

        let token = await firebase.auth().currentUser.getIdToken();
        let response = await axios.post(config.apiUrl + "/api/accounts/" + (account.id ? account.id : ''), account, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        if (!response || !response.data) {
            throw new Error("incomplete return value");
        }
    } catch (error) {
        throw error        
    }

}


async function deleteAccount(accountId) {
    if (!auth.user) {
        teardown();
        return;
    }
    if (!accountId) {
        console.log('Error@deleteAccount: no accountId', accountId)
    }

    await firebase
        .firestore()
        .collection("accounts")
        .doc(accountId)
        .delete()
}

function getAccountById(accountId) {
    if (!state.accounts) {
        return null;
    }
    for (let account of state.accounts) {
        if (account.id == accountId) {
            return account;
        }
    }
    return null;
}


export default function () {


    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        watch(auth.companyId, async (companyId) => {
            if (companyId) {
                loadAccounts();
            }else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadAccounts();
        }
        state.initialized = true
        state.initializing = false
    }

    return {
        ...toRefs(state),
        loadAccounts,
        teardown,
        deleteAccount,
        saveAccount,
        getAccountById        
    }
}