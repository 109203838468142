<template>
  <div class="oido-quantity-input"
    :class="{ 'has-errors': errors && errors.length > 0, 'readonly': readonly, 'disabled': disabled}">
    <slot name="before"></slot>
    <div class="input-content">
      <label :for="componentId">
        <span class="label-text" v-html="label"></span>
      </label>
      <oido-help-icon class="help-text" v-if="helpText" :help-text="helpText" :position="helpTextPosition"></oido-help-icon>
      <div class="input-section">
        <oido-button class="count-button count-down-button" v-if="!readonly" icon="remove" @click="localValue--"></oido-button>
        <span class="value" v-text="localValue" :disabled="disabled" ></span>
        <oido-button class="count-button count-up-button" v-if="!readonly" :id="componentId" icon="add" @click="localValue++"></oido-button>
      </div>
    </div>
    <div class="validation-errors" v-if="errors">
      <span class="validation-error" v-for="error of errors" v-text="error" :key="error"></span>
    </div>

    <slot name="after"></slot>
  </div>
</template>

<script setup>
import { v4 as uuidv4 } from 'uuid';
</script>
<script>
export default {
  data() {
    return {
      localValue: null
    };
  },
  mounted() {
    this.updateLocalValue()
  },
  props: {
    modelValue: Number,
    errors: [Object],
    name: String,
    label: String,
    readonly: Boolean,
    required: Boolean,
    disabled: Boolean,
    helpText: String,
    helpTextPosition: String    
  },
  watch: {
    modelValue() {
      this.updateLocalValue();
    },
    localValue() {      
      if (this.localValue != this.modelValue) {
        this.valueChanged()
      }
    },
  },

  computed: {
    componentId() {
      return this.name + '-' + uuidv4()
    }

  },

  methods: {
    updateLocalValue() {
      
      if (this.localValue !== this.modelValue) {
        this.localValue = this.modelValue;
      }
    },
    valueChanged() {
      
      this.$emit("update:modelValue", this.localValue);
    },
  },
};
</script>


<style scoped>
.oido-quantity-input{
  display: flex;  
  position: relative;
  width: min-content;  
}
.oido-quantity-input .input-section{
  display: flex;  
  position: relative;
  width: min-content;  
}
.oido-quantity-input .input-section .count-button{
  font-size: 1.2rem;
  padding: 0.15em;  
  background-color: #eee;
  border-radius: 2em;
  -webkit-user-select: none; /* Safari */
  user-select: none;
}

.oido-quantity-input .input-section .value{
  font-size: 1.2rem;
  font-weight: 599;
  padding: 0 0.3rem;
  min-width: 3em;
  text-align: center;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.oido-quantity-input .input-content{
display: flex;
flex-direction: column;
align-items: center;
}
.oido-quantity-input label{
  padding-bottom: 0.5rem;
}

</style>