<template>
  <div class="oido-checkbox"
    :class="{ 'has-errors': errors && errors.length > 0, 'readonly': readonly, 'disabled': disabled, 'checkbox-before-label': checkboxBeforeLabel}">

    <slot name="before"></slot>
    <div class="input-content">
      <div class="input-section" v-if="checkboxBeforeLabel">
        <oido-icon v-if="readonly" :name="'checkbox-' + (localValue ? 'checked' : 'empty')"
          :stroke="checkboxColor"></oido-icon>
        <input type="checkbox" v-model="localValue" v-if="!readonly" :name="name" :disabled="disabled" :id="componentId">
      </div>
      <label :for="componentId">
        <span class="label-text" v-html="label"></span>
      </label>
      <oido-help-icon class="help-text" v-if="helpText" :help-text="helpText" :position="helpTextPosition"></oido-help-icon>
      <div class="input-section" v-if="!checkboxBeforeLabel">
        <oido-icon v-if="readonly" :name="'checkbox-' + (localValue ? 'checked' : 'empty')"
          :stroke="checkboxColor"></oido-icon>
        <input type="checkbox" v-model="localValue" v-if="!readonly" :name="name" :disabled="disabled" :id="componentId">
      </div>
    </div>
    <div class="validation-errors" v-if="errors">
      <span class="validation-error" v-for="error of errors" v-text="error" :key="error"></span>
    </div>

    <slot name="after"></slot>
  </div>
</template>

<script setup>
import { v4 as uuidv4 } from 'uuid';
import AboFeatureBadge from './AboFeatureBadge.vue';
</script>
<script>
export default {
  data() {
    return {
      localValue: null
    };
  },
  mounted() {
    this.updateLocalValue()
  },
  props: {
    modelValue: Boolean,
    errors: [Object],
    name: String,
    label: String,
    readonly: Boolean,
    required: Boolean,
    disabled: Boolean,
    checkboxBeforeLabel: Boolean,
    helpText: String,
    helpTextPosition: String    
  },
  watch: {
    modelValue() {
      this.updateLocalValue();
    },
    localValue() {
      
      if (this.localValue != this.modelValue) {
        this.valueChanged()
      }
    },
  },

  computed: {
    componentId() {
      return this.name + '-' + uuidv4()
    },
    checkboxColor() {
      if (this.disabled) {
        return '#bbb'
      }
      return "var(--ion-color-primary)";
    }


  },

  methods: {
    updateLocalValue() {
      if(typeof this.modelValue == 'undefined'){
        return
      }      
      if (this.localValue !== this.modelValue) {
        this.localValue = this.modelValue;
      }
    },
    valueChanged() {
      this.$emit("update:modelValue", this.localValue);
    },
  },
};
</script>


<style scoped>
.oido-checkbox {
  width: 100%;
  position: relative;
  padding-top: 0.8rem;
  padding-bottom: 0.7rem;
  font-size: 0.9rem;
}

.oido-checkbox.readonly .input-content .input-section .postfix,
.oido-checkbox.readonly label,
.oido-checkbox.readonly .input-content .ml-text {
  color: #000;
}


.oido-checkbox.disabled:not(.readonly) .input-content .input-section .postfix,
.oido-checkbox.disabled label,
.oido-checkbox.disabled .input-content .ml-text {
  color: #888;
}


.input-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.input-content .input-section {
  flex-basis: 50%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 0.3rem;
}


label {
  font-weight: 400;
  margin-right: 0.4rem;
}

.validation-errors {
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.validation-errors {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
  text-align: right;
}

@media (max-width: 500px) {
  .validation-errors {
    text-align: left;
  }
}



.oido-checkbox .oido-icon {
  width: 1.7rem;
  height: 1.7rem;
  padding: 0.1rem;

}

.oido-checkbox input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.2rem;
  
  background: url('https://demo.oido.me/assets/images/checkbox.svg') no-repeat center center;
}
.oido-checkbox.readonly *:not(.abo-feature-badge)  {
  filter: grayscale(100%);
}
.oido-checkbox.disabled input[type=checkbox] {
  cursor: default;
  background: url('https://demo.oido.me/assets/images/checkbox-disabled.svg') no-repeat center center;
}

.oido-checkbox input[type=checkbox]:checked {
  background: url('https://demo.oido.me/assets/images/checkbox-checked.svg') no-repeat center center;
}

.oido-checkbox.disabled input[type=checkbox]:checked {    
  background: url('https://demo.oido.me/assets/images/checkbox-checked-disabled.svg') no-repeat center center;  
}


.help-text{
  margin-left: 0rem;
  position: relative;
  
}
.checkbox-before-label .input-content {
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.checkbox-before-label .input-content .input-section {
  justify-content: flex-start;
  flex:0;
  margin-right: 0.1rem;
}

.checkbox-before-label .validation-errors{
  text-align: left;
}

</style>