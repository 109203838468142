import { createRouter, createWebHistory } from '@ionic/vue-router';


import authenticationService from "./composables/authenticationService";
const auth = authenticationService();


const routes = [
  {
    path: "/",
    name: "startpage",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import('@/views/StatisticsPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "statistics"] }
  },
  {
    path: "/orders",
    name: "orders",
    redirect: "/orders/overview",
    meta: { requiresAuth: true, requiredPermissions: ["owner", "orders"] }
  },
  {
    path: "/orders/overview",
    name: "orders-overview",
    component: () => import('@/views/DashboardPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "orders"] }
  },
  {
    path: "/orders/list",
    name: "orders-list",
    component: () => import('@/views/OrdersPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "orders"] }
  },
  {
    path: "/orders/open",
    name: "orders-open",
    component: () => import('@/views/OrdersPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "orders"] }
  },
  {
    path: "/content",
    name: "content",
    component: () => import('@/views/ContentPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "content"] }
  },
  {
    path: "/pages/:id/edit",
    name: "content-edit-page",
    component: () => import('@/views/PageEditorPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "content"] }
  },
  {
    path: "/pages/new",
    name: "content-add-page",
    component: () => import('@/views/PageEditorPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "content"] }
  },
  {
    path: "/stories/:id/edit",
    name: "content-edit-story",
    component: () => import('@/views/StoryEditorPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "content"] }
  },
  {
    path: "/stories/new",
    name: "content-add-story",
    component: () => import('@/views/StoryEditorPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "content"] }
  },
  {
    path: "/menu",
    name: "menu",
    redirect: "/menu/products",
    meta: { requiresAuth: true, requiredPermissions: ["owner", "products"] }
  },
  {
    path: "/menu/categories-tags-courses",
    name: "menu-categories-tags-courses",
    component: () => import('@/views/SettingsCategoriesPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "products"] }
  },
  {
    path: "/menu/products",
    name: "menu-products",
    component: () => import('@/views/ProductsPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "products"] }
  },
  {
    path: "/menu/products/:id/edit",
    name: "products-edit-product",
    component: () => import('@/views/CombinedProductEditorPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "products"] }
  },
  {
    path: "/menu/products/new",
    name: "products-add-product",
    component: () => import('@/views/CombinedProductEditorPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "products"] }
  },
  {
    path: "/menu/discounts",
    name: "discounts",
    component: () => import('@/views/DiscountsPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "products"] }
  },
  {
    path: "/menu/discounts/:id/edit",
    name: "discounts-edit-discount",
    component: () => import('@/views/DiscountEditorPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "products"] }
  },
  {
    path: "/menu/discounts/new",
    name: "discounts-add-discount",
    component: () => import('@/views/DiscountEditorPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "products"] }
  },
  {
    path: "/accounts",
    name: "accounts",
    component: () => import('@/views/AccountsPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "accounts"] }
  },
  {
    path: "/accounts/:id/edit",
    name: "accounts-edit-account",
    component: () => import('@/views/AccountEditorPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "accounts"] }
  },
  {
    path: "/menu/accounts/new",
    name: "accounts-add-account",
    component: () => import('@/views/AccountEditorPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "accounts"] }
  },
  {
    path: "/pointsofsale",
    name: "pointsofsale",
    redirect: "/zones",
    meta: { requiresAuth: true, requiredPermissions: ["owner", "zones"] }
  },
  {
    path: "/zones",
    name: "zones",
    component: () => import('@/views/ZonesPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "zones"] }
  },
  {
    path: "/zones/:id/edit",
    name: "zones-edit-zone",
    component: () => import('@/views/ZoneEditorPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "zones"] }
  },
  {
    path: "/zones/new",
    name: "zones-add-zone",
    component: () => import('@/views/ZoneEditorPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "zones"] }
  },
  {
    path: "/orders/list",
    name: "orders-list",
    component: () => import('@/views/OrdersPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "orders"] }
  },

  {
    path: "/settings",
    name: "settings",
    redirect: "/settings/general",
    meta: { requiresAuth: true, requiredPermissions: ["owner", "settings"] }
  },
  {
    path: "/settings/general",
    name: "settings-general-settings",
    component: () => import('@/views/SettingsGeneralSettingsPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "settings"] }
  },
  {
    path: "/settings/opening-hours",
    name: "opening-hours-settings",
    component: () => import('@/views/OpeningHoursSettingsPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "settings"] }
  },
  {
    path: "/delivery",
    name: "delivery",
    component: () => import('@/views/DeliverySettingsPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "settings"] }
  },
  {
    path: "/settings/frontend",
    name: "frontend-settings",
    component: () => import('@/views/FrontendSettingsPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "settings"] }
  },
  {
    path: "/settings/banking",
    name: "settings-banking",
    component: () => import('@/views/SettingsBankingPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "settings"] }
  },
  {
    path: "/settings/api",
    name: "settings-api",
    component: () => import('@/views/SettingsApiSettingsPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "settings"] }
  },
  {
    path: "/settings/info",
    name: "settings-company-info",
    component: () => import('@/views/SettingsCompanyInfoPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "settings"] }
  },
  {
    path: "/settings/printers",
    name: "settings-printers",
    component: () => import('@/views/SettingsPrintersPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "settings"] }
  },
  {
    path: "/settings/printers/:id/edit",
    name: "settings-printers-edit-printer",
    component: () => import('@/views/SettingsPrintersEditPrinterPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "settings"] }
  },
  {
    path: "/settings/printers/new",
    name: "settings-printers-add-printer",
    component: () => import('@/views/SettingsPrintersEditPrinterPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner", "settings"] }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/ForgotPassword.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/views/ResetPassword.vue"),
  },
  {
    path: "/registration",
    name: "registration",
    component: () => import("@/views/RegistrationPage.vue"),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/PrivacyPage.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'termsAndConditions',
    component: () => import('@/views/TermsAndConditionsPage.vue')
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/NotificationsTab.vue')
  },
  {
    path: '/users/:userId',
    name: 'userpage',
    component: () => import('@/views/UserPage.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner"] }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/ProfileTab.vue'),
    meta: { requiresAuth: true, requiredPermissions: ["owner"] }
  },
  {
    path: '/the-end',
    name: 'the-end',
    component: () => import('@/views/EmptyPage.vue'),
    meta: { requiresAuth: true }
  },

]

let baseUrl = typeof process != 'undefined' ? process.env.BASE_URL : ""
baseUrl += "/admin"
const router = createRouter({

  history: createWebHistory(baseUrl),
  routes
})


router.beforeEach((to, from, next) => {
  // console.log('router.beforeEach in router', to.name);  
  // console.log('router.beforeEach permissions', auth.permissions.value);


  //an authenticated user has ben sent to login (probably direct after a successful login)
  //honour any redirect in the urls query
  if (auth.user.value && (to.name === 'login')) {
    if (to.query && to.query.redirect) {
      return next({ path: to.query.redirect, replace: true });
    } else {
      return next({ name: "startpage", replace: true });
    }
  }


  //unauthenticated user wants to access protected site
  //send him to login and encode the destination as redirect query
  if (!auth.user.value && to.meta.requiresAuth) {
    let destination = to.path;
    return next({ name: "login", replace: true, query: { redirect: destination } });
  }


  //authenticated user wants to access a page which requires an permission
  if (auth.user.value && to.meta.requiredPermissions && to.meta.requiredPermissions.length > 0) {

    let ownedPermissions = auth.permissions && auth.permissions.value ? auth.permissions.value : []

    if (auth.user.value.uid == auth.companyId.value) {
      ownedPermissions.push('owner')
    }

    // console.log('ownedPermissions', ownedPermissions)
    // console.log('to.meta.requiredPermissions', to.meta.requiredPermissions)

    if (!to.meta || !to.meta.requiredPermissions) {
      return next()
    }


    //if the user has one of the requiredPermissions
    for (let requiredPermission of to.meta.requiredPermissions) {
      if (ownedPermissions.includes(requiredPermission)) {
        return next()
      }
    }


    //if the user doesn't have the required permissions find the first toplevel he is allowed to see
    for (let route of routes) {
      if (route.redirect) {
        continue
      }

      if (!route.meta || !route.meta.requiredPermissions) {
        return next({ name: route.name, replace: true });
      }

      for (let requiredPermission of route.meta.requiredPermissions) {
        if (ownedPermissions.includes(requiredPermission)) {
          return next({ name: route.name, replace: true });
        }
      }

    }

    console.log('the end')
    return next({ name: "the-end", replace: true });
  }

  //everything else
  return next();

})

export default router
