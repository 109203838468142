<template>

  <div class="oido-bubble" :class="{ ['position-' + position]: true, [icon]: true, 'primary': primary }"
    v-if="bubbleText" @click.stop="toggleBubbleText">
    <oido-icon :name="icon" ></oido-icon>
    <div class="bubble-text" v-if="showBubbleText">
      <span class="close-icon">x</span>
      <div class="bubble-text-content">
        <div class="text" v-if="bubbleText" v-html="bubbleText"></div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>


<script>
import { v4 as uuidv4 } from 'uuid';

var timeOutRef = null;
export default {
  props: {
    icon: String,
    bubbleText: String,
    position: String,
    primary: Boolean
  },
  data() {
    return {
      showBubbleText: false
    };
  },
  mounted() {
    this.emitter.on("oido-bubble:active", (data) => {
      if (data.componentId != this.componentId) {
        this.showBubbleText = false;
        if (timeOutRef) {
          clearTimeout(timeOutRef);
        }
      }
    });

  },
  computed: {
    componentId() {
      return "oido-bubble" + '-' + uuidv4()
    }
  },


  methods: {
    toggleBubbleText() {

      if (timeOutRef) {
        clearTimeout(timeOutRef);
      }
      if (this.showBubbleText) {
        this.showBubbleText = false
      } else {
        this.showBubbleText = true;
        this.emitter.emit("oido-bubble:active", { componentId: this.componentId });

        timeOutRef = setTimeout(() => {
          this.showBubbleText = false;
        }, 2000000)
        return false
      }


    }
  }



};
</script>


<style scoped>
.oido-bubble {
  display: inline-block;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */
  user-select: none;
}

.oido-icon {
  position: relative;
  top: 0.15rem;
  width: 1rem;
  height: 1rem;
  display: inline-block;
}

.oido-bubble .bubble-text {
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  padding: 1rem;
  font-weight: 300;
  font-size: 0.9rem;

  border: 1px solid #f0f0f0;
  box-shadow: 0 0px 15px 3px rgba(0, 0, 0, 0.05);
  line-height: 1.1rem;
  bottom: 2rem;
  left: -0rem;
  width: 20rem;
  z-index: 500;
  border-radius: 0.3rem;
}

.oido-bubble.position-bottom .bubble-text {
  bottom: inherit;
  top: 1.5rem;
}

.oido-bubble.position-bottom-left .bubble-text {
  bottom: inherit;
  top: 1.5rem;
  margin-left: -17rem;
}

.oido-bubble.position-top-left .bubble-text {
  margin-left: -17rem;
}

@media (max-width: 500px) {
  .oido-bubble .bubble-text {
    width: 14rem;
  }

  .oido-bubble.position-bottom-left .bubble-text {
    margin-left: -13rem;
  }

  .oido-bubble.position-top-left .bubble-text {
    margin-left: -13rem;
  }

}

.bubble-text-content .text{
  white-space: pre-wrap;
  line-height: 1.4;
 
}

.close-icon {
  position: absolute;
  right: 0.5rem;
  top: 0.1rem;
  color: #999;
}
</style>
<style>
.oido-bubble.scan svg {
  stroke: transparent;
  fill: var(--ion-color-medium);
  stroke-width: 1px;
}

.oido-bubble.add-note svg,
.oido-bubble.note svg {
  stroke: transparent;
  stroke-width: 0px !important;
}

.oido-bubble.note svg *,
.oido-bubble.add-note svg * {
  stroke-width: 0 !important;
  fill: var(--ion-color-medium) !important;

}

.oido-bubble.primary .oido-icon svg,
.oido-bubble.primary .oido-icon svg * {
  fill: var(--ion-color-primary) !important;
}
</style>
