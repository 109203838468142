<template>
<div >
  <ion-item  lines="none" :detail="false" button @click="dismiss('logout')">{{$t('profile-page-logout-label')}}</ion-item>
</div>
  
</template>

<script setup>
import {
  IonItem,
  popoverController,
} from "@ionic/vue";

</script>
<script>


export default {
  methods: {    
    dismiss(action) {      
      popoverController.dismiss({action});      
    },
  },
};
</script>
<style scoped>
</style>