<template>
  <div class="oido-value-list" :class="{[columnClassName]: true}">
    <slot></slot>
  </div>
</template>


<script>



export default {
  props: ["columns"],
  computed:{
    columnClassName(){
      return "columns-" + this.columns
    }
  }


};
</script>


<style scoped>
.oido-value-list {
}
.columns-2{
  column-count: 2;
}
.columns-3{
  column-count: 3;
}
.columns-4{
  column-count: 4;
}
@media (max-width: 700px) {
.oido-value-list {
  column-count: 1!important;
}

}
</style>
