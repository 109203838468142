<template>
  <div class="oido-card-header">
    <slot></slot>
    <div class="actions" v-if="$slots['actions']">
      <slot name="actions"></slot>
    </div>
  </div>
</template>


<script >



export default {



};
</script>


<style scoped>
.oido-card-header {
  padding: 1.2rem 0.2rem 0.9rem;
  margin: 0 1.4rem;
  display: flex;
  border-bottom: 1px solid #f3f3f3;
  align-items: flex-end
}
.oido-card-header .actions{
  display: flex;
  justify-content: flex-end;
  flex: 1;
}


</style>

<style>
.oido-card-header .actions ion-button{
  min-height: 0;
  margin: 0;
  margin-left: 0.5rem;
  
}
.oido-card-header .actions ion-button::part(native) {
  padding:0.1rem 0.3rem;
  
}

.oido-card-header .actions .oido-button{
  font-size: 0.8rem;
  font-weight: 450;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-left: 1rem;
}
@media (max-width: 500px) {
  .oido-card-header .actions .oido-button{    
  }

  .oido-card-header .actions .oido-button:has(.icon) .label{
    display: none;
  }
}

</style>


