import { toRefs, reactive, watch } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();


const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.tags = [];
    state.error = null;

}
const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    tags: []
});


async function loadTags() {

    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }


    let tagsUnsubscribeHandle = firebase
        .firestore()
        .collection("tags")
        .where("companyId", "==", companyId)
        .orderBy("sortOrder", "asc")
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;
                if (type === "added") {
                    state.tags.splice(newIndex, 0, data);
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.tags.splice(oldIndex, 1);
                    state.tags.splice(newIndex, 0, data);

                } else if (type === "removed") {
                    state.tags.splice(oldIndex, 1);
                }

            });
        })
    unsubscribeHandles.push(tagsUnsubscribeHandle);
}


async function addTag() {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    let sortOrder = 1000;
    if (state.tags && state.tags.length > 0) {
        for (let tag of state.tags) {
            if (tag.sortOrder >= sortOrder) {
                sortOrder = tag.sortOrder + 500;
            }
        }
    }

    let tag = {
        name: {},
        companyId: companyId,
        sortOrder: sortOrder,
    }

    await firebase
        .firestore()
        .collection("tags")
        .add(tag)
}

async function reorderTag(from, to) {
    if (!auth.user) {
        teardown();
        return;
    }

    let move = function (target, from, to) {
        target.splice(to, 0, target.splice(from, 1)[0]);
    };

    let affectedTag = state.tags[from];

    let copiedTags = JSON.parse(JSON.stringify(state.tags))
    move(copiedTags, from, to);
    let sortOrder = 1000;

    let previousSortOrder = to > 0 ? copiedTags[to - 1].sortOrder : null;
    let nextSortOrder = to < copiedTags.length - 1 ? copiedTags[to + 1].sortOrder : null;

    console.log('previousSortOrder', previousSortOrder);
    console.log('nextSortOrder', nextSortOrder);

    if (!previousSortOrder && nextSortOrder) {
        sortOrder = nextSortOrder - 500;
    }
    if (previousSortOrder && !nextSortOrder) {
        sortOrder = previousSortOrder + 500;
    }
    if (previousSortOrder && nextSortOrder) {
        sortOrder = previousSortOrder + (nextSortOrder - previousSortOrder) / 2;
    }
    console.log('sortOrder', sortOrder);

    await firebase
        .firestore()
        .collection("tags")
        .doc(affectedTag.id)
        .set({ sortOrder: sortOrder }, { merge: true })


}
async function updateTag(tag) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }


    if (!tag.id) {
        console.log('Error@updateTag: no cat id', tag)
    }

    console.log('Info@updateTag: cat id', JSON.stringify(tag))
    tag.companyId = companyId;

    await firebase
        .firestore()
        .collection("tags")
        .doc(tag.id)
        .set(tag, { merge: true })
}
async function deleteTag(tagId) {
    if (!auth.user) {
        teardown();
        return;
    }
    if (!tagId) {
        console.log('Error@deleteTag: no cat id', tagId)
    }
    
    await firebase
        .firestore()
        .collection("tags")
        .doc(tagId)
        .delete()
}

export default function () {


    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        watch(auth.companyId, async (companyId) => {
            if (companyId) {
                loadTags();                
            }else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadTags();

        }
        state.initialized = true
        state.initializing = false
    }





    return {
        ...toRefs(state),
        loadTags,
        reorderTag,
        teardown,
        updateTag,
        deleteTag,
        addTag

    }


}