<template>
<div class="oido-modal-subtitle">
  <slot></slot>
</div>  
</template>


<script >



export default {

  

};
</script>


<style scoped>

.oido-modal-subtitle{
  margin-top: 0;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  color: #AAAAAA;
  padding: 0.3rem 1rem 0rem;
  width: 100%;
}

</style>


