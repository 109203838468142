<template>
  <span class="oido-icon"  :class="{[name]: true}" v-html="svg" ></span>
</template>


<script >
import icons from './icons';

export default {
  props: {
    name: String,
    stroke: String,
    strokeWidth: String,
    size: String,
  },
  computed: {
    svg() {
      let svg = null
      if (icons[this.name]) {
        svg = icons[this.name]
      }
      if(svg){
        let styles = '';
        if(this.stroke){
          styles += `stroke: ${this.stroke}!important;`;
        }
        if(this.size){
          styles += `width: ${this.size}!important;`;
        }
        if(this.strokeWidth){
          styles += `stroke-width: ${this.strokeWidth}!important;`;
        }
        svg = svg.replace('<svg ', `<svg style="${styles}" ` );
      }
      return svg;
    }

  }
}

</script>


<style scoped>

</style>
<style>
.oido-icon svg {  
  stroke: black;
  fill: transparent;
  stroke-width: 50px;
}


.oido-icon.clock-outgoing svg,
.oido-icon.clock-incoming svg{
  width: 1rem;  
  height: 1rem;
  font-size: 1rem;
  stroke-width: 1px;
  fill:inherit;
}
.oido-icon.delivery-time svg{
  width: 28.8867px!important;
height: 21.8066px!important;
font-size: 1rem;
  stroke-width: 0.5px;
  fill:inherit;
  zoom: 0.75;
}
</style>


