import { toRefs, reactive, watch } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();
import axios from "axios";
import config from "../../config"

import fileServiceModule from "./fileService";
const fileService = fileServiceModule();


const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.stories = [];
    state.error = null;

}

const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    stories: []
});


async function loadStories() {

    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }

    //only load if we have permissions (or are the owner)
    if(!auth.owner && !(auth.permissions && auth.permissions.value && auth.permissions.value.includes('content'))){
        return;
    }


    let storiesUnsubscribeHandle = firebase
        .firestore()
        .collection("stories")
        .where("companyId", "==", companyId) 
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;
                if (type === "added") {
                    state.stories.splice(newIndex, 0, data);
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.stories.splice(oldIndex, 1);
                    state.stories.splice(newIndex, 0, data);

                } else if (type === "removed") {
                    state.stories.splice(oldIndex, 1);
                }

            });
        })
    unsubscribeHandles.push(storiesUnsubscribeHandle);
}



async function saveStory(story) {
    
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    
    story.companyId = companyId;


    //only upload picuture if ist really a blob
    if (story.picture && typeof story.picture == 'object') {
        story.picture = await fileService.uploadFile(story.picture);
    }    

    try {
        let token = await firebase.auth().currentUser.getIdToken();
        let response = await axios.post(config.apiUrl + "/api/stories/" + (story.id ? story.id : ''), story, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        if (!response || !response.data) {
            throw new Error("incomplete return value");
        }
    } catch (error) {
        throw error
    }

}

async function addStory() {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }



    let sortOrder = 1000;
    if (state.stories && state.stories.length > 0) {
        for (let story of state.stories) {
            if (story.sortOrder >= sortOrder) {
                sortOrder = story.sortOrder + 500;
            }
        }
    }

    let story = {
        name: {},
        companyId: companyId,
        sortOrder: sortOrder,
    }

    await firebase
        .firestore()
        .collection("stories")
        .add(story)
}

async function reorderStory(from, to) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }


    let move = function (target, from, to) {
        target.splice(to, 0, target.splice(from, 1)[0]);
    };

    let affectedStory = state.stories[from];

    let copiedCats = JSON.parse(JSON.stringify(state.stories))
    move(copiedCats, from, to);
    let sortOrder = 1000;

    let previousSortOrder = to > 0 ? copiedCats[to - 1].sortOrder : null;
    let nextSortOrder = to < copiedCats.length - 1 ? copiedCats[to + 1].sortOrder : null;

    // console.log('previousSortOrder',previousSortOrder);
    // console.log('nextSortOrder', nextSortOrder);

    if (!previousSortOrder && nextSortOrder) {
        sortOrder = nextSortOrder - 500;
    }
    if (previousSortOrder && !nextSortOrder) {
        sortOrder = previousSortOrder + 500;
    }
    if (previousSortOrder && nextSortOrder) {
        sortOrder = previousSortOrder + (nextSortOrder - previousSortOrder) / 2;
    }
    // console.log('sortOrder', sortOrder);

    await firebase
        .firestore()
        .collection("stories")
        .doc(affectedStory.id)
        .set({ sortOrder: sortOrder }, { merge: true })
}

async function updateStory(story) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    if (!story.id) {
        console.log('Error@updateStory: no cat id', story)
    }

    if (!story.name) {
        story.name = {}
    }

    // console.log('Info@updateStory: cat id', JSON.stringify(story))
    story.companyId = companyId;

    await firebase
        .firestore()
        .collection("stories")
        .doc(story.id)
        .set(story, { merge: true })
}
async function deleteStory(storyId) {
    if (!auth.user) {
        teardown();
        return;
    }
    if (!storyId) {
        console.log('Error@deleteStory: no story id', storyId)
    }
    await firebase
        .firestore()
        .collection("stories")
        .doc(storyId)
        .delete()

}

export default function () {

    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        watch(auth.companyId, async (companyId) => {
            if (companyId) {
                loadStories();
            }else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadStories();

        }
        state.initialized = true
        state.initializing = false
    }





    return {
        ...toRefs(state),
        loadStories,
        reorderStory,
        teardown,
        updateStory,
        deleteStory,
        addStory,
        saveStory

    }


}