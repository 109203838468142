<template>

  <div class="language-row" v-if="loggedInUser && enabledLanguages && enabledLanguages.length > 1 && !(automaticTranslationsAreEnabled && hideTranslationTabs)">

    <div class="label" v-text="$t('content-language-selector-label')"></div>
    <oido-help-icon class="help-text"  :help-text="$t('content-language-selector-helptext')" ></oido-help-icon>
    <ion-button id="content-language-trigger-button" class="language-button" fill="clear" size="small" :class="{}">
      <ion-label v-text="$t('language-name-' + selectedLanguage)"></ion-label>
    </ion-button>
    
    <ion-popover trigger="content-language-trigger-button" mode="ios" >
      <div class="popover menu">
        <div class="popover-content">
          <div class="language" v-for="language of enabledLanguages" :key="language" :value="language"
          @click="selectLanguage(language)" v-text="$t('language-name-' + language)"
          :class="{'selected': language == selectedLanguage}">
        </div>
        </div>
      </div>
    </ion-popover>
  </div>


</template>

<script>
import {
  IonButton,
  IonPopover  
} from "@ionic/vue";
import translationService from "../composables/translationService";
import { IonFab, IonFabButton, IonFabList, IonLabel } from "@ionic/vue";

//DE 🇩🇪 EN 🇬🇧 ES 🇪🇸
export default {
  props: ["value"],
  components: { IonFab, IonFabButton, IonFabList, IonLabel,IonButton,IonPopover },
  data() {
    return {};
  },
  watch: {},
  async unmounted() { },
  async mounted() { },

  computed: {
    hideTranslationTabs() {
      return this.companySettings && this.companySettings.i18n && this.companySettings.i18n.hideTranslationTabs
    },
    automaticTranslationsAreEnabled() {
      return this.companySettings && this.companySettings.i18n && this.companySettings.i18n.enableAutomaticTranslation
    },
  },
  methods: {
    selectLanguage(language) {
      this.selectedLanguage = language;
    },
  },
  setup() {
    let { selectedLanguage, enabledLanguages } = translationService();
    return {
      selectedLanguage,
      enabledLanguages,
    };
  },
};
</script>
<style scoped>
.language-row {
  font-size: 0.8rem;
  font-weight: 300;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem 0.5rem 0.3rem 3.5rem;
  border-top: 1px solid #ddd;
  position: relative;
  
}
.language-row .label{
  flex:1;
}

.oido-help-icon{
  margin-bottom: 0.1rem;
}
.language-button {
  --padding-start: 0.5rem;
  --padding-end: 0.5rem;
  --padding-top: 0.3rem;
  --padding-bottom: 0.3rem;
  margin: 0;
  margin-right: -0.5rem;
  min-height: 1rem;
}
.popover-content .language{
  cursor: pointer;
  text-align: center;
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 300;
  padding: 0.3rem 0.5rem;
  margin-bottom: 0.3rem;
}
.popover-content .language.selected{
  color: var(--ion-color-primary);
}

</style>

<style>
.language-button ion-label {
  font-size: 0.7rem;

}

.language-row .oido-help-icon .help-text{
  width: 15rem!important;
  left: 1rem!important;
}
</style>
