<template>
<div class="oido-grid-column">
  <slot></slot>
</div>  
</template>


<script >



export default {

  

};
</script>


<style scoped>
.oido-grid-column{
  flex:1;
  display: flex;
  flex-direction: column;
}

.oido-grid .oido-grid-column.basis-66 {
  flex-basis: 66%;
}
.oido-grid .oido-grid-column.basis-33 {
  flex-basis: 33%;
}
.oido-grid .oido-grid-column.basis-60 {
  flex-basis: 60%;
}
.oido-grid .oido-grid-column.basis-40 {
  flex-basis: 40%;
}


</style>


