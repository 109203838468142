<template>
  <div class="oido-modal-header">
    <div class="actions">
      <slot name="actions"></slot>
      <ion-button class="cancel-button" @click="cancel" fill="clear" color="dark" size="large">
        <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
      </ion-button>
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import {
  IonButton,
  IonIcon,
  modalController,

} from "@ionic/vue";
import { closeOutline } from "ionicons/icons";
</script>
<script>


export default {

  methods: {
    cancel() {
      //test if its inside a vue-final-modal modal
      if(this.$el.closest('.vfm__content')){
        this.$emit('cancel')
      }else{
        //its inside an ionic modal
        modalController.dismiss();
      }      
    }
  }

};
</script>


<style scoped>
.oido-modal-header {
  padding: 0;
  margin: 0;
  display: flex;

  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.oido-modal-header .actions {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding: 0.6rem 0.7rem
}
</style>

<style>
.oido-modal-header .actions ion-button {
  min-height: 0;
  margin: 0;
  margin-left: 0.5rem;


}

.oido-modal-header .actions ion-button::part(native) {
  padding: 0.1rem 0.1rem;

}
</style>
