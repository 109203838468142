<template>
<h1 class="oido-card-title" :class="{ 'has-help-text': helpText }">
  <slot></slot>
  <oido-help-icon class="help-text" v-if="helpText" :help-text="helpText" :position="helpTextPosition"></oido-help-icon>
</h1>  
</template>


<script >



export default {
  props: {
    helpText: String,    
    helpTextPosition: String,    
  },
};
</script>


<style scoped>
.oido-card-title{
  margin: 0;
  padding: 0;;
  padding-bottom: 0;
  font-size: 1rem;
  font-weight: 600;
  position: relative;
}
.oido-card-title .oido-help-icon{
  margin-left: 0.2rem;
  
}

</style>
<style >
.oido-card-title .oido-help-icon .help-text{  
  bottom: initial !important;
  top: 1.5rem;
  
}
</style>


