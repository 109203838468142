import { toRefs, reactive, watch } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();


const unsubscribeHandles = [];

async function teardown() {
    for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
    state.categories = [];
    state.error = null;

}

const state = reactive({
    initialized: false,
    initializing: false,
    error: null,
    categories: []
});


async function loadCategories() {

    await teardown();

    let companyId = auth.companyId && auth.companyId.value

    if (!companyId) {
        return;
    }


    let categoriesUnsubscribeHandle = firebase
        .firestore()
        .collection("categories")
        .where("companyId", "==", companyId)
        .orderBy("sortOrder", "asc")
        .onSnapshot((docRef) => {
            docRef.docChanges().forEach((change) => {
                const { newIndex, oldIndex, doc, type } = change;
                let data = doc.data();
                data.id = doc.id;
                if (type === "added") {
                    state.categories.splice(newIndex, 0, data);
                    // if we want to handle references we would do it here
                } else if (type === "modified") {
                    // remove the old one first
                    state.categories.splice(oldIndex, 1);
                    state.categories.splice(newIndex, 0, data);

                } else if (type === "removed") {
                    state.categories.splice(oldIndex, 1);
                }

            });
        })
    unsubscribeHandles.push(categoriesUnsubscribeHandle);
}


async function addCategory() {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }


    let sortOrder = 1000;
    if (state.categories && state.categories.length > 0) {
        for (let category of state.categories) {
            if (category.sortOrder >= sortOrder) {
                sortOrder = category.sortOrder + 500;
            }
        }
    }

    let category = {
        name: {},
        companyId: companyId,
        sortOrder: sortOrder,
    }

    await firebase
        .firestore()
        .collection("categories")
        .add(category)
}

async function moveCategory(categoryId, index, parentId) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }

    let getSortOrderInList = (to, elem, list) => {

        let sortOrder = 1000;
        if (list.includes(elem)) {
            list.splice(list.indexOf(elem), 1);
        }

        list.splice(to, 0, elem);


        let previousSortOrder = to > 0 ? list[to - 1].sortOrder : null;
        let nextSortOrder = to < list.length - 1 ? list[to + 1].sortOrder : null;

        console.log('previousSortOrder', previousSortOrder);
        console.log('nextSortOrder', nextSortOrder);

        if (!previousSortOrder && nextSortOrder) {
            sortOrder = nextSortOrder - 500;
        }
        if (previousSortOrder && !nextSortOrder) {
            sortOrder = previousSortOrder + 500;
        }
        if (previousSortOrder && nextSortOrder) {
            sortOrder = previousSortOrder + (nextSortOrder - previousSortOrder) / 2;
        }
        console.log('sortOrder', sortOrder);

        return sortOrder;

    }


    let copiedCats = JSON.parse(JSON.stringify(state.categories))
    let catById = {}
    for (let category of copiedCats) {
        catById[category.id] = category;
    }
    let hierarchy = [];
    for (let category of copiedCats) {
        if (!category.parentId) {
            hierarchy.push(category);
        } else {
            if (!catById[category.parentId]) {
                continue
            }
            if (!catById[category.parentId].children) {
                catById[category.parentId].children = []
            }
            catById[category.parentId].children.push(category);
        }
    }

    let categoryToMove = catById[categoryId];
    if (!categoryToMove) {
        console.log('No categoryToMove!!', categoryId, catById)
        return;
    }
    let parentCategory = catById[parentId];
    if (parentId && !parentCategory) {
        console.log('No parentCategory!!')
        return;
    }
    if (parentCategory && !parentCategory.children) {
        parentCategory.children = []
    }

    let siblings = parentCategory ? parentCategory.children : hierarchy;
    let sortOrder = getSortOrderInList(index, categoryToMove, siblings)

    categoryToMove.parentId = parentId;
    categoryToMove.sortOrder = sortOrder;

    console.log('result', { sortOrder: sortOrder, parentId: parentId })
    await firebase
        .firestore()
        .collection("categories")
        .doc(categoryId)
        .set({ sortOrder: sortOrder, parentId: parentId }, { merge: true })

}
async function reorderCategory(from, to) {
    if (!auth.user) {
        teardown();
        return;
    }

    let move = function (target, from, to) {
        target.splice(to, 0, target.splice(from, 1)[0]);
    };

    let affectedCategory = state.categories[from];

    let copiedCats = JSON.parse(JSON.stringify(state.categories))
    move(copiedCats, from, to);
    let sortOrder = 1000;

    let previousSortOrder = to > 0 ? copiedCats[to - 1].sortOrder : null;
    let nextSortOrder = to < copiedCats.length - 1 ? copiedCats[to + 1].sortOrder : null;

    console.log('previousSortOrder', previousSortOrder);
    console.log('nextSortOrder', nextSortOrder);

    if (!previousSortOrder && nextSortOrder) {
        sortOrder = nextSortOrder - 500;
    }
    if (previousSortOrder && !nextSortOrder) {
        sortOrder = previousSortOrder + 500;
    }
    if (previousSortOrder && nextSortOrder) {
        sortOrder = previousSortOrder + (nextSortOrder - previousSortOrder) / 2;
    }
    console.log('sortOrder', sortOrder);

    await firebase
        .firestore()
        .collection("categories")
        .doc(affectedCategory.id)
        .set({ sortOrder: sortOrder }, { merge: true })


}
function getCategoryById(categoryId) {
    let catById = {}
    for (let category of state.categories) {
        catById[category.id] = category;
    }
    return catById[categoryId] ? catById[categoryId] : null;

}

async function updateCategory(category) {
    let companyId = auth.companyId && auth.companyId.value
    if (!companyId) {
        teardown();
        return;
    }



    if (!category.id) {
        console.log('Error@updateCategory: no cat id', category)
    }

    // console.log('Info@updateCategory: cat id', JSON.stringify(category))
    category.companyId = companyId
    delete category.children

    await firebase
        .firestore()
        .collection("categories")
        .doc(category.id)
        .set(category, { merge: true })
}
async function deleteCategory(categoryId) {
    if (!auth.user) {
        teardown();
        return;
    }
    if (!categoryId) {
        console.log('Error@deleteCategory: no cat id', categoryId)
    }

    await firebase
        .firestore()
        .collection("categories")
        .doc(categoryId)
        .delete()
}

function getChildrenOfCategory(categoryId) {
    if (!state.categories || state.categories.length <= 0) {
        return [];
    }
    let children = [];
    for (let category of state.categories) {
        if (category.parentId == categoryId) {
            children.push(category)
        }
    }
    return children;
}

export default function () {


    if (!state.initialized && !state.initializing) {
        state.initializing = true;

        watch(auth.companyId, async (companyId) => {
            if (companyId) {
                loadCategories();
            } else {
                teardown()
            }
        })

        if (auth.companyId && auth.companyId.value) {
            loadCategories();
        }
        state.initialized = true
        state.initializing = false
    }





    return {
        ...toRefs(state),
        loadCategories,
        reorderCategory,
        teardown,
        updateCategory,
        deleteCategory,
        addCategory,
        moveCategory,
        getChildrenOfCategory,
        getCategoryById

    }


}