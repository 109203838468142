<template>
<div class="oido-grid">
  <slot></slot>
</div>  
</template>


<script >



export default {

  

};
</script>


<style scoped>
.oido-grid{
  padding-top: 1.5rem;
}
</style>


